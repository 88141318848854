var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"border-none",attrs:{"no-body":""}},[_c('div',{staticClass:"content-item"},[_c('b-col',{staticClass:"content-image w-100",style:({
        'background-image': 'url(' + _vm.imageUrl + _vm.content.id + '/main)',
      }),attrs:{"cols":"12","lg":_vm.mapMode ? 12 : 4,"xl":_vm.mapMode ? 12 : 3}}),_c('b-col',{staticClass:"content-body"},[_c('div',{staticClass:"content-title text-truncate"},[_vm._v(" "+_vm._s(_vm.getFieldTranslation(_vm.content, "title"))+" ")]),(
          _vm.content.address.street ||
          _vm.content.address.city ||
          _vm.content.address.country
        )?_c('div',{staticClass:"content-info text-truncate"},[_c('font-awesome-icon',{attrs:{"icon":"map-marker-alt"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm._f("contentAddress")(_vm.content.address)))])],1):_vm._e(),_c('div',{staticClass:"content-description"},[_vm._v(" "+_vm._s(_vm.getFieldTranslation(_vm.content, "abstract"))+" ")]),(_vm.showDistance && _vm.content.distance)?_c('div',{staticClass:"content-distance"},[_c('font-awesome-icon',{attrs:{"icon":"arrows-alt-h"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm._f("distance")(_vm.content.distance)))])],1):_vm._e(),(
          _vm.content.showcasePrice &&
          _vm.content.showcasePrice !== 0 &&
          (!_vm.content.discount || _vm.content.discount === 0)
        )?_c('div',{staticClass:"content-price-card"},[_c('span',{staticClass:"mini-txt pb-1 mr-2"},[_vm._v(_vm._s(_vm.$t("common.startPrice")))]),_c('PriceLabel',{attrs:{"price":_vm.content.showcasePrice}})],1):(_vm.content.showcasePrice !== 0)?_c('div',{staticClass:"content-price-card"},[_c('span',{staticClass:"mini-txt pb-1 mr-2"},[_vm._v(_vm._s(_vm.$t("common.startPrice")))]),_c('div',[_c('span',{staticClass:"price-cut ml-4"},[_vm._v(_vm._s(_vm.content.showcasePrice)+"€")]),_c('PriceLabel',{staticClass:"discounted",attrs:{"price":_vm.evaluateDiscount()}})],1)]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }