import { client } from './setup';
import { MULTIMEDIA_API, MULTIMEDIA_CONTENT_TYPE } from '../globals';

export default {
    createMultimedia: (id, payload) => client.post(`${MULTIMEDIA_API}/${id}`, payload, { headers: { "Content-Type": MULTIMEDIA_CONTENT_TYPE, } }),
    getMultimediaList: (contentId) => client.get(`${MULTIMEDIA_API}/meta/${contentId}/list`),
    deleteMultimedia: (id, contentId) => client.delete(`${MULTIMEDIA_API}/${contentId}/${id}`),
    updateMultimedia: (id, contentId, payload) => client.put(`${MULTIMEDIA_API}/${contentId}/${id}`, payload, { headers: { "Content-Type": MULTIMEDIA_CONTENT_TYPE, } }),
    getImage: (contentId,id) => client.get(`${MULTIMEDIA_API}/data/${contentId}/${id}`),
    downloadFile: (id) => client.get(`${MULTIMEDIA_API}/data/file/${id}`),
    downloadAll: (contentId) => client.get(`${MULTIMEDIA_API}/data/download/${contentId}`),
    getMainImage: (contentId) => client.get(`${MULTIMEDIA_API}/data/${contentId}/main`),
    getCoverImage: (contentId) => client.get(`${MULTIMEDIA_API}/data/cover-image/${contentId}`),
}