<template>
  <div class="request-detail">
    <Loader :size="6" v-if="!request" />

    <template v-else>
      <CustomAlert type="danger" v-if="error">
        <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
        <span class="ml-2">{{ $t("admin.requests.detail.error") }}</span>
      </CustomAlert>

      <template v-else>
        <BookingRecap :showStatus="true" :booking="request">
          <FlatButton
            class="border text-info px-3"
            v-if="request.status == 'pending' && userRole != 'user'"
            @click.native="openAvailabilityModal"
          >
            <font-awesome-icon icon="calendar"></font-awesome-icon>
            <span class="ml-2">{{
              $t("admin.requests.detail.actions.verify")
            }}</span>
          </FlatButton>
        </BookingRecap>

        <br />

        <b-row
          align-h="end"
          align-v="center"
          v-if="request.status == 'wait_payment' && userRole == 'user'"
          no-gutters
        >
          <RaisedButton
            variant="success"
            size="md"
            @click.native="openBookingmodal"
          >
            <font-awesome-icon icon="cash-register" />
            <span class="ml-2">{{ $t("actions.completePay") }}</span>
          </RaisedButton>
        </b-row>

        <b-row
          v-if="request.status == 'pending' && userRole != 'user'"
          align-h="center"
          align-v="center"
        >
          <b-col sm="5">
            <RaisedButton
              class="w-100 p-2"
              variant="danger"
              @click.native="changeRequestStatus('rejection')"
            >
              <font-awesome-icon icon="times"></font-awesome-icon>
              <span class="ml-2">{{
                $t("admin.requests.detail.actions.decline")
              }}</span>
            </RaisedButton>
          </b-col>

          <b-col sm="5">
            <RaisedButton
              class="w-100 p-2"
              variant="success"
              @click.native="changeRequestStatus('confirmation')"
            >
              <font-awesome-icon icon="check"></font-awesome-icon>
              <span class="ml-2">{{
                $t("admin.requests.detail.actions.accept")
              }}</span>
            </RaisedButton>
          </b-col>
        </b-row>
      </template>
    </template>

    <b-modal hide-footer hide-header size="lg" id="payment-modal" centered>
      <h5>{{ $t("components.checkoutWizard.selectBooks") }}</h5>
      <div class="pl-3 pr-3">
        <b-row align-v="center" class="border rounded p-2">
          <b-col md="4" class="d-flex flex-column">
            <span class="text-left"
              >{{ getFieldTranslation(request.content, "title") }}
            </span>
          </b-col>
          <b-col md="4" class="d-flex flex-column">
            <span
              class="text-left"
              v-if="
                request.start.substring(0, 10) === request.end.substring(0, 10)
              "
            >
              {{ request.start | formatDate(true) }}
            </span>
            <span v-else class="text-left">
              {{ request.start | formatDate(true) }} ->
              {{ request.end | formatDate(true) }}
            </span>
          </b-col>
          <b-col md="1">
            <template v-if="request.slotId">
              <span class="text-center">
                {{ getTime(request.start) }}
              </span>
              <span class="text-center">
                {{ getTime(request.end) }}
              </span>
            </template>
          </b-col>
          <b-col md="2" class="d-flex flex-column">
            <span class="text-right"
              >{{ $t("common.adults") }}: {{ request.adultAmount }}
            </span>
            <span class="text-right"
              >{{ $t("common.children") }}: {{ request.kidAmount }}
            </span>
          </b-col>
          <b-col md="1" class="d-flex flex-column">
            <strong class="text-right"> {{ request.sum }}€</strong>
          </b-col>
        </b-row>
        <b-row align-h="start" class="pt-2">
          <vue-recaptcha
            ref="recaptcha"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
            :sitekey="siteKey"
            :loadRecaptchaScript="true"
          ></vue-recaptcha>
        </b-row>
        <RaisedButton
          variant="success"
          size="md"
          class="float-right mt-2"
          @click.native="completePayment"
          :disabled="!captchaVerified"
        >
          <font-awesome-icon icon="cash-register" />
          <span class="ml-2">Paga</span>
        </RaisedButton>
      </div>
    </b-modal>

    <b-modal size="lg" hide-footer hide-header id="availability-modal">
      <BookingOverview
        v-if="request"
        :contentId="request.contentId"
        :packageId="request.packageId"
        :startDate="request.start"
      />
    </b-modal>
  </div>
</template>

<style lang="scss">
.request-detail {
  padding: 1rem;
  background-color: white;
  border-radius: 0.25rem;
}
.slots-list {
  border-radius: 0.25rem;
  border: 1px solid #ddd;
  max-height: 500px;
  overflow-y: auto;

  .slots-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid #ddd;
    }

    .slots-check {
      padding: 0 0.5rem;
      font-size: 1.5rem;
    }
  }
}
</style>

<script>
import { Booking, BookingCheckoutApi } from "../../../api";
import moment from "moment";
import Loader from "../../../components/Loader.vue";
import CustomAlert from "../../../components/CustomAlert.vue";
import RaisedButton from "../../../components/RaisedButton.vue";
import FlatButton from "../../../components/FlatButton.vue";
import BookingRecap from "../../../components/BookingRecap.vue";
import BookingOverview from "../../../components/BookingOverview.vue";
import jwt_decode from "jwt-decode";
import VueRecaptcha from "vue-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../globals";
import {
  fixBookingStatus,
  getBookingClass,
  getBookingIcon,handleError
} from "../../../utils";
import { BookingCheckout } from "../../../models/BookingCheckout";

export default {
  name: "RequestDetail",
  components: {
    BookingRecap,
    BookingOverview,
    FlatButton,
    RaisedButton,
    CustomAlert,
    Loader,
    VueRecaptcha,
  },
  data: () => ({
    request: null,
    error: false,
    requestDates: null,
    lastSelectedDate: null,
    slotsInfo: null,
    selectedDayAvailability: null,
    userRole: "user",
    captchaVerified: false,
    captcha: "",
    siteKey: RECAPTCHA_SITE_KEY,
  }),
  created: function () {
    this.initRequest();
    this.getRole();
  },
  methods: {
    getRole() {
      let tkn = this.$cookies.get("atlas_token");

      const decoded = jwt_decode(tkn);
      this.userRole = decoded.role;
    },
    initRequest() {
      const id = this.$route.params.id;

      Booking.getRequest(id).then(
        ({ data: booking }) => {
          this.request = booking;
        },
        (err) => {
          console.log(err);
          this.request = {};
          this.error = true;
          handleError(
            this,
            err,
            "messages.requestsErrorMsg",
            "messages.requestsError"
          );
        }
      );
    },
    changeRequestStatus(action) {
      this.$loading.show();

      Booking.updateRequestState(this.request.id, action).then(
        () => {
          this.$loading.hide();
          this.reload();
          this.$bvToast.toast(this.$t("messages.requestState"), {
            title: this.$t("messages.success"),
            variant: "success",
            solid: true,
          });
        },
        (err) => {
          console.log(err);
          this.$loading.hide();
          handleError(
            this,
            err,
            "messages.requestStateAction",
            "messages.requestsError"
          );
        }
      );
    },
    reload() {
      this.request = null;
      this.initRequest();
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.captchaVerified = false;
    },
    onCaptchaVerified(recaptchaToken) {
      this.captcha = recaptchaToken;
      this.captchaVerified = true;
    },
    openBookingmodal() {
      this.$bvModal.show("payment-modal");
    },
    completePayment() {
      this.$loading.show();
      let bookingCheckoutIds = new BookingCheckout();
      bookingCheckoutIds.userId = this.request.userId;
      bookingCheckoutIds.name = this.request.name;
      bookingCheckoutIds.email = this.request.email;
      bookingCheckoutIds.phone = this.request.phoneNumber;
      bookingCheckoutIds.bookings = [this.request.id];
      bookingCheckoutIds.captcha = this.captcha;

      BookingCheckoutApi.sendBookingCheckoutIds(bookingCheckoutIds).then(
        (res) => {
          const checkoutUrl = res.data.checkoutUrl;
          window.location.href = checkoutUrl;
          this.$loading.hide();
        },
        (err) => {
          handleError(
            this,
            err,
            "messages.requestCheckout",
            "messages.checkoutError"
          );
          this.$loading.hide();
        }
      );
    },
    openAvailabilityModal() {
      this.requestDates = [
        {
          highlight: {
            start: { fillMode: "outline" },
            base: { fillMode: "light" },
            end: { fillMode: "outline" },
          },
          dates: {
            start: new Date(this.request.from),
            end: new Date(this.request.to),
          },
        },
      ];

      this.$bvModal.show("availability-modal");
    },
    getRequestClass() {
      return getBookingClass(this.request.status);
    },
    getRequestIcon() {
      return getBookingIcon(this.request.status);
    },
    fixStatusText() {
      return fixBookingStatus(this.request.status);
    },
    getTime(date) {
      return moment(date).utc().format("HH:mm");
    },
  },
};
</script>
