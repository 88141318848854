<template>
  <div class="admin-root">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
.admin-root {
  height: 100%;
  min-height: 100vh;
  background-color: #ddd;
}
</style>

<script>
// import Navbar from "@/components/Navbar";
// import Footer from "@/components/Footer";

export default {
  name: "AdminRoot",
};
</script>