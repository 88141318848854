export class Discount {

    constructor(
        id,
        amount,
        contentId,
        userId
    ) {
        this.id = id || null
        this.amount = amount || null; // { from, to }
        this.contentId = contentId || null; // { amount, from, to }
        this.userId = userId || null;
    }
}

export class NewDiscounts {

    constructor(
        amount,
        contents,
        userId,
    ) {
        this.amount = amount || null; // { from, to }
        this.contents = contents || null; // { amount, from, to }
        this.userId = userId || null;
    }
}