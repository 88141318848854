export class FileMetadata {

    constructor(
        name,
        // description,
        mainImage,
        id,
        deleted,
    ) {
        this.id = id;
        this.title = this.originalName = name;
        // this.description = description;
        this.mainImage = mainImage;
        this.deleted = deleted || false;
        this.setExtension();
    }

    setExtension() {
        let extension;
        if(this.originalName.includes('.')) {
            const nameParts = this.originalName.split('.');
            extension = nameParts[nameParts.length - 1];
        }
        this.extension = extension;
    }
}