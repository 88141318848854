<template>
  <div class="tags">
    <div class="mb-3">
      <h6 class="page-title mb-1">
        {{ $t("admin.platformManagement.tags.list.title") }}
      </h6>
      <span class="page-subtitle">
        {{ $t("admin.platformManagement.tags.list.description") }}
      </span>
    </div>

    <b-row no-gutters align-v="center" class="mb-3">
      <b-col>
        <CustomSearch
          :placeholder="
            $t('admin.platformManagement.tags.list.placeholders.search')
          "
          v-model="searchText"
        />
      </b-col>

      <div class="pl-3">
        <RaisedButton
          @click.native="$bvModal.show('new-tag')"
          variant="success"
        >
          <font-awesome-icon icon="plus"></font-awesome-icon>
          <span class="ml-2">{{
            $t("admin.platformManagement.tags.list.add")
          }}</span>
        </RaisedButton>
      </div>
    </b-row>

    <Loader v-if="!tags" :size="6" />

    <template v-else>
      <CustomAlert v-if="!tags.length">
        <font-awesome-icon icon="info-circle"></font-awesome-icon>
        <span class="ml-2">{{
          $t("admin.platformManagement.tags.list.noResults")
        }}</span>
      </CustomAlert>

      <div class="tags-list" v-else>
        <div class="tag-item" v-for="(tag, index) of filteredTags" :key="index">
          <div class="tag-icon">
            <font-awesome-icon icon="tag"></font-awesome-icon>
          </div>

          <b-col>
            <h6 class="mb-0">{{ tag.label[0].text }}</h6>
            <span class="text-muted">{{ tag.label[1].text }}</span>
          </b-col>

          <div class="px-3">
            <IconButton @click.native="editTag(tag)">
              <font-awesome-icon icon="pen"></font-awesome-icon>
              <!-- <span class="ml-2">Edit</span> -->
            </IconButton>
            <IconButton @click.native="deleteModal(tag.id)" class="ml-3">
              <font-awesome-icon
                class="text-danger"
                icon="trash"
              ></font-awesome-icon>
              <!-- <span class="ml-2">Delete</span> -->
            </IconButton>
          </div>
        </div>
      </div>
    </template>

    <b-modal
      hide-footer
      :title="$t('admin.platformManagement.tags.edit.newTitle')"
      id="new-tag"
      size="lg"
      @hidden="resetNewTag"
    >
      <NewTag
        :newTag="newTag"
        @cancel="$bvModal.hide('new-tag')"
        @submit-tag="saveTag"
      />
    </b-modal>

    <DeleteModal @cancel="$bvModal.hide('delete-modal')" @confirm="deleteTag">
      <div
        v-if="tag"
        v-html="
          $t('admin.platformManagement.tags.delete.mainMessage', {
            title: getFieldTranslation(tag, 'label'),
          })
        "
      ></div>
    </DeleteModal>
  </div>
</template>

<style lang="scss">
.tags {
  .tags-list {
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    max-height: 500px;
    overflow-y: auto;

    .tag-item {
      display: flex;
      align-items: center;
      padding: 0.5rem;

      &:not(:last-of-type) {
        border-bottom: 1px solid #ddd;
      }

      .tag-icon {
        padding: 0 0.5rem;
        font-size: 1.5rem;
      }
    }
  }
}
</style>

<script>
import NewTag from "./NewTag.vue";
import CustomAlert from "../../../components/CustomAlert.vue";
import Loader from "../../../components/Loader.vue";
import IconButton from "../../../components/IconButton.vue";
import DeleteModal from "../../../components/DeleteModal.vue";
import RaisedButton from "../../../components/RaisedButton.vue";
import CustomSearch from "../../../components/CustomSearch.vue";

import { cloneDeep } from "lodash";
import { handleError } from "../../../utils";
import { Tag } from "../../../models/Tag.js";
import Tags from "../../../api/Tags.js";

export default {
  name: "Tags",
  data: () => ({
    tags: null,
    tag: null,
    newTag: null,
    searchText: "",
  }),
  components: {
    CustomAlert,
    Loader,
    IconButton,
    DeleteModal,
    RaisedButton,
    NewTag,
    CustomSearch,
  },
  created() {
    this.getTags();
    this.resetNewTag();
  },
  computed: {
    filteredTags() {
      const search = this.searchText.trim().toLowerCase();
      return this.tags
        ? this.tags.filter((tag) =>
            this.getFieldTranslation(tag, "label")
              .toLowerCase()
              .includes(search)
          )
        : [];
    },
  },
  methods: {
    getTags() {
      Tags.getTags().then(
        (res) => {
          this.tags = res.data;
        },
        (err) => {
          const error = err.response.data;
          handleError(this, err, error.message, error.error);
          this.tags = [];
        }
      );
    },
    resetNewTag() {
      this.newTag = new Tag();
    },
    editTag(tag) {
      this.newTag = cloneDeep(tag);
      this.$bvModal.show("new-tag");
    },
    saveTag() {
      this.$loading.show();

      if (this.newTag.id) {
        Tags.updateTag(this.newTag).then(
          () => {
            this.$bvToast.toast(this.$t("messages.tagModified"), {
              title: this.$t("messages.success"),
              variant: "success",
              solid: true,
            });
            this.$loading.hide();
            this.newTag = new Tag();
            this.tags = null;
            this.getTags();
            this.$bvModal.hide("new-tag");
          },
          (err) => {
            const error = err.response.data;
            handleError(this, err, error.message, error.error);
            this.$loading.hide();
          }
        );
      } else {
        Tags.createTag(this.newTag).then(
          (res) => {
            this.$bvToast.toast(this.$t("messages.tagAdded"), {
              title: this.$t("messages.success"),
              variant: "success",
              solid: true,
            });
            this.$loading.hide();
            this.tags.push(res.data);
            this.newTag = new Tag();
            this.$bvModal.hide("new-tag");
          },
          (err) => {
            const error = err.response.data;
            handleError(
              this,
              err,
              error.message,
              error.error
            );
            this.$loading.hide();
          }
        );
      }
    },
    deleteModal(id) {
      this.tag = this.tags.find((tag) => tag.id == id);
      this.$bvModal.show("delete-modal");
    },
    deleteTag() {
      this.$loading.show();

      Tags.deleteTag(this.tag.id).then(
        () => {
          this.$bvToast.toast(this.$t("messages.tagDeleted"), {
            title: this.$t("messages.success"),
            variant: "success",
            solid: true,
          });
          this.tags = null;
          this.getTags();
          this.$bvModal.hide("delete-modal");
          this.$loading.hide();
        },
        (err) => {
          const error = err.response.data;
          handleError(
            this,
            err,
            error.message,
            error.error
          );
          this.$loading.hide();
        }
      );
    },
  },
};
</script>