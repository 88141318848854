<template>
  <div
    :class="['checkable-item', { checked: value }]"
    @click="$emit('input', !value)"
  >
    {{ $t(label) }}

    <div class="checked-icon" v-if="value">
      <b-icon icon="check2-circle"></b-icon>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";

.checkable-item {
  padding: 0.5rem 1rem;
  font-weight: 600;
  text-align: center;
  font-family: "Quicksand";
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid $default-gray;
  position: relative;
  color: #444;
  user-select: none;

  &:hover {
    box-shadow: inset 0 0 0 10rem rgba(0, 0, 0, 0.025);
  }
  &:active {
    box-shadow: inset 0 0 0 10rem rgba(0, 0, 0, 0.125);
  }

  &.checked {
    font-variation-settings: 'wght' 600;
    background-color: rgba($geo-info, 0.25);
    border-color: $geo-info;
    color: $geo-info;
  }

  .checked-icon {
    line-height: 1;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    font-size: 2rem;
    color: $geo-success;
  }
}
</style>

<script>
export default {
  name: "CheckableItem",
  props: {
    label: String,
    value: Boolean,
  },
};
</script>
