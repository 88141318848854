<template>
  <div class="map-container" :style="cssProps">
    <div
      class="loader-overlay"
      v-show="!placeholderMode && !markers"
    >
      <bounce-loader :loading="true" size="100px" color="white"></bounce-loader>
    </div>

    <l-map
      ref="leafMap"
      class="leaflet-map"
      :zoom="zoom"
      :center="actualCenter"
      :bounds.sync="bounds"
      :options="mapOptions"
    >
      <l-tile-layer :url="url" :attribution="attribution" />

      <template v-if="userLocation">
        <l-marker
          :lat-lng="[userLocation[1], userLocation[0]]"
          :options="{ interactive: false }"
        >
          <l-icon class-name="user">
            <div class="user-dot"></div>
          </l-icon>
        </l-marker>
      </template>

      <l-marker
        v-if="detailMarker"
        :lat-lng="center"
        :options="{ interactive: false }"
      ></l-marker>

      <l-marker
        v-if="editMarker"
        :lat-lng="editMarkerPosition"
        :options="{ draggable: true }"
        @update:latLng="editMarkerPositionUpdate"
      >
      </l-marker>

      <v-marker-cluster :options="placeholderMode ? miniCOpt : fullCOpt">
        <l-marker
          v-for="marker in markers"
          :key="marker.id"
          :lat-lng="[marker.coordinates[0], marker.coordinates[1]]"
          :options="placeholderMode ? { interactive: false } : {}"
        >
          <l-popup v-if="!placeholderMode">
            <MarkerPopupBody
              :image="marker.image"
              :title="getFieldTranslation(marker, 'title')"
              :address="marker.address"
              :category="marker.category"
              :showDetailButton="true"
              @content-detail="
                $router.push({
                  name: 'ContentDetail',
                  params: { id: marker.id },
                })
              "
            ></MarkerPopupBody>
          </l-popup>
        </l-marker>
      </v-marker-cluster>

    </l-map>

    <RaisedButton
      v-if="!placeholderMode"
      class="update-btn"
      @click.native="emitBoxBounds"
    >
      <font-awesome-icon icon="sync-alt"></font-awesome-icon>
      <span class="ml-2">{{$t("actions.searchArea")}}</span>
    </RaisedButton>
  </div>
</template>

<style lang="scss">
@import "src/variables.scss";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.marker-cluster div {
  cursor: var(--cursor);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px !important;
  font-weight: 600;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.5);

  & span {
    line-height: 40px;
  }
}
.marker-cluster-small {
  background-color: rgba(255, 255, 255, 0);
}
.marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}
.marker-cluster-medium div {
  background-color: rgb(241, 211, 87);
}
.marker-cluster-medium {
  background-color: rgba(255, 255, 255, 0);
}

.marker-cluster-large {
  background-color: rgb(241, 87, 87);
}

.marker-cluster-large div {
  background-color: rgba(255, 255, 255, 0);
}
.noTouch {
  pointer-events: none;
}
.map-container {
  height: inherit;
  overflow: hidden;
  position: relative;

  .leaflet-map {
    z-index: 0;
  }

  .loader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .update-btn {
    width: 250px;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .user-dot {
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: $geo-info;

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $geo-info;
      animation: ripple 1s ease 0s infinite;
    }
  }

  @keyframes ripple {
    0% {
      left: 10px;
      top: 10px;
      opcity: 75;
      width: 0;
      height: 0;
    }
    100% {
      left: -40px;
      top: -40px;
      opacity: 0;
      width: 100px;
      height: 100px;
    }
  }

  .cluster-dot {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    box-shadow: 0.3rem 0.3rem 0.5rem rgba(black, 0.25);
    cursor: pointer;
    background-color: #0077b6;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    .cluster-number {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
}
</style>

<script>
import MarkerPopupBody from "@/components/MarkerPopupBody";
import RaisedButton from "@/components/RaisedButton";
import BounceLoader from "vue-spinner/src/BounceLoader";


import { LMap, LTileLayer, LMarker, LPopup, LIcon } from "vue2-leaflet";


export default {
  name: "LeafletMap",
  props: {
    markers: Array,
    placeholderMode: { type: Boolean, default: true },
    editMarker: { type: Boolean, default: false },
    center: {
      type: Array,
      default: () => [37.650413, 14.117355],
    },
    editMarkerPosition:{
      type: Array,
      default:() => [37.650413, 14.117355]
    },
    zoom: { type: Number, default: 9 },
    userLocation: Array,
    detailMarker: Boolean,
  },
  components: {
    MarkerPopupBody,
    BounceLoader,
    RaisedButton,
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data: () => ({
    map: null,
    miniCOpt: {
      disableClusteringAtZoom: 8,
      maxClusterRadius: 20,
      spiderfyOnMaxZoom: false,
      zoomToBoundsOnClick: false,
    },
    fullCOpt: { disableClusteringAtZoom: 18 },
    bounds: null,
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    currentBounds: {},
    mapOptions: {
      zoomSnap: 0.5,
      zoomControl: false,
    },
 //   editMarkerPosition: latLng(37.650413, 14.117355),
  }),
  computed: {
    cssProps() {
      return this.placeholderMode
        ? { "--cursor": "auto" }
        : { "--cursor": "pointer" };
    },
    actualCenter() {
      return this.center;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.map = this.$refs.leafMap.mapObject;
      this.bounds = this.map.getBounds();
      this.currentBounds = this.map.getBounds();

      this.onMapLoad();
    });
  },
  methods: {
    onMapLoad() {
      let actualBounds = [
        this.currentBounds._northEast.lng,
        this.currentBounds._northEast.lat,
        this.currentBounds._southWest.lng,
        this.currentBounds._southWest.lat,
      ];
      this.$emit("map-loaded", {
        center: this.actualCenter,
        boxBounds: actualBounds,
        zoom: this.zoom,
      });
    },
    emitBoxBounds: function () {
     
      let boxBounds = [
        this.bounds._southWest.lng,
        this.bounds._southWest.lat,
        this.bounds._northEast.lng,
        this.bounds._northEast.lat,
      ];

      this.$emit("map-current-zone", {
        center: this.actualCenter,
        boxBounds,
        zoom: this.zoom,
      });
    },
    editMarkerPositionUpdate(evt) {
      this.$emit("locationUpdate", evt);
    },
  },
};
</script>