import router from "./router";
import VueCookies from "vue-cookies";

export function logout() {
  VueCookies.remove("atlas_user");
  VueCookies.remove("atlas_token");
  router.push({ name: 'Login' });
}

export function serializeDay(dayName, forCalendar) {

  switch (dayName) {
    case 'Monday': return forCalendar ? 2 : 0;
    case 'Tuesday': return forCalendar ? 3 : 1;
    case 'Wednesday': return forCalendar ? 4 : 2;
    case 'Thursday': return forCalendar ? 5 : 3;
    case 'Friday': return forCalendar ? 6 : 4;
    case 'Saturday': return forCalendar ? 7 : 5;
    case 'Sunday': return forCalendar ? 1 : 6;
    default: return 0;
  }
}

export function serializeMonth(monthName) {
  switch (monthName) {
    case 'January': return 0;
    case 'February': return 1;
    case 'March': return 2;
    case 'April': return 3;
    case 'May': return 4;
    case 'June': return 5;
    case 'July': return 6;
    case 'August': return 7;
    case 'September': return 8;
    case 'October': return 9;
    case 'November': return 10;
    case 'December': return 11;
    default: return 0;
  }
}

export const ROLES_ROUTES = new Map([
  ['admin', ['/admin/pages/contents', '/admin/pages/users', '/admin/pages/settings', '/admin/pages/account', '/admin/pages/discounts']],
  ['user', ['/admin/pages/requests', '/admin/pages/account']],
  ['provider', ['/admin/pages/contents', '/admin/pages/requests', '/admin/pages/account']],
]);

export function plainObjectsEquality(first, second) {
  for (var T in first) {
    if (String(first[T]) != String(second[T])) {
      return false;
    }
  }
  for (var K in second) {
    if (String(first[K]) != String(second[K])) {
      return false;
    }
  }
  return true;
}

export function getBookingClass(status, text, variant) {
  switch (status) {
    case "pending":
      return text ? "text-warning" : variant ? "warning" : "bg-warning";
    case "wait_payment":
      return text ? "text-info" : variant ? "info" : "bg-info";

    case "rejected":
      return text ? "text-danger" : variant ? "danger" : "bg-danger";

    case "paid":
      return text ? "text-success" : variant ? "success" : "bg-success";

    case "payment_expired":
    case "expired":
      return text ? "text-secondary" : variant ? "secondary" : "bg-secondary";
  }
}

export function getBookingIcon(status) {
  switch (status) {
    case "pending":
      return "user-clock";

    case "rejected":
      return "user-times";

    case "wait_payment":
      return "comment-dollar";

    case "paid":
      return "user-check";

    case "expired":
    case "payment_expired":
      return "hourglass-end";

    default:
      return "comment-dots";
  }
}

export function fixBookingStatus(status) {
  switch (status) {
    case "wait_payment":
      return "waitingPayment";

    case "rejected":
    case "pending":
    case "paid":
      return status;

    case "payment_expired":
    case "expired":
      return "expired";
  }
}

export function contentPositionByStatus(status) {
  return ["pending","modified","approved","not-approved","rejected","deleted"].indexOf(status)
}

export function requestPositionByStatus(status) {
  return ["pending","wait_payment","paid","rejected","expired"].indexOf(status)
}


  export function calculateDiscount(price, discount) {
    return price - (price / 100) * discount;
  }

  export function handleError(_this, err, message, title) {

    if (err.response.status != 401) {
      _this.$bvToast.toast(_this.$t(message), {
        title: _this.$t(title),
        variant: "danger",
        solid: true,
      });
    } else {
      _this.$bvToast.toast(_this.$t("messages.sessionExpiredMsg"), {
        title: _this.$t("messages.sessionExpired"),
        variant: "danger",
        solid: true,
      });
    }
  }
