<template>
  <validation-observer ref="fieldObserver" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(emitCategory)">
      <validation-provider
        :name="$t('common.name')"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group
          id="category-name-it-fieldset"
          :label="`${$t('common.name')} (${$t('language.it.short')})`"
          label-cols="4"
          label-for="category-name-it"
        >
          <b-form-input
            id="category-name-it"
            v-model="newCategory.name[0].text"
            :placeholder="`${$t(
              'admin.platformManagement.categories.edit.categoryName'
            )} (${$t('language.it.full')})`"
            :state="getValidationState(validationContext)"
          ></b-form-input>

          <b-form-invalid-feedback id="category-name-it-live-feedback">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group
        id="category-name-en-fieldset"
        :label="`${$t('common.name')} (${$t('language.en.short')})`"
        label-cols="4"
        label-for="category-name-en"
      >
        <b-form-input
          id="category-name-en"
          v-model="newCategory.name[1].text"
          :placeholder="`${$t(
            'admin.platformManagement.categories.edit.categoryName'
          )} (${$t('language.en.full')})`"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="category-description-it-fieldset"
        :label="`${$t('common.description')} (${$t('language.it.short')})`"
        label-cols="4"
        label-for="category-description-it"
      >
        <b-form-textarea
          id="category-description-it"
          v-model="newCategory.description[0].text"
          :placeholder="`${$t(
            'admin.platformManagement.categories.edit.categoryDescription'
          )} (${$t('language.it.full')})`"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
        id="category-description-en-fieldset"
        :label="`${$t('common.description')} (${$t('language.en.short')})`"
        label-cols="4"
        label-for="category-description-en"
      >
        <b-form-textarea
          id="category-description-en"
          v-model="newCategory.description[1].text"
          :placeholder="`${$t(
            'admin.platformManagement.categories.edit.categoryDescription'
          )} (${$t('language.en.full')})`"
        ></b-form-textarea>
      </b-form-group>

      <h6>{{ $t("admin.platformManagement.extraFields.edit.icon") }}</h6>

      <IconsTable
        :selected="newCategory.icon"
        @selected-icon="newCategory.icon = $event"
      />

      <br />

      <b-row align-h="end" no-gutters>
        <FlatButton @click.native="$emit('cancel')">{{
          $t("actions.cancel")
        }}</FlatButton>
        <RaisedButton class="ml-3" type="submit" variant="success">{{
          $t("actions.confirm")
        }}</RaisedButton>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import RaisedButton from "../../../components/RaisedButton.vue";
import FlatButton from "../../../components/FlatButton.vue";
import IconsTable from "../../../components/IconsTable.vue";

export default {
  name: "NewCategory",
  props: {
    newCategory: Object,
  },
  components: {
    IconsTable,
    FlatButton,
    RaisedButton,
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    emitCategory() {
      this.$emit("submit-category", this.newCategory);
    },
  },
};
</script>