<template>
  <div class="account">
    <b-card>
      <b-container>
        <b-row no-gutters align-v="center" class="mb-5">
          <Avatar size="lg" class="bg-light border-secondary" :user="user" />
          <div class="ml-3">
            <h5 class="mb-0">{{ user.firstName }} {{ user.lastName }}</h5>
          </div>

          <div class="ml-auto">
            <RaisedButton
              class="ml-3"
              size="sm"
              variant="secondary"
              @click.native="$bvModal.show('change-password-modal')"
            >
              <font-awesome-icon icon="key"></font-awesome-icon>
              <span class="ml-2">{{ $t("pages.account.changePassword") }}</span>
            </RaisedButton>

            <RaisedButton
              class="ml-3"
              size="sm"
              variant="warning"
              @click.native="$bvModal.show('edit-modal')"
            >
              <font-awesome-icon icon="pen"></font-awesome-icon>
              <span class="ml-2">{{ $t("pages.account.editContacts") }}</span>
            </RaisedButton>

            <RaisedButton
              class="ml-3"
              size="sm"
              variant="danger"
              @click.native="$bvModal.show('delete-modal')"
            >
              <font-awesome-icon icon="times"></font-awesome-icon>
              <span class="ml-2">{{ $t("pages.account.deleteAccount") }}</span>
            </RaisedButton>
          </div>
        </b-row>

        <table class="table account-info">
          <tbody>
            <tr>
              <th scope="col">Username</th>
              <td>{{ user.username }}</td>
            </tr>
            <tr>
              <th scope="col">{{ $t("common.telephone") }}</th>
              <td>{{ user.contacts.telephone }}</td>
            </tr>
            <tr>
              <th scope="col">Email</th>
              <td>{{ user.contacts.email }}</td>
            </tr>
          </tbody>
        </table>
      </b-container>
    </b-card>

    <b-modal
      hide-footer
      :title="$t('pages.account.changePassword')"
      id="change-password-modal"
    >
      <validation-observer
        ref="changePasswordObserver"
        v-slot="{ handleSubmit }"
      >
        <b-form
          class="change-password-form"
          @submit.prevent="handleSubmit(submitNewPassword)"
        >
          <validation-provider
            :name="$t('pages.account.currentPassword')"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              id="current-pwd-fieldset"
              :label="$t('pages.account.currentPassword')"
              label-for="current-pwd-field"
            >
              <b-form-input
                id="current-pwd-field"
                :placeholder="$t('pages.account.currentPassword')"
                v-model.trim="currentPassword"
                type="password"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="current-pwd-field-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            :name="$t('pages.account.newPassword')"
            :rules="{ required: true, password: '@confirmPassword' }"
            v-slot="validationContext"
          >
            <b-form-group
              id="new-pwd-fieldset"
              :label="$t('pages.account.newPassword')"
              label-for="new-pwd-field"
            >
              <b-form-input
                id="new-pwd-field"
                :placeholder="$t('pages.account.newPassword')"
                v-model.trim="newPassword"
                type="password"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="new-pwd-field-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="confirmPassword"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              id="confirm-pwd-fieldset"
              :label="$t('pages.account.confirmPassword')"
              label-for="confirm-pwd-field"
            >
              <b-form-input
                id="confirm-pwd-field"
                :placeholder="$t('pages.account.confirmPassword')"
                v-model.trim="confirmPassword"
                type="password"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="confirm-pwd-field-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-row
            align-v="center"
            align-h="between"
            class="border-top pt-3 px-3 mt-3"
          >
            <b-button
              type="button"
              class="border"
              variant="light"
              @click="$bvModal.hide('change-password-modal')"
              >{{ $t("actions.cancel") }}</b-button
            >
            <RaisedButton type="submit" variant="success">{{
              $t("actions.confirm")
            }}</RaisedButton>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      hide-footer
      :title="$t('pages.account.editContacts')"
      id="edit-modal"
    >
      <validation-observer ref="editContactsObserver" v-slot="{ handleSubmit }">
        <b-form
          class="edit-contacts-form"
          @submit.prevent="handleSubmit(editContacts)"
        >
          <validation-provider
            name="firstname"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              id="firstname-fieldset"
              :label="$t('pages.registration.firstName')"
              label-for="firstname-fieldset"
            >
              <b-form-input
                id="firstname-field"
                :placeholder="$t('pages.registration.firstName')"
                v-model.trim="user.firstName"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="firstname-field-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="lastname"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              id="lastname-fieldset"
              :label="$t('pages.registration.lastName')"
              label-for="lastname-fieldset"
            >
              <b-form-input
                id="lastname-field"
                :placeholder="$t('pages.registration.lastName')"
                v-model.trim="user.lastName"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="lastname-field-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            id="telephone-fieldset"
            :label="$t('common.telephone')"
            label-for="telephone-fieldset"
          >
            <b-form-input
              id="telephone-field"
              :placeholder="$t('common.telephone')"
              v-model.trim="user.contacts.telephone"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="email-fieldset"
            label="Email"
            label-for="email-fieldset"
          >
            <b-form-input
              id="email-field"
              placeholder="Email"
              v-model.trim="user.contacts.email"
            ></b-form-input>
          </b-form-group>

          <b-row
            align-v="center"
            align-h="between"
            class="border-top pt-3 px-3 mt-3"
          >
            <b-button
              type="button"
              class="border"
              variant="light"
              @click="$bvModal.hide('edit-modal')"
              >{{ $t("actions.cancel") }}</b-button
            >
            <RaisedButton type="submit" variant="success">{{
              $t("actions.confirm")
            }}</RaisedButton>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <DeleteModal @cancel="$bvModal.hide('delete-modal')" @confirm="deleteUser">
      <p>
        {{ $t("messages.deleteMe") }}
      </p>
    </DeleteModal>
  </div>
</template>

<style lang="scss">
.account {
  .account-info {
    td,
    th {
      padding: 0.5rem 0.75rem;
      border: none;
    }
  }
}
</style>

<script>
import { Account } from "../api";
import Avatar from "../components/Avatar.vue";
import RaisedButton from "../components/RaisedButton.vue";
import { logout } from "../utils";
import DeleteModal from "../components/DeleteModal.vue";
import { handleError } from "../utils";
export default {
  name: "Account",
  components: {
    RaisedButton,
    Avatar,
    DeleteModal,
  },
  data: () => ({
    user: null,
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
  }),
  created: function () {
    this.user = this.$cookies.get("atlas_user");
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    submitNewPassword() {
      this.$loading.show();

      const body = {
        old: this.currentPassword,
        new: this.newPassword,
      };

      Account.changePassword(body).then(
        () => {
          this.$loading.hide();
          this.$root.$bvToast.toast(this.$t("messages.passwordSuccessMsg"), {
            title: this.$t("messages.accountSuccess"),
            variant: "success",
            solid: true,
          });
          logout();
        },
        (err) => {
          console.log(err);
          this.$loading.hide();
          handleError(
            this,
            err,
            "messages.passwordErrorMsg",
            "messages.accountError"
          );
        }
      );
    },
    editContacts() {
      this.$loading.show();

      Account.updateAccount(this.user).then(
        (res) => {
          this.$loading.hide();
          this.$root.$bvToast.toast(this.$t("messages.contactsSuccessMsg"), {
            title: this.$t("messages.accountSuccess"),
            variant: "success",
            solid: true,
          });
          this.user = res.data;
          this.$bvModal.hide("edit-modal");
          const date = new Date();
          this.$cookies.set("atlas_user", this.user, date);
        },
        (err) => {
          console.log(err);
          this.$loading.hide();
          handleError(
            this,
            err,
            "messages.contactsErrorMsg",
            "messages.accountError"
          );
          this.$bvModal.hide("edit-modal");
        }
      );
    },
    deleteUser() {
      this.$loading.show();

      Account.deleteAccount().then(
        () => {
          this.$loading.hide();
          this.$root.$bvToast.toast(this.$t("messages.userDeleteSuccess"), {
            title: this.$t("messages.accountSuccess"),
            variant: "success",
            solid: true,
          });
          logout();
        },
        (err) => {
          console.log(err);
          this.$loading.hide();
          handleError(
            this,
            err,
            "messages.deleteAccountErrorMsg",
            "messages.accountError"
          );
        }
      );
    },
  },
};
</script>