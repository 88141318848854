<template>
  <div class="request-list-item">
    <b-col :cols="leadingCols" class="lead-icon text-center">
      <font-awesome-icon
        :icon="getRequestIcon()"
        :class="getRequestClass(true)"
        :fixedWidth="true"
      ></font-awesome-icon>
      <div class="atlas-tag sm text-white pill" :class="getRequestClass()">
        {{ $t(`common.${fixStatusText()}`)}}
      </div>
    </b-col>

    <b-col class="overflow-hidden">
      <h6 class="mb-0">{{ getFieldTranslation(request, "contentTitle") }}</h6>
      <small class="text-muted request-date">{{
        request.requestDate | formatDate
      }}</small>

      <div class="d-flex mt-2">
        <div class="content-category border text-dark">
          <font-awesome-icon icon="calendar"></font-awesome-icon>
          <span
            class="ml-2"
            v-if="request.start.substring(0, 10) === request.end.substring(0, 10)"
          >
            {{ request.start | formatDate(true) }}
          </span>
          <span v-else class="ml-2">
            {{ request.start | formatDate(true) }} ->
            {{ request.end | formatDate(true) }}
          </span>
        </div>
        <div class="content-category border text-dark ml-3">
          <font-awesome-icon icon="user"></font-awesome-icon>
          <span class="ml-2">{{ request.name }}</span>
        </div>

        <div class="content-category border text-dark ml-3">
          <font-awesome-icon icon="users"></font-awesome-icon>
          <span class="ml-2">{{
            request.adultAmount + request.kidAmount
          }}</span>
        </div>
      </div>
    </b-col>

    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";

.request-list-item {
  padding: 0.5rem;
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
  }

  .lead-icon {
    padding: 0 0.5rem;
    font-size: 1.5rem;

    .atlas-tag {
      line-height: 1;
      display: block;
    }
  }
}
</style>

<script>
import { fixBookingStatus, getBookingClass, getBookingIcon } from "../utils";
export default {
  name: "RequestListItem",
  props: {
    request: Object,
    leadingCols: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    getRequestClass(text) {
      return getBookingClass(this.request.status, text);
    },
    getRequestIcon() {
      return getBookingIcon(this.request.status);
    },
    fixStatusText() {
      return fixBookingStatus(this.request.status);
    },
  },
};
</script>