<template>
  <b-dropdown class="flat-dropdown" variant="flat" right no-caret>
    <template #button-content>
      <div class="d-flex align-items-center">
        <Avatar size="sm" :user="user" />
        <div class="pl-3">
          <h6 class="user-info text-truncate">
            {{ user.firstName }} {{ user.lastName }}
          </h6>
        </div>
      </div>
    </template>
    <b-dropdown-item @click="dashboard" v-if="user.role === 'provider' || user.role === 'admin'">
      <font-awesome-icon
        icon="solar-panel"
        :fixedWidth="true"
      ></font-awesome-icon>
      <span class="ml-3">Dashboard</span>
    </b-dropdown-item>
        <b-dropdown-item @click="myOrder" v-if="user.role === 'user'">
      <font-awesome-icon
        icon="file-invoice"
        :fixedWidth="true"
      ></font-awesome-icon>
      <span class="ml-3">{{$t("navigation.myOrders")}}</span>
    </b-dropdown-item>
    <b-dropdown-item @click="accountSection">
      <font-awesome-icon icon="user" :fixedWidth="true"></font-awesome-icon>
      <span class="ml-3">Account</span>
    </b-dropdown-item>
    <b-dropdown-item @click="logout">
      <font-awesome-icon
        icon="sign-out-alt"
        :fixedWidth="true"
      ></font-awesome-icon>
      <span class="ml-3">Logout</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<style lang="scss" scoped>
.user-info {
  max-width: 150px;
  margin-bottom: 0;
}
</style>

<script>
import { logout } from "../utils";
import Avatar from "./Avatar.vue";

export default {
  name: "AccountCombo",
  props: {
    user: Object,
  },
  components: {
    Avatar,
  },
  methods: {
    logout() {
      logout();
    },
    accountSection() {
      if (this.$route.name != "Account") this.$router.push({ name: "Account" });
    },
    dashboard() {
        if (this.$route.name != "ContentsList")
          this.$router.push({ name: "ContentsList" });
    },
    myOrder() {
      if (this.$route.name != "RequestsList")
        this.$router.push({ name: "RequestsList" });
    },
  },
};
</script>