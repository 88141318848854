<template>
  <div class="time-slots-editor">
    <b-row>
      <b-col>
        <div class="p-3 rounded border">
          <b-row no-gutters class="mb-2" align-v="center">
            <b-col class="pr-3">
              <b-form-input
                id="rule-time-from"
                type="time"
                v-model="newTimeSlot.from"
              ></b-form-input>
            </b-col>

            <font-awesome-icon icon="chevron-right"></font-awesome-icon>

            <b-col class="pl-3">
              <b-form-input
                id="rule-time-to"
                type="time"
                v-model="newTimeSlot.to"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-form-group
            id="time-slot-amount-fieldset"
            :label="$t('components.availabilityVerifier.availableSpots')"
            label-cols="8"
            label-for="time-slot-amount"
          >
            <b-form-input
              id="time-slot-amount"
              type="number"
              min="0"
              :placeholder="$t('components.timeSlotEditor.spots')"
              v-model.number="newTimeSlot.amount"
            ></b-form-input>
          </b-form-group>

          <p class="mt-3 invalid-feedback d-block" v-if="invalidTime">
            {{ $t("components.timeSlotEditor.errorTime") }}
          </p>

          <CustomAlert type="warning" class="mb-2" v-if="!newTimeSlot.amount" >
            <font-awesome-icon icon="info-circle"></font-awesome-icon>
            <span class="ml-2">{{
              $t("components.timeSlotEditor.needTime")
            }}</span>
          </CustomAlert>

          <b-row no-gutters align-h="end">
            <FlatButton
              @click.native="saveTimeSlot"
              :disabled="!newTimeSlot.from || !newTimeSlot.to || invalidTime"
              class="border bg-info text-white"
            >
              <span
                >{{
                  editTime != undefined ? $t("common.update") : $t("common.add")
                }}
                {{ $t("components.timeSlotEditor.timeSlot") }}</span
              >
            </FlatButton>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/variables.scss";

.time-slots {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 0.25rem;

  .time-slot {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem;

    &.editing {
      background-color: rgba($geo-info, 0.125);
      border-left: 5px solid rgba($geo-info, 0.5);
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #ddd;
    }

    .leading {
      padding: 0 0.5rem;
    }
  }
}
.range-list {
  max-height: 300px;
  overflow-x: clip;
  overflow-y: auto;

  .range-items {
    padding-bottom: 0.35rem;
    margin-left: 15px;

    &.editing {
      .range-item {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        padding: 0.35rem 0.75rem;
        background-color: rgba($geo-info, 0.125);
      }
    }
    .range-item {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 0.35rem 0.75rem;
    }

    svg {
      margin: 0 1rem 0 1rem;
    }
  }
}
</style>

<script>
import { TimeSlot } from "../models/TimeSlot";

import FlatButton from "./FlatButton.vue";
import CustomAlert from "../components/CustomAlert.vue";
import moment from "moment";

export default {
  name: "TimeSlotsEditor",
  components: {
    FlatButton,
    CustomAlert,
  },
  props: {
    editTime: Object,
  },
  data: () => ({
    newTimeSlot: new TimeSlot(),
  }),
  created: function () {
    if (this.editTime != undefined) {
      this.newTimeSlot.from = this.editTime.from;
      this.newTimeSlot.to = this.editTime.to;
      this.newTimeSlot.amount = this.editTime.amount;
    }
  },
  computed: {
    invalidTime() {
      const momentFrom = moment(this.newTimeSlot.from, "HH:mm");
      const momentTo = moment(this.newTimeSlot.to, "HH:mm");

      return momentFrom.isAfter(momentTo);
    },
  },
  methods: {
    saveTimeSlot() {
      if (this.editTime != undefined) {
        this.$emit("edit-time-slot", this.newTimeSlot);
      } else {
        this.$emit("add-time-slot", this.newTimeSlot);
      }
    },
  },
};
</script>
