<template>
  <div class="slot-item">
    <div class="slots-check">
      <input
        type="radio"
        :checked="value == slotInfo.slotId"
        :class="{ readonly: readonly }"
        @input="$emit('input', slotInfo.slotId)"
      />
    </div>

    <b-col class="overflow-hidden">
      <h6 class="mb-0">{{ slotInfo.from }} » {{ slotInfo.to }}</h6>
      <span class="text-muted text-truncate">
        {{$t('components.bookingSlotItem.availableSpots', {amount: slotInfo.amount})}}</span
      >
    </b-col>
  </div>
</template>

<style lang="scss">
.slot-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;

  .readonly {
    pointer-events: none;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
  }

  .slots-check {
    padding: 0 0.5rem;
    font-size: 1.5rem;
  }
}
</style>

<script>
export default {
  name: "BookingSlotItem",
  props: {
    slotInfo: {
      type: Object,
      required: true,
    },
    value: String,
    readonly: Boolean,
  },
};
</script>