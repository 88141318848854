<template>
  <div class="users-list">
    <Loader :size="6" v-if="!users" />

    <template v-else>
      <b-row class="align-items-center">
        <b-col>
          <CustomSearch
            :value="searchText"
            :placeholder="$t('admin.users.list.search')"
            @input="handleFilterChange('search', $event)"
          />
        </b-col>
        <b-col cols="3" class="pl-0">
          <b-form-select
            size="sm"
            :value="statusFilter"
            @input="handleFilterChange('status', $event)"
          >
            <b-form-select-option :value="null">
              {{ $t("admin.users.list.allStati") }}
            </b-form-select-option>
            <b-form-select-option value="enabled">{{
              $t("admin.users.list.enabled")
            }}</b-form-select-option>
            <b-form-select-option value="disabled">{{
              $t("admin.users.list.disabled")
            }}</b-form-select-option>
            <b-form-select-option value="pending">{{
              $t("admin.users.list.pending")
            }}</b-form-select-option>
          </b-form-select>
        </b-col>

        <b-col cols="3" class="pl-0">
          <b-form-select
            size="sm"
            :value="roleFilter"
            @input="handleFilterChange('role', $event)"
          >
            <b-form-select-option :value="null">
              {{ $t("admin.users.list.allRoles") }}
            </b-form-select-option>
            <b-form-select-option value="admin">{{
              $t("admin.users.list.admin")
            }}</b-form-select-option>
            <b-form-select-option value="provider">{{
              $t("admin.users.list.provider")
            }}</b-form-select-option>
            <b-form-select-option value="user">{{
              $t("admin.users.list.user")
            }}</b-form-select-option>
          </b-form-select>
        </b-col>

        <RaisedButton
          variant="success"
          class="mr-3"
          @click.native="$router.push({ name: 'RegisterProvider' })"
        >
          <font-awesome-icon icon="plus"></font-awesome-icon>
          <span class="ml-2">{{ $t("admin.users.list.newProvider") }}</span>
        </RaisedButton>
      </b-row>

      <br />

      <CustomAlert v-if="!filteredUsers.length">
        <font-awesome-icon icon="info-circle"></font-awesome-icon>
        <span class="ml-2">{{ $t("admin.users.list.noResults") }}</span>
      </CustomAlert>

      <div class="users-wrapper" v-else>
        <div
          class="user-list-item"
          v-for="user of filteredUsers"
          :key="user.id"
        >
          <div :class="['user-status', user.status]">
            <font-awesome-icon
              :icon="user.status == 'enabled' ? 'user-check' : 'user-times'"
              class="mx-3"
            ></font-awesome-icon>
            <div class="atlas-tag mt-1">{{ user.status }}</div>
          </div>

          <b-col
            @click="
              $router.push({
                name: 'UserDetail',
                params: { id: user.id },
              })
            "
          >
            <div class="d-flex align-items-center">
              <h6 class="mb-0 mr-2">{{ user.username }}</h6>
              <div class="atlas-tag bg-light border">{{ user.role }}</div>
              <div
                class="atlas-tag text-light bg-info ml-2"
                v-if="user.category == 'business'"
              >
                <font-awesome-icon icon="star"></font-awesome-icon>
                <span class="ml-2">{{ user.category }}</span>
              </div>
            </div>
            <span class="text-muted"
              >{{ user.firstName }} {{ user.lastName }}</span
            >
          </b-col>

          <b-col cols="3" class="text-right">
            <IconButton
              class="text-warning"
              @click.native="
                $router.push({
                  name: 'UserEdit',
                  params: { id: user.id },
                })
              "
              v-if="user.role === 'provider' && user.status === 'enabled'"
            >
              <font-awesome-icon icon="pen" fixed-width></font-awesome-icon
            ></IconButton>
            <IconButton
              class="text-success"
              @click.native="toggleUserStatus($event, user)"
              v-if="
                user.status != 'enabled' &&
                user.status != 'deleted' 
              "
            >
              <font-awesome-icon icon="check" fixed-width></font-awesome-icon>
            </IconButton>
            <IconButton
              class="ml-2 text-danger"
              @click.native="toggleUserStatus($event, user, true)"
              v-if="user.status != 'disabled'"
            >
              <font-awesome-icon icon="times" fixed-width></font-awesome-icon>
            </IconButton>

            <IconButton
              class="ml-2 text-dark"
              @click.native="deleteModal($event, user)"
            >
              <font-awesome-icon icon="trash" fixed-width></font-awesome-icon>
            </IconButton>
          </b-col>
        </div>
      </div>
    </template>
    <DeleteModal @cancel="$bvModal.hide('delete-modal')" @confirm="deleteUser">
      <p v-if="selectedUser">
        {{
          selectedUser.role === "user"
            ? $t("messages.deleteUser")
            : $t("messages.deleteProvider")
        }}
        <b>{{ selectedUser.username }}</b
        >?
      </p>
    </DeleteModal>
  </div>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";

.users-wrapper {
  background-color: white;
  border-radius: 0.25rem;
  border: 1px solid #ddd;
}

.user-list-item {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  .user-status {
    padding: 0 0.5rem;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.enabled {
      color: $geo-success;
    }
    &.disabled {
      color: $geo-warning;
    }
  }
}
</style>

<script>
import Loader from "../../../components/Loader.vue";
import RaisedButton from "../../../components/RaisedButton.vue";
import CustomAlert from "../../../components/CustomAlert.vue";
import IconButton from "../../../components/IconButton.vue";
import CustomSearch from "../../../components/CustomSearch.vue";
import DeleteModal from "../../../components/DeleteModal.vue";
import { Users } from "../../../api";
import { handleError } from "../../../utils";

export default {
  name: "UsersList",
  components: {
    CustomSearch,
    IconButton,
    CustomAlert,
    RaisedButton,
    Loader,
    DeleteModal,
  },
  computed: {
    filteredUsers() {
      return this.users
        .filter(
          (user) => !this.statusFilter || user.status == this.statusFilter
        )
        .filter((user) => !this.roleFilter || user.role == this.roleFilter)
        .filter((user) =>
          user.username
            .toLowerCase()
            .includes(this.searchText.trim().toLowerCase())
        );
    },
  },
  data() {
    return {
      users: null,
      statusFilter: null,
      roleFilter: null,
      searchText: "",
      selectedUser: null,
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    reset() {
      this.users = null;
      this.getUsers();
    },
    getUsers() {
      Users.getUsers().then(
        ({ data: users }) => {
          this.users = users;
        },
        (err) => {
          console.log(err);
          this.users = [];
          handleError(this, err, "messages.userGetErr", "messages.userError");
        }
      );
    },
    deleteModal(evt, user) {
      evt.stopPropagation();
      this.selectedUser = user;
      this.$bvModal.show("delete-modal");
    },
    deleteUser() {
      Users.deleteUser(this.selectedUser.id).then(
        () => {
          this.$bvModal.hide("delete-modal");
          this.$bvToast.toast(this.$t("messages.userDeleteSuccess"), {
            title: this.$t("messages.userDelete"),
            variant: "success",
            solid: true,
          });
          this.reset();
        },
        (err) => {
          this.$bvModal.hide("delete-modal");
          handleError(
            this,
            err,
            "messages.userDeleteErr",
            "messages.userError"
          );
        }
      );
    },
    toggleUserStatus(evt, user, disable) {
      evt.stopPropagation();
      this.$loading.show();
      let status = user.status == "enabled" ? "disabled" : "enabled";
      if (disable) {
        status = "disabled";
      }

      Users.toggleUserStatus(user.id, status).then(
        () => {
          this.$loading.hide();
          this.$bvToast.toast(
            status == "enabled"
              ? this.$t("messages.userEnabled")
              : this.$t("messages.userDisabled"),
            {
              title: `User ${status}`,
              variant: "success",
              solid: true,
            }
          );
          this.reset();
        },
        (err) => {
          this.$loading.hide();
          if (status == "enabled") {
            handleError(
              this,
              err,
              "messages.userEnabledErr",
              "messages.userError"
            );
          } else {
            handleError(
              this,
              err,
              "messages.userDisabledErr",
              "messages.userError"
            );
          }
        }
      );
    },
    handleFilterChange(filter, value) {
      switch (filter) {
        case "search":
          this.searchText = value;
          break;

        case "status":
        default:
          this.statusFilter = value;
          break;

        case "role":
          this.roleFilter = value;
          break;
      }
    },
  },
};
</script>