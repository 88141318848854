import { Translation } from "./Translation";
export class Category {

    constructor(
        name,
        description,
        icon,
    ) {
        this.name = name || new Translation();
        this.description = description || new Translation();
        this.icon = icon || '';
    }
}