<template>
  <div class="extra-fields">
    <div class="mb-3">
      <h6 class="page-title mb-1">
        {{ $t("admin.platformManagement.extraFields.list.title") }}
      </h6>
      <span class="page-subtitle">{{
        $t("admin.platformManagement.extraFields.list.description")
      }}</span>
    </div>

    <b-row class="mb-3" align-v="center" no-gutters>
      <b-col>
        <CustomSearch
          v-model="searchText"
          :placeholder="
            $t('admin.platformManagement.extraFields.list.placeholders.search')
          "
        />
      </b-col>

      <div class="px-3">
        <b-form-select size="sm" :value="filter" @input="filterExtraFields">
          <b-form-select-option value="All">{{
            $t("common.all")
          }}</b-form-select-option>
          <b-form-select-option value="true">{{
            $tc("admin.platformManagement.extraFields.list.hidden", 2)
          }}</b-form-select-option>
          <b-form-select-option value="false">{{
            $tc("admin.platformManagement.extraFields.list.visible", 2)
          }}</b-form-select-option>
        </b-form-select>
      </div>

      <div>
        <FlatButton
          class="border rounded mr-2"
          @click.native="sortAsc = !sortAsc"
        >
          <font-awesome-icon
            :icon="sortAsc ? 'sort-alpha-down' : 'sort-alpha-down-alt'"
          ></font-awesome-icon>
        </FlatButton>

        <RaisedButton
          variant="success"
          @click.native="$bvModal.show('new-extra-field')"
        >
          <font-awesome-icon icon="plus"></font-awesome-icon>
          <span class="ml-2">{{ $t("actions.add") }}</span>
        </RaisedButton>
      </div>
    </b-row>

    <Loader v-if="!extraFields" :size="6" />

    <template v-else>
      <CustomAlert v-if="!extraFields.length">
        <font-awesome-icon icon="info-circle"></font-awesome-icon>
        <span class="ml-2">{{
          $t("admin.platformManagement.extraFields.list.noResults")
        }}</span>
      </CustomAlert>

      <div class="fields-list" v-else>
        <ExtraFieldItem
          v-for="(field, index) of filteredFields"
          :key="index"
          :field="field"
          @edit="editField(field.id)"
          @delete="deleteModal(field.id)"
        />
      </div>
    </template>

    <b-modal
      hide-footer
      :title="$t('admin.platformManagement.extraFields.edit.newTitle')"
      id="new-extra-field"
      size="lg"
      @hidden="resetNewField"
    >
      <NewExtraField
        :newField="newField"
        @cancel="$bvModal.hide('new-extra-field')"
        @submit-field="saveField"
      />
    </b-modal>

    <DeleteModal @cancel="$bvModal.hide('delete-modal')" @confirm="deleteField">
      <div v-if="field">
        <span
          v-html="
            $t('admin.platformManagement.extraFields.delete.mainMessage', {
              title: getFieldTranslation(field, 'title'),
            })
          "
        ></span>
      </div>
    </DeleteModal>
  </div>
</template>

<style lang="scss">
.fields-list {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  max-height: 450px;
  overflow-y: auto;

  .field-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid #ddd;
    }

    .field-icon {
      font-size: 1.5rem;
      padding: 0 0.5rem;
    }
  }
}
</style>

<script>
import NewExtraField from "./NewExtraField.vue";

import RaisedButton from "../../../components/RaisedButton.vue";
import FlatButton from "../../../components/FlatButton.vue";
import CustomAlert from "../../../components/CustomAlert.vue";
import Loader from "../../../components/Loader.vue";
import DeleteModal from "../../../components/DeleteModal.vue";
import ExtraFieldItem from "../../../components/ExtraFieldItem.vue";
import CustomSearch from "../../../components/CustomSearch.vue";

import { ExtraField } from "../../../models/ExtraField";
import { ExtraFields } from "../../../api";
import { handleError } from "../../../utils";
export default {
  name: "ExtraFields",
  components: {
    NewExtraField,
    CustomAlert,
    FlatButton,
    RaisedButton,
    CustomSearch,
    ExtraFieldItem,
    DeleteModal,
    Loader,
  },
  data: () => ({
    field: null,
    newField: null,
    searchText: "",
    sortAsc: true,
    filter: "All",
    keepField: false,
    extraFields: null,
  }),
  created: function () {
    this.getExtraFields();
    this.resetNewField();
  },
  computed: {
    filteredFields() {
      const search = this.searchText.trim().toLowerCase();
      return this.extraFields
        .filter((field) =>
          this.getFieldTranslation(field, "title")
            .toLowerCase()
            .includes(search)
        )
        .sort((a, b) => {
          if (a.title[this.appLocale] < b.title[this.appLocale]) {
            return this.sortAsc ? -1 : 1;
          }
          if (a.title[this.appLocale] > b.title[this.appLocale]) {
            return this.sortAsc ? 1 : -1;
          }
          return 0;
        });
    },
  },
  methods: {
    resetNewField() {
      this.newField = new ExtraField();
    },
    filterExtraFields(evt) {
      this.filter = evt;
      this.getExtraFields();
    },
    getExtraFields() {
      const params = new URLSearchParams();

      if (this.filter != "All") {
        params.set("hidden", this.filter);
      }

      ExtraFields.getExtraFields(params).then(
        (res) => {
          const fields = res.data;
          this.extraFields = fields;
        },
        (err) => {
          const error = err.response.data;
          handleError(this, err, error.message, error.error);
          this.extraFields = [];
        }
      );
    },
    editField(id) {
      this.newField = this.extraFields.find((field) => field.id == id);
      this.$bvModal.show("new-extra-field");
    },
    deleteModal(id) {
      this.field = this.extraFields.find((field) => field.id == id);
      this.$bvModal.show("delete-modal");
    },
    deleteField() {
      this.$loading.show();

      const params = new URLSearchParams();
      if (!this.field.deleted && this.keepField) params.set("hide", true);

      ExtraFields.deleteExtraField(this.field.id, params).then(
        () => {
          this.$bvToast.toast(this.$t("messages.fieldDelete"), {
            title: this.$t("messages.success"),
            variant: "success",
            solid: true,
          });
          this.extraFields = null;
          this.getExtraFields();
          this.$bvModal.hide("delete-modal");
          this.$loading.hide();
        },
        (err) => {
          const error = err.response.data;
          handleError(this, err, error.message, error.error);
          this.$loading.hide();
        }
      );
    },
    saveField() {
      this.$loading.show();

      if (this.newField.id) {
        ExtraFields.updateExtraField(this.newField).then(
          () => {
            this.$loading.hide();
            this.newField = new ExtraField();
            this.extraFields = null;
            this.getExtraFields();
            this.$bvModal.hide("new-extra-field");
          },
          (err) => {
            const error = err.response.data;
            handleError(this, err, error.message, error.error);
            this.$loading.hide();
          }
        );
      } else {
        ExtraFields.createExtraField(this.newField).then(
          (res) => {
            this.$loading.hide();
            this.extraFields.push(res.data);
            this.newField = new ExtraField();
            this.$bvModal.hide("new-extra-field");
          },
          (err) => {
            const error = err.response.data;

            handleError(this, err, error.message, error.error);
            this.$loading.hide();
          }
        );
      }
    },
  },
};
</script>