<template>
  <div class="contents-catalog">
    <div class="filter-bar" v-if="isMobile">
      <RaisedButton
        class="p-2 px-3"
        :round="true"
        variant="bar"
        @click.native="showFilters"
      >
        <font-awesome-icon icon="filter" :fixedWidth="true"></font-awesome-icon
        ><span class="ml-2">{{ $t("actions.filter") }}</span>
      </RaisedButton>
      <RaisedButton
        class="p-2 px-3"
        :round="true"
        variant="bar"
        @click.native="openContentsMap"
      >
        <font-awesome-icon
          icon="map-marked-alt"
          :fixedWidth="true"
        ></font-awesome-icon
        ><span class="ml-2">{{ $t("navigation.map") }}</span></RaisedButton
      >
    </div>

    <b-row no-gutters>
      <b-col class="d-none d-sm-block" sm="6" md="4" lg="3">
        <div class="filters-wrapper">
          <div class="map-miniature">
            <RaisedButton
              class="full-screen-button"
              @click.native="openContentsMap"
            >
              {{ $t("actions.expandMap") }}
            </RaisedButton>

            <LeafletMap
              class="mini-map"
              :markers="markers"
              :placeholderMode="true"
              :userLocation="userLocation"
              :zoom="7"
            ></LeafletMap>
          </div>
          <div class="filters-container">
            <div class="filters-header border-bottom">
              <CustomSearch
                v-model="searchText"
                placeholder="Search.."
                @search="searchContents"
              />
            </div>
            <div class="filters-body">
              <ContentsFilters
                :catalogMode="true"
                :priceRange="priceFilter"
                :maxDistance="distanceFilter"
                :selectedTags="selectedTags"
                :sortBy="sortBy"
                :selectedCategory="selectedCategory"
                @updated-tags="selectedTags = $event"
                @updated-distance="distanceFilter = $event"
                @updated-price="priceFilter = $event"
                @updated-filters="activeFilters = $event"
                @updated-sort="sortBy = $event"
                @updated-category="selectedCategory = $event"
              />
            </div>
            <div class="filters-footer border-top">
              <RaisedButton
                class="px-4"
                :round="true"
                variant="primary"
                @click.native="searchContents"
                >{{ $t("actions.applyFilter") }}</RaisedButton
              >
            </div>
          </div>
        </div>
      </b-col>

      <b-col class="pl-sm-3" sm="6" md="8" lg="9">
        <template v-if="!contents">
          <b-card
            v-for="index in pageLimit"
            :key="index"
            no-body
            img-left
            class="mb-2"
          >
            <b-skeleton-img card-img="left" width="250px"></b-skeleton-img>
            <b-card-body>
              <b-skeleton width="50%"></b-skeleton>
              <b-skeleton class="mb-3" width="20%"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton class="mb-3" width="100%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card-body>
          </b-card>
        </template>

        <template v-else>
          <CustomAlert v-if="!contents.length">
            <b-icon icon="info-circle"></b-icon>
            <span class="ml-2">{{ $t("common.noContents") }}</span>
          </CustomAlert>

          <template v-else>
            <template v-if="!isMobile">
              <ContentItem
                class="action mb-2"
                v-for="(content, index) of contents"
                :key="index"
                :content="content"
                :catalogMode="true"
                @click.native="
                  $router.push({
                    name: 'ContentDetail',
                    params: { id: content.id },
                  })
                "
              ></ContentItem>

              <b-pagination
                :value="page"
                @input="onPageChange"
                align="center"
                :total-rows="total"
                :per-page="pageLimit"
                v-if="total > pageLimit"
              >
              </b-pagination>
            </template>

            <template v-else>
              <div class="list-wrapper" ref="contentList">
                <ContentItem
                  class="action mb-2"
                  v-for="content of contents"
                  :key="content.id"
                  :mapMode="true"
                  :content="content"
                  :id="'content-' + content.id"
                  @click.native="
                    $router.push({
                      name: 'ContentDetail',
                      params: { id: content.id },
                    })
                  "
                />

                <infinite-loading
                  class="w-100"
                  spinner="spiral"
                  @infinite="infiniteHandler"
                >
                  <span slot="no-results"></span>
                  <span slot="no-more"></span>
                </infinite-loading>
              </div>
            </template>
          </template>
        </template>
      </b-col>
    </b-row>

    <b-modal id="filters-modal" title="Filters" centered>
      <div class="border-bottom">
        <CustomSearch
          v-model="searchText"
          placeholder="Search.."
          @search="searchContents"
        />
      </div>
      <ContentsFilters
        :catalogMode="true"
        :priceRange="priceFilter"
        :maxDistance="distanceFilter"
        :selectedTags="selectedTags"
        :sortBy="sortBy"
        :selectedCategory="selectedCategory"
        @updated-tags="selectedTags = $event"
        @updated-distance="distanceFilter = $event"
        @updated-price="priceFilter = $event"
        @updated-filters="activeFilters = $event"
        @updated-sort="sortBy = $event"
        @updated-category="selectedCategory = $event"
      />
      <div slot="modal-footer" class="w-100 m-0 text-center">
        <RaisedButton
          class="px-4"
          :round="true"
          variant="primary"
          @click.native="searchContents"
          >{{ $t("actions.applyFilter") }}</RaisedButton
        >
      </div>
    </b-modal>

    <FullScreenModal modalName="contents-map" @modal-hidden="initCatalog">
      <ContentsMap
        class="w-100"
        @close="closeContentsMap"
        :mapOnly="isMobile"
      />
    </FullScreenModal>
  </div>
</template>

<style lang="scss">
.contents-catalog {
  padding: 1rem;

  .filter-bar {
    width: 100%;
    margin-bottom: 3.5rem;
    justify-content: space-around;
    display: flex;
    z-index: 20;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .filters-wrapper {
    border-radius: 0.25rem;
    background-color: white;
    box-shadow: 0.25rem 0.25rem 1rem #00000044;

    .map-miniature {
      height: 260px;
      background-color: rgb(90, 150, 230);
      position: relative;

      .full-screen-button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        z-index: 2;
      }
    }
    .mini-map {
      pointer-events: none;
      z-index: 1;
    }
    .filters-container {
      // padding: 0.5rem;
      height: 52vh;
      display: flex;
      flex-direction: column;

      .filters-header,
      .filters-footer {
        text-align: center;
        padding: 0.5rem;
      }

      .filters-body {
        overflow-y: auto;
      }

      .price,
      .categories {
        padding: 1rem;
        margin-bottom: 1rem;
        border: 1px solid #ddd;
      }
    }
  }
}
</style>

<script>
import LeafletMap from "../components/LeafletMap.vue";
import ContentsMap from "./ContentsMap.vue";
import ContentItem from "../components/ContentItem.vue";
import CustomSearch from "../components/CustomSearch.vue";
import CustomAlert from "../components/CustomAlert.vue";

import ContentsFilters from "../components/ContentsFilters.vue";
import RaisedButton from "../components/RaisedButton.vue";
import FullScreenModal from "../components/FullScreenModal.vue";

import { Contents } from "../api";
import { DEFAULT_CONTENTS_LIMIT, SICILY_CENTER } from "../globals";

import { isEmpty } from "lodash";
import { plainObjectsEquality } from "../utils";
import { handleError } from "../utils";

export default {
  name: "ContentsCatalog",
  components: {
    LeafletMap,
    CustomAlert,
    CustomSearch,
    ContentItem,
    FullScreenModal,
    RaisedButton,
    ContentsFilters,
    ContentsMap,
  },
  data: () => ({
    contents: null,
    firstPage: null,
    otherPages: null,
    pageLoading: false,
    searchText: "",
    page: 1,
    isMobile: false,
    markers: [],
    sortBy: null,
    pageLimit: DEFAULT_CONTENTS_LIMIT && 10,
    total: undefined,
    activeFilters: [],
    userLocation: null,
    center: SICILY_CENTER,
    priceFilter: null,
    distanceFilter: null,
    selectedTags: [],
    showModal: false,
    userId: "",
    selectedCategory: null,
  }),
  created() {
    const modalOpen = this.$route.query.map;
    if (!modalOpen) {
      this.initUser();
      this.initLocation();
      this.initContents();
    }

    this.isMobile = window.innerWidth < 576;
    window.addEventListener("resize", this.onWindowResize);
  },
  mounted() {
    const modalOpen = this.$route.query.map == "true";
    if (modalOpen) this.$fsModal.show("contents-map");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    initUser() {
      let userCookie = this.$cookies.get("atlas_user");

      if (userCookie) {
        this.userId = userCookie.id;
      }
    },
    initCatalog() {
      this.initLocation();
      this.initContents();
    },
    initLocation() {
      if (this.$cookies.isKey("USER_POSITION")) {
        this.userLocation = this.$cookies.get("USER_POSITION").split(",");
      }
    },
    initContents() {
      const params = this.getParamsFromUrl();
      this.getContents(params);
    },
    infiniteHandler($state) {
      this.getPageContents($state);
    },
    onWindowResize(event) {
      this.isMobile = event.target.innerWidth < 576;
    },
    getContentsMobile($state) {
      if (!$state) {
        this.markers = null;
        this.contents = null;
      }

      const params = this.getParamsFromUrl($state);

      Contents.getContents(params).then(
        (res) => {
          const contents = res.data.contents;
          if ($state) {
            if (contents.length) {
              this.contents.push(...contents);
              $state.loaded();
            } else $state.complete();
          } else this.contents = contents;
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentErrorMsg",
            "messages.contentError"
          );
        }
      );
    },
    urlParamsIsEmpty: function () {
      const queryParams = this.$route.query;
      return isEmpty(queryParams);
    },
    openContentsMap() {
      this.showModal = true;

      this.$fsModal.show("contents-map");
      this.contents = null;
      this.userLocation = null;
      this.$router.push({
        name: "ContentsCatalog",
        query: { map: true, ...this.$route.query },
      });
    },
    closeContentsMap() {
      this.showModal = false;
      this.$fsModal.hide("contents-map");
      const newParams = { ...this.$route.query };
      delete newParams.box;
      delete newParams.zoom;
      delete newParams.map;
      this.$router.push({
        name: "ContentsCatalog",
        query: newParams,
      });
    },
    getParamsFromUrl: function ($state) {
      const queryParams = this.$route.query;
      const requestParams = new URLSearchParams();

      if (this.isMobile) {
        requestParams.set("pageSize", this.pageLimit);
        if ($state) this.page += 1;
        else this.page = 1;
        requestParams.set("page", this.page);
      } else {
        requestParams.set("pageSize", this.pageLimit);
        requestParams.set("page", this.page);
      }

      requestParams.set("mapMode", "markers");
      requestParams.append("coordsMode", "latlon");

      if (this.user !== "") {
        requestParams.set("user", this.userId);
      }

      if (this.userLocation) requestParams.set("center", this.userLocation);

      if (this.urlParamsIsEmpty()) return requestParams;

      if (queryParams.search) {
        this.searchText = queryParams.search;
      }
      if (queryParams.page) {
        this.page = queryParams.page;
      }
      if (queryParams.tags) {
        this.selectedTags = queryParams.tags.split(",");
        requestParams.set("tags", queryParams.tags);
      }
      if (queryParams.minPrice) {
        this.priceFilter = queryParams.minPrice;
        requestParams.set("minPrice", queryParams.minPrice);
      }

      if (queryParams.categories) {
        this.selectedCategory = queryParams.categories;
        requestParams.set("categories", queryParams.categories);
      }

      if (queryParams.maxPrice) {
        this.priceFilter += ":" + queryParams.maxPrice;
        requestParams.set("maxPrice", queryParams.maxPrice);
      }
      if (queryParams.maxDistance) {
        this.distanceFilter = Number.parseInt(queryParams.maxDistance);
        requestParams.set("maxDistance", queryParams.maxDistance);
      }
      if (queryParams.sortBy) {
        this.sortBy = queryParams.sortBy;
        requestParams.set("sortBy", queryParams.sortBy);
      }

      if (this.searchText.length) requestParams.set("q", this.searchText);

      return requestParams;
    },
    onPageChange(page) {
      this.pageLoading = true;
      this.page = page;
      this.updateUrl();
      if (page == 1) {
        this.contents = this.firstPage;
        this.pageLoading = false;
      } else this.getPageContents();
    },
    getPageContents($state) {
      let params = new URLSearchParams();

      if (!this.otherPages || this.otherPages.length == 0) {
        $state.complete();
        return;
      }
      if ($state) this.page += 1;

      const pageBody = this.otherPages[this.page - 2]; // index starts from 0, but 0 is the 2nd page
      if (!pageBody) {
        $state.complete();
        return;
      }
      if (this.userId) {
        params.set("user", this.userId);
      }

      Contents.retrievePageContents(pageBody, params).then(
        ({ data: contents }) => {
          this.contents = contents;
          this.pageLoading = false;
          //if (!this.markers) this.prepareMinimap();
        },
        (err) => {
          console.log(err);
          this.contents = [];
          handleError(
            this,
            err,
            "messages.contentErrorMsg",
            "messages.contentError"
          );
        }
      );
    },
    getContents(params) {
      if (!params) {
        params = new URLSearchParams();

        if (this.searchText.length) params.set("q", this.searchText);
        if (this.distanceFilter) params.set("maxDistance", this.distanceFilter);
        if (this.sortBy) params.set("sort", this.sortBy);

        if (this.selectedTags.length) {
          const tags = this.selectedTags.join(",");
          params.set("tags", tags);
        }
        if (this.selectedCategory) {
          params.set("categories", this.selectedCategory);
        }
        if (this.priceFilter) {
          const [minPrice, maxPrice] = this.priceFilter.split(":");
          params.set("minPrice", minPrice);
          if (maxPrice) params.set("maxPrice", maxPrice);
        }
        params.set("mapMode", "markers");
        params.append("coordsMode", "latlon");
      }

      if (this.userLocation) params.set("center", this.userLocation);

      if (this.userId) params.set("user", this.userId);

      params.set("pageSize", this.pageLimit);
      params.set("page", this.page);

      Contents.getContents(params).then(
        ({ data: contentPagination }) => {
          this.total = contentPagination.total;
          this.otherPages = contentPagination.nextPages;
          this.firstPage = contentPagination.firstPage;
          this.markers = contentPagination.markers;

          if (this.page != 1) {
            if (this.otherPages.length) this.getPageContents();
            else {
              this.contents = this.firstPage;
              this.page = 1;
              this.updateUrl();
            }
          } else {
            this.contents = this.firstPage;
            //this.prepareMinimap();
          }
        },
        (err) => {
          console.log(err);
          this.contents = [];
          handleError(
            this,
            err,
            "messages.contentErrorMsg",
            "messages.contentError"
          );
        }
      );
    },
    searchContents() {
      this.updateUrl();
      this.page = 1;
      this.contents = null;
      this.getContents();
    },
    showFilters() {
      this.$bvModal.show("filters-modal");
    },
    updateUrl() {
      const currentQuery = this.$route.query;
      var newQuery = {};
      if (this.searchText !== "") newQuery.search = this.searchText;
      if (this.selectedTags.length) newQuery.tags = this.selectedTags.join(",");
      if (this.priceFilter) {
        const [minPrice, maxPrice] = this.priceFilter.split(":");
        newQuery.minPrice = minPrice;
        if (maxPrice) newQuery.maxPrice = maxPrice;
      }
      if (this.distanceFilter) newQuery.maxDistance = this.distanceFilter;
      if (this.sortBy) newQuery.sortBy = this.sortBy;
      newQuery.page = this.page;
      if (this.selectedCategory) newQuery.categories = this.selectedCategory;
      if (this.user !== "") {
        newQuery.user = this.userId;
      }
      if (!plainObjectsEquality(currentQuery, newQuery)) {
        this.$router.push({
          name: "ContentsCatalog",
          query: newQuery,
        });
      }
    },
    prepareMinimap() {
      this.markers = this.contents.map((content) => ({
        coordinates: content.location.coordinates,
        title: content.abstract,
        address: content.address,
        id: content.id,
      }));
    },
  },
};
</script>