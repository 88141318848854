<template>
  <validation-observer ref="fieldObserver" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(emitTag)">
      <validation-provider
        name="Name"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group
          id="tag-label-it-fieldset"
          :label="`${$t('common.name')} (${$t('language.it.short')})`"
          label-cols="4"
          label-for="tag-label-it"
        >
          <b-form-input
            id="tag-label-it"
            v-model="newTag.label[0].text"
            :placeholder="`${$t(
              'admin.platformManagement.tags.edit.tagName'
            )} (${$t('language.it.full')})`"
            :state="getValidationState(validationContext)"
          ></b-form-input>

          <b-form-invalid-feedback id="tag-label-it-live-feedback">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group
        id="tag-label-en-fieldset"
        :label="`${$t('common.name')} (${$t('language.en.short')})`"
        label-cols="4"
        label-for="tag-label-en"
      >
        <b-form-input
          id="tag-label-en"
          v-model="newTag.label[1].text"
          :placeholder="`${$t(
            'admin.platformManagement.tags.edit.tagName'
          )} (${$t('language.en.full')})`"
        ></b-form-input>
      </b-form-group>

      <br />

      <b-row align-h="end" no-gutters>
        <FlatButton @click.native="$emit('cancel')">{{
          $t("actions.cancel")
        }}</FlatButton>
        <RaisedButton class="ml-3" type="submit" variant="success">{{
          $t("actions.confirm")
        }}</RaisedButton>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import RaisedButton from "../../../components/RaisedButton.vue";
import FlatButton from "../../../components/FlatButton.vue";

export default {
  name: "NewTag",
  props: {
    newTag: Object,
  },
  components: {
    FlatButton,
    RaisedButton,
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    emitTag() {
      this.$emit("submit-tag", this.newTag);
    },
  },
};
</script>