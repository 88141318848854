<template>
  <div
    :class="['avatar', size]"
    :style="{ backgroundImage: 'url(' + src + ')' }"
  >
    {{ userInitials }}
  </div>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    user: Object,
    size: {
      type: String,
      default: "md",
    },
    src: String,
  },
  computed: {
    userInitials: function () {
      return this.user
        ? this.user.firstName.charAt(0) + this.user.lastName.charAt(0)
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #b5b5b5;
  color: #555;
  font-weight: 600;
  text-transform: uppercase;
  background-color: white;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &.lg {
    width: 60px;
    height: 60px;
    font-size: 1.5rem;
  }

  &.md {
    height: 45px;
    width: 45px;
    font-size: 1.2rem;
  }

  &.sm {
    height: 35px;
    width: 35px;
    font-size: 1rem;
  }
}
</style>