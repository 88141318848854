<template>
  <div class="recap-tab mt-3">
    <b-row no-gutters align-h="center">
      <b-col>
        <div class="p-3 border bg-light">
          <h4>{{ $t("components.availabilityForm.summary") }}</h4>
          <table class="w-100 mb-2">
            <thead>
              <tr>
                <th width="10%"></th>
                <th width="70%"></th>
                <th width="10%"></th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="reservation.adultAmount">
                <td>x{{ reservation.adultAmount }}</td>
                <td>{{ $t("common.adults") }}</td>
                <td>
                  <span class="price-cut" v-if="discount != 0"
                    >{{ adultsPartial }}€</span
                  >
                </td>
                <td>
                  <PriceLabel
                    v-if="discount === 0"
                    :price="adultsPartial"
                    size="sm"
                  />

                  <PriceLabel
                    v-else
                    :price="calculateDiscount(adultsPartial, discount)"
                    size="sm"
                  />
                </td>
              </tr>
              <tr v-if="reservation.kidAmount">
                <td>x{{ reservation.kidAmount }}</td>
                <td>{{ $t("common.children") }}</td>
                <td>
                  <span class="price-cut" v-if="discount != 0"
                    >{{ kidsPartial }}€</span
                  >
                </td>
                <td>
                  <PriceLabel
                    v-if="discount === 0"
                    :price="kidsPartial"
                    size="sm"
                  />

                  <PriceLabel
                    v-else
                    :price="calculateDiscount(kidsPartial, discount)"
                    size="sm"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="option-recap"
            v-for="(option, index) of selectedOptions"
            :key="index"
          >
            <p>
              <font-awesome-icon size="xs" icon="plus"></font-awesome-icon>
              <b class="ml-2">{{ getFieldTranslation(option, "title") }}</b>
            </p>
            <div class="option-body">
              <table class="w-100">
                <thead>
                  <tr>
                    <th width="10%"></th>
                    <th width="70%"></th>
                    <th width="10%"></th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="reservation.adultAmount">
                    <td>x{{ reservation.adultAmount }}</td>
                    <td>{{ $t("common.adults") }}</td>

                    <td>
                      <span class="price-cut" v-if="discount !== 0"
                        >{{
                          option.adultPrice * reservation.adultAmount
                        }}€</span
                      >
                    </td>
                    <td>
                      <PriceLabel
                        v-if="discount === 0"
                        :price="option.adultPrice"
                        size="sm"
                      />

                      <PriceLabel
                        v-else
                        :price="
                          calculateDiscount(
                            option.adultPrice * reservation.adultAmount,
                            discount
                          )
                        "
                        size="sm"
                      />
                    </td>
                  </tr>
                  <tr v-if="reservation.kidAmount">
                    <td>x{{ reservation.kidAmount }}</td>
                    <td>{{ $t("common.children") }}</td>

                    <td>
                      <span class="price-cut" v-if="discount !== 0"
                        >{{ option.kidPrice * reservation.kidAmount }}€</span
                      >
                    </td>
                    <td>
                      <PriceLabel
                        v-if="discount === 0"
                        :price="option.kidPrice"
                        size="sm"
                      />

                      <PriceLabel
                        v-else
                        :price="
                          calculateDiscount(
                            option.kidPrice * reservation.kidAmount,
                            discount
                          )
                        "
                        size="sm"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr />

          <b-row no-gutters class="m-1" align-v="end">
            <b-col v-if="daysInvolved <= 1">{{$t("components.bookingWizard.total")}} </b-col>
            <b-col v-else> {{$t("components.bookingWizard.totalDays", {days:daysInvolved}) }}</b-col>
            <b-col class="text-right">
              <PriceLabel v-if="discount === 0" :price="reservationTotal" />
              <div class="d-flex align-items-center justify-content-end" v-else>
                <span class="price-cut lg mr-2" v-if="discount !== 0"
                  >{{ reservationTotal }}€</span
                >
                <PriceLabel
                  :price="calculateDiscount(reservationTotal, discount)"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import PriceLabel from "../PriceLabel.vue";
import { calculateDiscount } from "../../utils";
import { eachDayOfInterval } from "date-fns";
export default {
  name: "RecapView",
  components: {
    PriceLabel,
  },
  props: {
    pack: Object,
    reservation: Object,
    discount: Number,
  },
  data() {
    return {
      reservationOptions: this.pack.price.options.map((option) => ({
        title: option.title,
        selected: false,
        id: option.id,
        adultPrice: option.adultPrice,
        kidPrice: option.kidPrice,
        perPerson: option.perPerson,
      })),
      options: [],
    };
  },
  computed: {
    adultsPartial() {
      return this.pack.price.perPerson
        ? this.reservation.adultAmount * this.pack.price.adultPrice
        : this.pack.price.adultPrice;
    },
    kidsPartial() {
      return this.pack.price.kidPrice
        ? this.reservation.kidAmount * this.pack.price.kidPrice
        : 0;
    },
    selectedOptions() {
      return this.pack.price.options.filter((option) =>
        this.reservation.options.includes(option.id)
      );
    },
    reservationTotal() {
      const optionsPartial = this.selectedOptions.reduce(
        (accumulator, current) => {
          if (!current.perPerson) return accumulator + current.adults;
          else {
            const optionKidsTotal =
              current.kidPrice * this.reservation.kidAmount;
            const optionAdultsTotal =
              current.adultPrice * this.reservation.adultAmount;

            return (accumulator = optionKidsTotal + optionAdultsTotal);
          }
        },
        0
      );

      return (this.kidsPartial + this.adultsPartial + optionsPartial) * this.daysInvolved;
    },
    daysInvolved(){
      if(this.reservation.start && this.reservation.end){
      return eachDayOfInterval({start: (new Date(this.reservation.start)), end: (new Date(this.reservation.end))}).length
      }else return 0
    }
  },
  methods: {
    validate() {
      this.reservation.options = this.selectedOptions.map((opt) => opt.id);
      this.reservation.sum = this.reservationTotal;
      this.$emit("on-validate", true, this.reservation);
      return true;
    },
    calculateDiscount(price, discount) {
      return calculateDiscount(price, discount);
    },
  },
};
</script>
