<template>
  <div class="contents-filters">
    <div class="filter-group d-flex align-items-center justify-content-between">
      <h6 class="page-title mb-0">{{ $t("common.order") }}</h6>

      <CustomRadios
        :options="sortOptions"
        size="sm"
        direction="row"
        class="justify-content-center"
        v-model="activeSort"
      />
    </div>
    <div class="filter-group">
      <h6 class="page-title">{{$t("admin.navigation.categories")}}</h6>
      
      <b-form-select v-model="activeCategory" :options="categoryOptions">
      </b-form-select>
    </div>

    <div class="filter-group">
      <h6 class="page-title">{{ $t("common.tag") }}</h6>

      <div class="tags-group" v-if="tagsCheckboxes">
        <b-row no-gutters>
          <b-col cols="6" v-for="(tag, index) of tagsCheckboxes" :key="index">
            <CustomCheckbox
              :id="tag.id"
              :label="getFieldTranslation(tag, 'label')"
              :value="tag.value"
              @input="toggleTag($event, index)"
            />
          </b-col>
        </b-row>
      </div>

      <Loader :size="4" v-else />
    </div>

    <div class="filter-group" :class="{ 'h-100': !catalogMode }">
      <h6 class="page-title">{{ $t("common.price") }}</h6>

      <CustomRadios
        :options="priceOptions"
        direction="row"
        :columns="6"
        v-model="activePrice"
      />
    </div>

    <div class="filter-group" :class="{ 'h-100': !catalogMode }">
      <h6 class="page-title">{{ $t("common.distance") }}</h6>

      <CustomRadios
        :options="distanceOptions"
        direction="row"
        :columns="6"
        v-model="activeDistance"
      />
    </div>
  </div>
</template>

<style lang="scss">
.contents-filters {
  .filter-group {
    padding: 0.5rem;
    background-color: white;

    &:not(:last-of-type) {
      border-bottom: 1px solid #ddd;
    }

    .page-title {
      padding: 0 0.5rem;
      margin-bottom: 1rem;
    }

    .tags-group {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}
</style>

<script>
import { Tags, Categories } from "../api";

import Loader from "./Loader.vue";
import CustomRadios from "./CustomRadios.vue";
import CustomCheckbox from "./CustomCheckbox.vue";
import { handleError } from "../utils";

export default {
  name: "ContentsFilters",
  props: {
    priceRange: String,
    sortBy: String,
    selectedTags: Array,
    maxDistance: Number,
    catalogMode: Boolean,
    selectedCategory: String,
  },
  components: {
    CustomCheckbox,
    CustomRadios,
    Loader,
  },
  data: () => ({
    tags: null,
    tagsCheckboxes: null,
    priceOptions: [
      { label: "0 - 50€", value: "0:50" },
      { label: "50 - 100€", value: "50:100" },
      { label: "100 - 150€", value: "100:150" },
      { label: "> 150€", value: "150" },
    ],
    categories: null,
  }),
  created: function () {
    this.initTags();
    this.initCategories();
  },
  methods: {
    initTags() {
      Tags.getTags().then(
        (res) => {
          this.tags = res.data;
          this.tagsCheckboxes = this.tags
            .map((tag) => ({
              id: tag.id,
              label: tag.label,
              value: this.selectedTags.includes(tag.id),
            }))
            .sort((a, b) => a.label < b.label);
        },
        (err) => {
          console.log(err);
          this.tags = [];
          handleError(this, err, "messages.tagsErrorMsg", "messages.tagsError");
        }
      );
    },
    initCategories() {
      Categories.getCategories().then(
        (res) => {
          this.categories = res.data;
        },
        (err) => {
          console.log(err);
          this.categories = [];
          handleError(this, err, "messages.tagsErrorMsg", "messages.tagsError");
        }
      );
    },
    toggleTag(event, index) {
      this.$set(this.tagsCheckboxes[index], "value", event);
      this.activeTags = this.tagsCheckboxes
        .filter((tag) => tag.value)
        .map((tag) => tag.id);
    },
  },
  computed: {
    activePrice: {
      get() {
        return this.priceRange;
      },
      set(value) {
        this.$emit("updated-price", value);
      },
    },
    activeTags: {
      get() {
        return this.selectedTags;
      },
      set(value) {
        this.$emit("updated-tags", value);
      },
    },
    activeDistance: {
      get() {
        return this.maxDistance;
      },
      set(value) {
        this.$emit("updated-distance", value);
      },
    },
    activeSort: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit("updated-sort", value);
      },
    },
    distanceOptions() {
      return [
        { label: this.$t("filters.noLimits"), value: null },
        { label: `${this.$t("filters.within")} 1km`, value: 1000 },
        { label: `${this.$t("filters.within")}  5km`, value: 5000 },
        { label: `${this.$t("filters.within")}  10km`, value: 10000 },
        { label: `${this.$t("filters.within")}  20km`, value: 20000 },
        { label: `${this.$t("filters.within")}  50km`, value: 50000 },
        { label: `${this.$t("filters.within")}  100km`, value: 100000 },
        { label: `${this.$t("filters.within")}  500km`, value: 500000 },
        { label: `${this.$t("filters.within")}  1000km`, value: 1000000 },
      ];
    },
    sortOptions() {
      return [
        { label: this.$t("common.price"), value: "price" },
        { label: this.$t("common.distance"), value: "distance" },
      ];
    },
    activeCategory: {
      get() {
        return this.selectedCategory;
      },
      set(value) {
        this.$emit("updated-category", value);
      },
    },
    categoryOptions() {
      if (this.categories) {
        let categoriesMapped = this.categories.map((category) => ({
          text: this.getFieldTranslation(category, "name"),
          value: category.id,
        }));
        categoriesMapped.unshift({
          text: this.$t("filters.noCategory"),
          value: null,
        });
        return categoriesMapped;
      } else {
        return [];
      }
    },
  },
};
</script>