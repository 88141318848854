import OverlayLoader from '../components/OverlayLoader.vue';
import { EventBus } from '../eventBus';

export default {
    install(Vue) {
        Vue.component(OverlayLoader.name, OverlayLoader);
        
        Vue.prototype.$loading = {
            show: function () {
                EventBus.$emit('show-loader');
            },
            hide: function () {
                EventBus.$emit('hide-loader');
            }
        }
    }
}