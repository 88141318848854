<template>
  <validation-observer ref="fieldObserver" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(emitField)">
      <validation-provider
        name="Name"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group
          id="field-name-it-fieldset"
          :label="`${$t('common.name')} (${$t('language.it.short')})`"
          label-cols="4"
          label-for="field-name-it"
        >
          <b-form-input
            id="field-name-it"
            v-model="newField.title[0].text"
            :placeholder="`${$t(
              'admin.platformManagement.extraFields.edit.placeholders.name'
            )} (${$t('language.it.full')})`"
            :state="getValidationState(validationContext)"
          ></b-form-input>

          <b-form-invalid-feedback id="field-name-it-live-feedback">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group
        id="field-name-en-fieldset"
        :label="`${$t('common.name')} (${$t('language.en.short')})`"
        label-cols="4"
        label-for="field-name-en"
      >
        <b-form-input
          id="field-name-en"
          v-model="newField.title[1].text"
          :placeholder="`${$t(
            'admin.platformManagement.extraFields.edit.placeholders.name'
          )} (${$t('language.en.full')})`"
        ></b-form-input>
      </b-form-group>

      <validation-provider
        name="Tipo"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group
          id="field-type-fieldset"
          :label="$t('admin.platformManagement.extraFields.edit.type')"
          label-cols="4"
          label-for="field-type"
        >
          <b-form-select
            id="field-type"
            v-model="newField.type"
            placeholder="Tipo"
            :state="getValidationState(validationContext)"
          >
            <b-form-select-option value="number">{{
              $t("admin.platformManagement.extraFields.edit.typeNumber")
            }}</b-form-select-option>
            <b-form-select-option value="string">
              {{ $t("admin.platformManagement.extraFields.edit.typeString") }}
            </b-form-select-option>
            <b-form-select-option value="boolean">
              {{ $t("admin.platformManagement.extraFields.edit.typeBoolean") }}
            </b-form-select-option>
          </b-form-select>

          <b-form-invalid-feedback id="field-type-live-feedback">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group
        id="field-value-fieldset"
        :label="$t('admin.platformManagement.extraFields.edit.value')"
        label-cols="4"
        label-for="field-value"
      >
        <b-form-input
          id="field-value"
          v-model="newField.stringValue[0].text"
          v-if="newField.type == 'string'"
          :placeholder="`${$t(
            'admin.platformManagement.extraFields.edit.value'
          )} (${$t('language.it.full')})`"
        ></b-form-input>

        <b-form-input
          id="field-value"
          v-model="newField.stringValue[0].text"
          v-if="newField.type == 'number'"
          type="number"
          :placeholder="
            $t('admin.platformManagement.extraFields.edit.defaultValue')
          "
        ></b-form-input>

        <b-form-checkbox
          id="field-value"
          v-if="newField.type == 'boolean'"
          v-model="newField.boolValue"
        >
          <span>{{ newField.boolValue ? "TRUE" : "FALSE" }}</span>
        </b-form-checkbox>
      </b-form-group>

      <template v-if="newField.type == 'string'">
        <b-form-group
          id="field-value-en-fieldset"
          :label="`${$t(
            'admin.platformManagement.extraFields.edit.value'
          )} (${$t('language.en.full')})`"
          label-cols="4"
          label-for="field-value-en"
        >
          <b-form-input
            id="field-value-en"
            v-model="newField.stringValue[1].text"
            :placeholder="`${$t(
              'admin.platformManagement.extraFields.edit.value'
            )} (${$t('language.en.full')})`"
          ></b-form-input>
        </b-form-group>
      </template>

      <h6>{{ $t("admin.platformManagement.extraFields.edit.icon") }}</h6>

      <IconsTable
        :selected="newField.icon"
        @selected-icon="newField.icon = $event"
      />

      <br />

      <b-row align-h="end" no-gutters>
        <FlatButton @click.native="$emit('cancel')">{{
          $t("actions.cancel")
        }}</FlatButton>
        <RaisedButton class="ml-3" type="submit" variant="success">{{
          $t("actions.save")
        }}</RaisedButton>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import RaisedButton from "../../../components/RaisedButton.vue";
import FlatButton from "../../../components/FlatButton.vue";
import IconsTable from "../../../components/IconsTable.vue";

export default {
  name: "NewExtraField",
  props: {
    newField: Object,
  },
  components: {
    IconsTable,
    FlatButton,
    RaisedButton,
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    emitField() {
      this.$emit("submit-field", this.newField);
    },
  },
};
</script>