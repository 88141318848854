<template>
  <validation-observer ref="fieldObserver" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(emitCategory)">
      <validation-provider
        :name="$t('common.amount')"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group
          id="amount-fieldset"
          :label="`${$t('common.amount')}`"
          label-cols="4"
          label-for="amount"
        >
          <b-form-input
            id="amount"
            v-model.number="discount.amount"
            min="0"
            max="100"
            :placeholder="$t('components.discounts.discountRate')"
            :state="getValidationState(validationContext)"
          ></b-form-input>

          <b-form-invalid-feedback id="amount-fieldset">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-row align-h="end" no-gutters>
        <FlatButton @click.native="$emit('cancel')">{{
          $t("actions.cancel")
        }}</FlatButton>
        <RaisedButton class="ml-3" type="submit" variant="success">{{
          $t("actions.confirm")
        }}</RaisedButton>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import RaisedButton from "../../../components/RaisedButton.vue";
import FlatButton from "../../../components/FlatButton.vue";


export default {
  name: "EditDiscount",
  props: {
    discount: Object,
  },
  components: {
    FlatButton,
    RaisedButton,
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    emitCategory() {
      this.$emit("submit-discount", this.discount);
    },
  },
};
</script>