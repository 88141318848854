<template>
  <div class="contents-map">
    <div class="contents-map-body">
      <b-col lg="3" md="4" sm="6" class="border-right p-0" v-if="!mapOnly">
        <div class="contents-sidebar">
          <template v-if="showFilters">
            <div class="filters-body">
              <div class="p-2 border-bottom">
                <CustomSearch v-model="searchText" placeholder="Search.." />
              </div>
              <ContentsFilters
                class="p-2"
                :priceRange="priceFilter"
                :maxDistance="distanceFilter"
                :selectedTags="selectedTags"
                :sortBy="sortBy"
                :catalogMode="true"
                :selectedCategory="selectedCategory"
                @updated-tags="selectedTags = $event"
                @updated-price="priceFilter = $event"
                @updated-distance="distanceFilter = $event"
                @updated-filters="activeFilters = $event"
                @updated-sort="sortBy = $event"
                @updated-category="selectedCategory = $event"
              />
            </div>
            <div class="sidebar-footer border-top">
              <RaisedButton variant="info" @click.native="applyFilters">
                <font-awesome-icon icon="check"></font-awesome-icon>
                <span class="ml-2">{{ $t("actions.applyFilter") }}</span>
              </RaisedButton>
            </div>
          </template>

          <template v-else>
            <Loader class="py-5" v-if="!contents" variant="dark" :size="5" />

            <template v-else>
              <CustomAlert class="m-2" variant="info" v-if="!contents.length">
                <b-icon icon="info-circle"></b-icon>
                <span class="ml-2">{{ $t("common.noContents") }}</span>
              </CustomAlert>

              <div class="list-wrapper" ref="contentList">
                <ContentItem
                  class="action mb-2"
                  v-for="content of contents"
                  :key="content.id"
                  :mapMode="true"
                  :content="content"
                  :id="'content-' + content.id"
                  :highlight="highlighted == content.id"
                  @click.native="contentDetail(content.id)"
                />

                <infinite-loading
                  class="w-100"
                  spinner="spiral"
                  @infinite="infiniteHandler"
                >
                  <span slot="no-results"></span>
                  <span slot="no-more"></span>
                </infinite-loading>
              </div>
            </template>
          </template>
        </div>
      </b-col>

      <b-col class="position-relative p-0">
        <RaisedButton
          class="show-filters"
          @click.native="toggleFilters"
          :variant="showFilters ? 'dark' : 'light'"
        >
          <font-awesome-icon
            icon="filter"
            :class="{ 'text-dark': !showFilters }"
          ></font-awesome-icon>
        </RaisedButton>

        <RaisedButton
          class="close-map"
          variant="danger"
          @click.native="$emit('close')"
        >
          <font-awesome-icon icon="times"></font-awesome-icon>
        </RaisedButton>

        <LeafletMap
          :maxHeight="260"
          :markers="markers"
          :placeholderMode="false"
          :userLocation="userLocation"
          @map-current-zone="updateMapBoundingBox"
          @map-loaded="onMapLoad"
        ></LeafletMap>
      </b-col>
    </div>

    <b-modal id="map-filters-modal" title="Filters" centered>
      <div class="border-bottom">
        <CustomSearch v-model="searchText" placeholder="Search.." />
      </div>
      <ContentsFilters
        :catalogMode="true"
        :priceRange="priceFilter"
        :maxDistance="distanceFilter"
        :selectedTags="selectedTags"
        :sortBy="sortBy"
        :selectedCategory="selectedCategory"
        @updated-tags="selectedTags = $event"
        @updated-distance="distanceFilter = $event"
        @updated-price="priceFilter = $event"
        @updated-filters="activeFilters = $event"
        @updated-sort="sortBy = $event"
        @updated-category="selectedCategory = $event"
      />
      <div slot="modal-footer" class="w-100 m-0 text-center">
        <RaisedButton
          class="px-4"
          :round="true"
          variant="primary"
          @click.native="applyFilters"
          >{{ $t("actions.applyFilter") }}</RaisedButton
        >
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.contents-map {
  display: flex;
  flex-direction: column;

  .contents-map-body {
    flex: 1 0;
    display: flex;

    .contents-sidebar {
      display: flex;
      flex-direction: column;
      max-height: 100vh;

      .filters-body {
        flex: 1 0;
        overflow-y: auto;
      }

      .sidebar-header,
      .sidebar-footer {
        padding: 0.5rem;
        text-align: center;
      }
    }

    .map-container {
      height: 100vh;

      @media (max-width: 576px) {
        height: 100vh;
      }
    }
  }

  .close-map,
  .show-filters {
    position: absolute;
    margin: 1rem;
    padding: 0;
    z-index: 20;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .show-filters {
    left: 0;
  }

  .close-map {
    right: 0;
  }

  .list-wrapper {
    padding: 0.25rem 0.5rem;
    // height: 100%;
    overflow-y: auto;

    .content-item {
      flex-direction: column;

      .content-image {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
    }
  }
}
</style>

<script>
import LeafletMap from "../components/LeafletMap.vue";
import Loader from "../components/Loader.vue";
import ContentItem from "../components/ContentItem.vue";
import CustomAlert from "../components/CustomAlert.vue";
import CustomSearch from "../components/CustomSearch.vue";
import ContentsFilters from "../components/ContentsFilters.vue";
import RaisedButton from "../components/RaisedButton.vue";

import { Marker } from "../models/Marker.js";
//import { Cluster } from "../models/Cluster.js";

import { EventBus } from "../eventBus";

import { MULTIMEDIA_API, DEFAULT_CONTENTS_LIMIT } from "../globals";
import { Contents } from "../api";
import { plainObjectsEquality, handleError } from "../utils";

export default {
  name: "ContentsMap",
  props: {
    mapOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LeafletMap,
    Loader,
    ContentItem,
    RaisedButton,
    ContentsFilters,
    CustomSearch,
    CustomAlert,
  },
  data: () => ({
    searchText: "",
    userLocation: null,
    distanceFilter: null,
    selectedTags: [],
    priceFilter: null,
    sortBy: null,
    showFilters: false,
    pageLimit: DEFAULT_CONTENTS_LIMIT && 5,
    sicilyBounds: [
      [11.817398, 36.661452],
      [15.696895, 38.672868],
    ],
    boxBounds: [],
    contents: null,
    markers: null,
    clusters: null,
    page: 1,
    mapZoom: 7,
    mapCenter: undefined,
    highlighted: null,
    selectedCategory: null,
  }),
  created() {
    if (this.$cookies.isKey("USER_POSITION")) {
      this.userLocation = this.$cookies.get("USER_POSITION").split(",");
    }
    this.getValuesFromUrl();
  },
  methods: {
    infiniteHandler($state) {
      this.getPageContents($state);
    },
    toggleFilters() {
      if (this.mapOnly) this.$bvModal.show("map-filters-modal");
      else this.showFilters = !this.showFilters;
    },
    applyFilters() {
      this.updateUrl();
      this.searchContents();
    },
    updateUrl() {
      const currentQuery = this.$route.query;
      var newQuery = {};
      if (this.searchText !== "") newQuery.search = this.searchText;
      if (this.selectedTags.length) newQuery.tags = this.selectedTags.join(",");
      if (this.priceFilter) {
        const [minPrice, maxPrice] = this.priceFilter.split(":");
        newQuery.minPrice = minPrice;
        if (maxPrice) newQuery.maxPrice = maxPrice;
      }
      if (this.distanceFilter) newQuery.maxDistance = this.distanceFilter;
      if (this.sortBy) newQuery.sortBy = this.sortBy;
      if (this.selectedCategory) newQuery.categories = this.selectedCategory;
      // newQuery.page = this.page;
      newQuery.zoom = this.mapZoom;
      newQuery.center = this.mapCenter.join(",");
      newQuery.map = true;
      if (this.user !== "") {
        newQuery.user = this.userId;
      }
      if (!plainObjectsEquality(currentQuery, newQuery)) {
        this.$router.push({
          name: "ContentsCatalog",
          query: newQuery,
        });
      }
    },
    getValuesFromUrl() {
      const queryParams = this.$route.query;

      if (queryParams == { map: "true" }) return;

      if (queryParams.box) {
        this.boxBounds = queryParams.box
          .split(",")
          .map((num) => Number.parseFloat(num));
      }
      if (queryParams.categories) {
        this.activeFilters = queryParams.categories.split(",");
      }
      if (queryParams.tags) {
        this.selectedTags = queryParams.tags.split(",");
      }
      if (queryParams.minPrice) {
        this.priceFilter = queryParams.minPrice;
      }
      if (queryParams.maxPrice) {
        this.priceFilter += ":" + queryParams.maxPrice;
      }
      if (queryParams.maxDistance) {
        this.distanceFilter = Number.parseInt(queryParams.maxDistance);
      }
      if (queryParams.search) {
        this.searchText = queryParams.search;
      }
      if (queryParams.zoom) {
        this.mapZoom = Number.parseFloat(queryParams.zoom);
      }
      if (queryParams.center) {
        this.mapCenter = queryParams.center
          .split(",")
          .map((num) => Number.parseFloat(num));
      }
    },
    generateRequestParams() {
      const requestParams = new URLSearchParams();

      requestParams.set("box", this.boxBounds.join(","));

      if (this.searchText.length) requestParams.set("q", this.searchText);
      if (this.distanceFilter)
        requestParams.set("maxDistance", this.distanceFilter);
      if (this.sortBy) requestParams.set("sort", this.sortBy);
      if (this.selectedTags.length) {
        requestParams.set("tags", this.selectedTags.join(","));
      }
      if (this.priceFilter) {
        const [minPrice, maxPrice] = this.priceFilter.split(":");
        requestParams.set("minPrice", minPrice);
        if (maxPrice) requestParams.set("maxPrice", maxPrice);
      }
      if (this.distanceFilter) {
        requestParams.set("distance", this.distanceFilter);
      }
      if (this.mapZoom) requestParams.set("zoom", Math.floor(this.mapZoom));

      requestParams.set("pageSize", this.pageLimit);
      requestParams.set("page", this.page);

      if (this.userLocation) {
        requestParams.set("center", this.userLocation.join(","));
      }

      if (this.selectedCategory) {
        requestParams.set("categories", this.selectedCategory);
      }

      requestParams.append("mapMode", "markers");
      requestParams.append("coordsMode", "latlon");

      return requestParams;
    },
    searchContents() {
      this.$bvModal.hide("map-filters-modal");
      this.updateUrl();
      this.getContents();
    },
    emitHighlighted(id) {
      EventBus.$emit("show-popup", id);
    },
    deselectContent() {
      this.highlighted = null;
    },
    selectContent(id) {
      this.highlighted = id;

      const listEl = document.getElementById(`content-${id}`);

      if (!listEl) return;

      this.$refs.contentList.scroll({
        behavior: "smooth",
        top: listEl.offsetTop,
      });
    },
    updateMapBoundingBox(evt) {
      const { center, boxBounds, zoom } = evt;
      this.boxBounds = boxBounds;
      this.mapCenter = center;
      this.mapZoom = zoom;
      this.updateUrl();
      this.getContents();
    },
    onMapLoad(evt) {
      const queryParams = this.$route.query;

      if (Boolean(queryParams.map) !== true) {
        EventBus.$emit("map-center", this.mapCenter);
        console.warn("not fullscreen, center this.");
      } else {
        const { center, boxBounds, zoom } = evt;
        this.boxBounds = boxBounds;
        this.mapCenter = center;
        this.mapZoom = zoom;
        this.getContents();
      }
    },
    getContents() {
      this.markers = null;
      this.contents = null;

      const params = this.generateRequestParams();

      Contents.getContents(params).then(
        async ({ data: clustersData }) => {
          this.contents = clustersData.firstPage;
          this.otherPages = clustersData.nextPages;

          const markers = clustersData.markers;
          const promises = markers.map(async (mar) => {
            const content = this.contents.find(
              (content) => content.id == mar.id
            );

            if (content) {
              const marker = new Marker(
                content.id,
                mar.coordinates,
                content.title,
                `${MULTIMEDIA_API}/data/${content.id}/main`,
                content.address,
                content.category
              );
              return marker;
            } else {
              return await this.getMarkerDetails(mar.id);
            }
          });
          this.markers = (await Promise.all(promises)).filter(
            (res) => !res.rejected
          );
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentErrorMsg",
            "messages.contentError"
          );
        }
      );
    },
    getPageContents($state) {
      if (!this.otherPages || this.otherPages.length == 0) {
        $state.complete();
        return;
      }
      this.page += 1;

      const pageBody = this.otherPages[this.page - 2]; // index starts from 0, but 0 is the 2nd page
      if (!pageBody) {
        $state.complete();
        return;
      }

      Contents.retrievePageContents(pageBody).then(
        ({ data: contents }) => {
          if (contents.length) {
            this.contents.push(...contents);
            $state.loaded();
          } else $state.complete();
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentErrorMsg",
            "messages.contentError"
          );
        }
      );
    },
    getMarkerDetails(id) {
      return new Promise((resolve) => {
        Contents.getContent(id).then(
          ({ data: content }) => {
            const marker = new Marker(
              content.id,
              [
                content.location.coordinates[1],
                content.location.coordinates[0],
              ],
              content.title,
              `${MULTIMEDIA_API}/data/${content.id}/main`,
              content.address,
              content.category
            );
            resolve(marker);
          },
          (err) => {
            console.log(err);
            resolve({ rejected: true });
          }
        );
      });
    },
    contentDetail(id) {
      this.$router.push(`/contents/${id}`);
    },
  },
};
</script>
