<template>
  <div class="forgot-password">
    <b-container class="pt-4">
      <b-card>
        <div class="text-right"><LocalesCombo class="locales-combo" /></div>

        <div class="text-center mb-4">
          <img src="../../assets/scialari.svg" width="200px" class="mb-4" />

          <h5>{{ $t("pages.login.forgotPassword") }}</h5>
          <span class="text-muted">
            {{ $t("pages.recovery.insertEmail") }}
          </span>
        </div>

        <b-row align-h="center" class="mb-2">
          <b-col cols="6">
            <validation-observer ref="emailObserver" v-slot="{ handleSubmit }">
              <b-form @submit.prevent="handleSubmit(forgotPassword)">
                <validation-provider
                  name="email"
                  :rules="{ required: true, email: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="email-fieldset"
                    label="Email"
                    label-for="email"
                    :label-cols="3"
                  >
                    <b-form-input
                      id="email"
                      v-model.trim="email"
                      placeholder="Account email"
                      :state="getValidationState(validationContext)"
                      type="email"
                    ></b-form-input>

                    <b-form-invalid-feedback id="email-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <div class="text-center mt-4">
                  <RaisedButton
                    type="submit"
                    variant="success"
                    :disabled="!email"
                  >
                    <font-awesome-icon icon="shield-alt"></font-awesome-icon>
                    <span class="ml-2">
                      {{ $t("pages.recovery.proceed") }}</span
                    >
                  </RaisedButton>
                </div>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { Recovery } from "../../api";
import RaisedButton from "../../components/RaisedButton.vue";
import LocalesCombo from "../../components/LocalesCombo.vue";
import { handleError } from "../../utils";

export default {
  components: {
    RaisedButton,
    LocalesCombo,
  },
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      lang: this.$i18n.locale,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    forgotPassword() {
      let account = { username: this.email, lang: this.lang };

      this.$loading.show();

      Recovery.forgotPassword(account).then(
        () => {
          this.$loading.hide();
          this.$root.$bvToast.toast(this.$t("messages.passwordResetMsg"), {
            title: this.$t("messages.passwordReset"),
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "Login" });
        },
        (err) => {
          this.$loading.hide();
          handleError(
            this,
            err,
            "messages.resetErrorMsg",
            "messages.resetError"
          );
        }
      );
    },
  },
};
</script>
