import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { BootstrapVue, SpinnerPlugin, IconsPlugin, AlertPlugin, NavbarPlugin, ButtonPlugin, BreadcrumbPlugin, ToastPlugin, AvatarPlugin, TooltipPlugin, FormPlugin, ModalPlugin } from 'bootstrap-vue'

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required, numeric, regex, min, min_value } from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.directive('field-updated', {
  bind: function (el, binding) {
    const field = Object.keys(binding.modifiers)[0];
    const updatedFields = binding.value;
    if (!updatedFields) return;
    if (updatedFields.includes(field)) {
      if (el.tagName == 'TR') el.classList.add('edited-row');
      else el.classList.add('edited-field');
    }
  }
})

extend('required', required)
extend('min', min)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)
extend('regex', regex)
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Passwords do not match.'
})

import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading, { /* options */ });

import Loader from './plugins/Loader';
Vue.use(Loader);

import FullScreenModal from './plugins/FullScreenModal';
Vue.use(FullScreenModal);

import ContentLocalizer from './plugins/ContentLocalizer';
Vue.use(ContentLocalizer);

import wysiwyg from "vue-wysiwyg";

Vue.use(wysiwyg, {
  hideModules: {
    "image": true,
    "table": true,
    "code": true,
    // "removeFormat": true,
    "justifyLeft": true,
    "justifyCenter": true,
    "justifyRight": true,
  },
}); // config is optional. more below

import VCalendar from 'v-calendar'
Vue.use(VCalendar, {
  componentPrefix: 'vue',
});

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Used BootstrapVue plugins
Vue.use(AlertPlugin)
Vue.use(NavbarPlugin)
Vue.use(BreadcrumbPlugin)
Vue.use(SpinnerPlugin)
Vue.use(ButtonPlugin)
Vue.use(AvatarPlugin)
Vue.use(FormPlugin)
Vue.use(ModalPlugin)
Vue.use(TooltipPlugin)
Vue.use(ToastPlugin)

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faCircle, faSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)
library.add(faCircle, faSquare)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import { format, add } from 'date-fns';

Vue.filter('distance', function (value) {
  const result = value > 1000
    ? value / 1000
    : value;

  const unit = value > 1000
    ? 'km'
    : 'm';

  return `${result.toFixed(2)} ${unit}`;
});

Vue.filter('getDateTime', function (date, amountToAdd) {
  if (!date) return;

  if (amountToAdd) date = add(new Date(date), amountToAdd);

  return format(new Date(date), 'HH:mm');
});

Vue.filter('formatDate', function (date, simple) {
  if (!date) return;

  let adjustedDate = date.substring(0,10)

  return simple ? format(new Date(adjustedDate), 'dd/MM/yyy') : format(new Date(date), 'dd/MM/yyy HH:mm');
});

Vue.filter('contentDate', function (contentDate) {
  if (!contentDate) return;

  let date;
  date = format(new Date(contentDate.start), 'dd/MM/yyy HH:mm');

  if (contentDate.expiration)
    date += " - " + format(new Date(contentDate.end), 'dd/MM/yyy HH:mm');

  return date;
});

Vue.filter('contentAddress', function (address) {
  let location = [];
  if (address) {
    if (address.street.length) location.push(address.street);
    if (address.city.length) location.push(address.city);
    if (address.country.length) location.push(address.country);
  }
  return location.length ? location.join(", ") : null;
});

Vue.filter('contentIcon', function (category) {
  let icon = '';

  switch (category) {
    case 'service':
      icon = 'tools';
      break;

    case 'monument':
      icon = 'building';
      break;

    case 'event':
      icon = 'calendar-date';
      break;
  }

  return icon;
});

import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk: process.env.STRIPE_PUBLISHABLE_KEY || 'pk_test_51JUAvhBtogW9kTjpowhrIOOwHn7scDFLqf5cYiDuBUpv3ikgZC4gl4X4USBRMMfaSwYJaq7SZxfoGtJsRBEIlsNg00BO343e3v',
  // stripeAccount: process.env.STRIPE_ACCOUNT,
  // apiVersion: process.env.API_VERSION,
  // locale: process.env.LOCALE,
};

Vue.use(StripePlugin, options);

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect);

import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-wysiwyg/dist/vueWysiwyg.css';
import './overrides.scss';
import './styles.scss';

// _> Leaflet 
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster)
// <_
Vue.config.productionTip = false

// vue-i18n app initialization
import i18n from './i18n';

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
