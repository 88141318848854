<template>
  <div class="custom-breadcrumb">
    <b-breadcrumb>
      <slot></slot>
    </b-breadcrumb>
  </div>
</template>

<style lang="scss" scoped>
.custom-breadcrumb .breadcrumb {
  background: -webkit-linear-gradient(
    to right,
    rgba(white, 0.85),
    rgba(white, 0.25)
  );
  background: linear-gradient(to right, rgba(white, 0.85), rgba(white, 0.25));

  .breadcrumb-item a:hover {
    text-decoration: none;
  }
}
</style>

<script>
export default {
  name: "CustomBreadcrumb",
};
</script>