<template>
  <div class="booking-esit">
    <b-container class="p-3">
      <b-card class="my-5 shadow-lg">
        <b-card-text class="text-center">
          <LocalesCombo class="locales-combo" />

          <div class="booking-message">
            <template v-if="success">
              <p class="font-xl">{{ $t("pages.bookingSuccess.title") }}</p>
              <p class="font-lg text-success">
                {{ $t("pages.bookingSuccess.subtitle") }}
              </p>
            </template>

            <template v-else>
              <p class="font-xl">{{ $t("pages.bookingError.title") }}</p>
              <p class="font-lg text-danger">
                {{ $t("pages.bookingError.subtitle") }}
              </p>

              <br />

              <p class="font-lg text-muted">
                {{ $t("pages.bookingError.trailing") }}
              </p>
            </template>
          </div>

          <br />
          <br />

          <img class="platform-logo" src="../assets/scialari.svg" />

          <div class="text-center mt-4">
            <RaisedButton
              type="submit"
              variant="success"
              @click.native="$router.push({path: '/admin/pages/requests'})"
            >
              <span class="ml-2">{{ $t("common.continue") }} </span>
              <font-awesome-icon icon="chevron-right"></font-awesome-icon>
            </RaisedButton>
          </div>
        </b-card-text>
      </b-card>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.booking-esit {
  min-height: 100vh;
  height: 100%;
  background-image: url("../assets/home-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .locales-combo {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .face-icon {
    width: 200px;
    height: 200px;
    // animation: colorPath 0.5s ease-in-out 1s;

    @include media-breakpoint-between(xs, sm) {
      width: 120px;
      height: 120px;
    }

    @include media-breakpoint-between(sm, md) {
      width: 150px;
      height: 150px;
    }
  }

  .booking-message {
    font-weight: 300;

    .font-lg {
      font-size: 2rem;
      @include media-breakpoint-down(xs) {
        font-size: 1.25rem;
      }

      @include media-breakpoint-between(sm, md) {
        font-size: 1.5rem;
      }

      @include media-breakpoint-between(md, lg) {
        font-size: 1.75rem;
      }
    }

    .font-xl {
      font-size: 3.5rem;
      @include media-breakpoint-down(xs) {
        font-size: 1.5rem;
        font-weight: 400;
      }

      @include media-breakpoint-between(sm, md) {
        font-size: 2rem;
      }

      @include media-breakpoint-between(md, lg) {
        font-size: 2.75rem;
      }
    }
  }

  .platform-logo {
    width: 200px;

    @include media-breakpoint-down(xs) {
      width: 150px;
    }

    @include media-breakpoint-between(sm, lg) {
      width: 180px;
    }
  }
}
</style>

<script>
import LocalesCombo from "../components/LocalesCombo.vue";
import RaisedButton from "../components/RaisedButton.vue";

export default {
  name: "BookingConfirmation",
  components: {
    LocalesCombo,
    RaisedButton,
  },
  data: function () {
    return {
      success: this.$route.meta.bookingSuccess,
    };
  },
};
</script>