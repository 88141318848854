<template>
  <div class="root">
    <Navbar :user="user" />
    <div class="root-body">
      <div class="page-wrapper">
        <router-view />
      </div>
      <Footer />
    </div>

    <OverlayLoader />
  </div>
</template>

<style lang="scss">
.root {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .root-body {
    flex: 1 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .page-wrapper {
      flex: 1 0;
    }
  }
}
</style>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  data: () => ({
    user: null,
  }),
  created() {
    this.user = this.$cookies.get("atlas_user");
  },
};
</script>