<template>
  <div class="availability-rules-editor">
    <b-col>
      <b-form-group
        id="days-range-from-fieldset-pos"
        :label="$t('common.from')"
        label-for="days-range-from-input-pos"
        label-cols="3"
      >
        <b-form-input
          id="days-range-from-input-pos"
          v-model="range.start"
          :min="today"
          type="date"
          :placeholder="$t('components.availabilityForm.noDate')"
          autocomplete="off"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="days-range-to-fieldset-pos"
        :label="$t('common.to')"
        label-for="days-range-to-input-pos"
        label-cols="3"
      >
        <b-form-input
          id="days-range-to-input-pos"
          v-model="range.end"
          type="date"
          :min="today"
          :placeholder="$t('components.availabilityForm.noDate')"
          autocomplete="off"
        ></b-form-input>
        <b-form-invalid-feedback class="feedback" v-if="invalidRange">
          {{ $t("components.availabilityForm.rangeError") }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <b-col class="pt-3">
      <FlatButton
        @click.native="addRange()"
        :disabled="
          range.start == undefined || range.end == undefined || invalidRange
        "
        class="border bg-info text-white float-right"
      >
        <span
          >{{
            editRangeDates.length == 0 ? $t("common.add") : $t("common.update")
          }}
          Range</span
        >
      </FlatButton>
    </b-col>
  </div>
</template>

<style lang="scss">
@import "@/variables.scss";
.range-list {
  max-height: 300px;
  overflow-x: clip;
  overflow-y: auto;

  .range-items {
    padding-bottom: 0.35rem;
    margin-left: 15px;

    &.editing {
      .range-item {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        padding: 0.35rem 0.75rem;
        background-color: rgba($geo-info, 0.125);
      }
    }
    .range-item {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 0.35rem 0.75rem;
    }
    svg {
      margin: 0 1rem 0 1rem;
    }
  }
}
.divider {
  border-left: 6px solid $default-gray;
  height: 170px;
  position: absolute;
  left: 50%;
  top: 0;
}
.availability-rules-wrap {
  border: 1px solid $default-gray;
}
.mw-80 {
  max-width: 80%;
}

.feedback {
  position: absolute;
  display: block !important;
}
</style>

<script>
import FlatButton from "./FlatButton.vue";

import moment from "moment";

export default {
  name: "AvailabilityRangeForm",
  components: {
    FlatButton,
  },
  props: {
    editRangeDates: Array,
  },
  data: () => ({
    range: { start: null, end: null },
    today: moment(new Date()).format("YYYY-MM-DD"),
  }),
  computed: {
    invalidRange() {
      let dayFrom = moment(this.range.start, "YYYY-MM-DD");
      let dayTo = moment(this.range.end, "YYYY-MM-DD");

      return dayFrom.isAfter(dayTo);
    },
  },
  created: function () {
    if (this.editRangeDates.length != 0) {
      this.range.start = this.editRangeDates[0];
      this.range.end = this.editRangeDates[1];
    }
  },
  methods: {
    /*      
        @add-negative="addNegativeRange"
        @add-positive="addPositiveRange"
        @edit-positive="editPositiveRange"
        @edit-negative="editNegativeRange"
*/

    addRange() {
      if (this.editRangeDates.length > 0) {
        this.$emit("edit-range", this.range.start, this.range.end);
      } else {
        this.$emit("add-range", this.range.start, this.range.end);
      }
    },
    resetRange() {
      this.range.start = undefined;
      this.range.end = undefined;
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
};
</script>