<template>
  <footer>
    <b-navbar class="footer-navbar">
      <img src="../assets/scialari.svg" height="30" />
    </b-navbar>
  </footer>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";

.footer-navbar {
  box-shadow: 0 -0.125rem 0.5rem rgba(0, 0, 0, 0.2);
  margin-top: 3rem;
  background-color: #999;
  color: white;

  .footer-title {
    color: #ffffff;
  }

  .footer-col {
    padding: 0 1rem;
    text-align: center;

    .footer-link {
      color: #ffffffaa;
      cursor: pointer;
      margin: 0.5rem 0;

      &:hover {
        text-decoration: none;
        color: #ffffffdd;
      }
    }
  }
}
</style>

<script>
export default {
  name: "Footer",
};
</script>