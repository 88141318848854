<template>
  <div class="provider-initialization">
    <b-container class="pt-4">
      <b-card>
        <div class="text-center mb-3">
          <img src="../assets/scialari.svg" width="200px" class="mb-4" />

          <h5>One last step</h5>
          <span class="text-muted"
            >You just need to choose a new password to start using the
            platform.</span
          >
        </div>

        <validation-observer ref="passwordObserver" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(savePassword)">
            <validation-provider
              name="newPassword"
              :rules="{ required: true, password: '@confirmPassword' }"
              v-slot="validationContext"
            >
              <b-form-group
                id="new-password-fieldset"
                label="New Password"
                label-for="new-password"
                :label-cols="4"
              >
                <b-form-input
                  id="new-password"
                  v-model.trim="newPassword"
                  placeholder="Account password"
                  :state="getValidationState(validationContext)"
                  type="password"
                ></b-form-input>

                <b-form-invalid-feedback id="new-password-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="confirmPassword"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group
                id="confirm-password-fieldset"
                label="Confirm Password"
                label-for="confirm-password"
                :label-cols="4"
              >
                <b-form-input
                  id="confirm-password"
                  v-model.trim="confirmPassword"
                  placeholder="Confirm previous password"
                  :state="getValidationState(validationContext)"
                  type="password"
                ></b-form-input>

                <b-form-invalid-feedback id="confirm-password-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div class="text-center mt-4">
              <RaisedButton
                type="submit"
                variant="success"
                :disabled="newPassword != confirmPassword"
              >
                <font-awesome-icon icon="shield-alt"></font-awesome-icon>
                <span class="ml-2">Confirm</span>
              </RaisedButton>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import { Users } from "../api";
import RaisedButton from "../components/RaisedButton.vue";
export default {
  components: { RaisedButton },
  name: "ProviderInitialization",
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    savePassword() {
      const token = this.$route.query.ref;

      this.$loading.show();

      Users.setPassword(token, this.newPassword).then(
        () => {
          this.$loading.hide();
          this.$root.$bvToast.toast(this.$t("messages.providerActivatedMsg"),
            {
              title: this.$t("messages.accountSuccess"),
              variant: "success",
              solid: true,
            }
          );
          this.$router.push({ name: "Login" });
        },
        () => {
          this.$loading.hide();
          this.$bvToast.toast(this.$t("messages.providerErrorMsg"), {
            title: this.$t("messages.accountError"),
            variant: "danger",
            solid: true,
          });
        }
      );
    },
  },
};
</script>