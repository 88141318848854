<template>
  <div class="field-item">
    <div class="field-icon">
      <font-awesome-icon :icon="field.icon || 'info-circle'" fixed-width></font-awesome-icon>
    </div>
    <b-col class="px-2">
      <div class="d-flex">
        <div>
          <b-row no-gutters align-v="center" class="mb-1">
            <h6 class="mb-0">{{ getFieldTranslation(field, "title") }}</h6>
            <font-awesome-icon
              class="ml-2"
              v-if="field.hidden"
              icon="eye-slash"
            ></font-awesome-icon>
          </b-row>
          <!-- <div class="small-tag d-table text-uppercase">{{ field.type }}</div> -->
          <div class="text-muted">
            <span class="semi-bold">{{$t('admin.platformManagement.extraFields.list.defaultValue')}}:</span>
            <span class="ml-2">{{
              getFieldTranslation(field, "stringValue") ||
              field.boolValue ||
              $t("common.none")
            }}</span>
          </div>
        </div>

        <div class="ml-auto d-flex align-items-center">
          <IconButton @click.native="$emit('edit')" v-if="!field.hidden">
            <font-awesome-icon icon="pen"></font-awesome-icon>
            <!-- <span class="ml-2">Edit</span> -->
          </IconButton>
          <IconButton v-if="!field.hidden" class="ml-3" @click.native="$emit('delete')">
            <font-awesome-icon
              class="text-danger"
              icon="trash"
            ></font-awesome-icon>
            <!-- <span class="ml-2">Delete</span> -->
          </IconButton>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import IconButton from "./IconButton.vue";

export default {
  name: "ExtraFieldItem",
  props: {
    field: Object,
  },
  components: {
    IconButton,
  },
};
</script>