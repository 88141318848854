<template>
  <div class="home">
    <div class="full-viewport">
      <div class="home-background"></div>
      <b-col md="10" xl="9" class="welcome-message">
        <h1 class="welcome-title">{{ $t("pages.home.mainText") }}</h1>
        <p class="welcome-text">{{ $t("pages.home.secondaryText") }}</p>
      </b-col>
    </div>

    <br />

    <b-container>
      <div class="home-text shadow" v-html="$t('pages.home.scialari')"></div>

      <h3 class="display-4 page-title text-center mb-3">
        {{ $t("pages.home.experiences") }}
      </h3>
      <p class="experiences-text mb-0">
        {{ $t("pages.home.experiencesIntro") }}
      </p>
      <p class="experiences-text">{{ $t("pages.home.experiencesMain") }}</p>

      <b-row>
        <b-col xl="6" class="mb-3" v-if="dbCategories == null">
          <Loader :size="4" />
        </b-col>
        <b-col
          v-else
          xl="6"
          class="mb-3"
          v-for="(catId, index) of categories"
          :key="index"
          @click="
            $router.push({
              name: 'ContentsCatalog',
              query: { categories: catId },
            })
          "
        >
          <div class="category-card">
            <div class="category-background">
              <img :src="imageBaseUrl + catId" />
            </div>

            <div class="category-text">
              <h3>{{ getFieldTranslation(findCategory(catId), "name") }}</h3>
              <span class="description">{{
                getFieldTranslation(findCategory(catId), "description")
              }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/variables.scss";

.home {
  .full-viewport {
    position: relative;
    min-height: 95vh;
    height: 100%;

    .home-background {
      filter: brightness(0.85);
      position: absolute;
      background-image: url("../assets/home-background.jpg");
      background-position: center;
      background-size: cover;
      height: 100%;
      width: 100%;
    }

    .welcome-message {
      position: absolute;
      right: 0;
      bottom: 3.5rem;
      text-align: right;
      color: white;
      text-shadow: 3px 3px 10px black;
      padding: 1rem 2rem;

      .welcome-title {
        font-size: calc(2.5rem + 1.5vw);
        font-weight: 300;
      }

      .welcome-text {
        font-size: calc(1rem + 1.2vw);
        margin: 2rem 0;
      }
    }
  }

  .home-text {
    font-size: 1.5rem;
    font-weight: 300;
    font-family: "Quicksand";
    margin-bottom: 2rem;
    background-color: rgba($primary, 0.125);
    color: $geo-info;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;

    b {
      color: $primary;
      font-weight: 600;
    }
  }

  .experiences-text {
    padding: 1rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 300;
  }

  .category-card {
    cursor: pointer;
    position: relative;
    border-radius: 0.25rem;
    overflow: hidden;
    height: 300px;
    box-shadow: 0 0 1rem 0.5rem #00000022;

    &.small {
      height: 100px;
      border-radius: 50px;
    }

    .category-background {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        filter: brightness(0.8);
      }
    }

    .category-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-shadow: 0 0 5px #000000;
      text-align: center;

      .description {
        font-size: 1.125rem;
      }
    }
  }
}
</style>

<script>
import { HOME_CATEGORIES, HOME_MULTIMEDIA } from "../globals";
import { Categories } from "../api";
import { handleError } from "../utils";
import Loader from "../components/Loader.vue";

export default {
  name: "Home",
    components: {
    Loader,
  },
  data: () => ({
    categories: HOME_CATEGORIES,
    imageBaseUrl: HOME_MULTIMEDIA,
    dbCategories: null
  }),
  created: function () {
    this.initCategories();
  },
  methods: {
    initCategories() {
      Categories.getCategories().then(
        (res) => {
          this.dbCategories = res.data;
        },
        (err) => {
          console.log(err);
          this.dbCategories = [];
          handleError(this,err,"messages.categoriesErrorMsg","messages.categoriesError");
        }
      );
    },
    findCategory(id) {
      return this.dbCategories.find((cat) => cat.id == id);
    },
  },
};
</script>