<template>
  <b-nav-item
    :to="{ name: user ? 'Cart' : 'GuestCart' }"
    active-class="active-link"
    class="cart-link"
  >
    <font-awesome-icon icon="shopping-cart"></font-awesome-icon>

    <div class="atlas-tag cart-pill" v-if="cartItems != undefined">
      {{ cartItems }}
    </div>
  </b-nav-item>
</template>

<script>
import { EventBus } from "../eventBus";
export default {
  name: "CartLink",
  props: {
    user: Object,
  },
  data: () => ({
    cartItems: undefined,
  }),
  created() {
    EventBus.$on("cart-item-added", this.updateCart);
    EventBus.$on("cart-item-removed", this.updateCart);

    this.updateCart();
  },
  methods: {
    updateCart() {
      const cartCookie = this.$cookies.get("atlas_cart");
      if (!cartCookie) this.cartItems = undefined;
      else this.cartItems = JSON.parse(cartCookie).length != 0 ? JSON.parse(cartCookie).length : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/variables.scss";

.cart-link {
  position: relative;

  > .nav-link.active-link {
    color: $geo-warning;
  }

  > .nav-link.active-link:focus,
  > .nav-link.active-link:active,
  > .nav-link.active-link:hover {
    color: darken($geo-warning, 5%);
  }

  .cart-pill {
    font-size: 0.65rem;
    background-color: $geo-danger;
    color: white;
    font-weight: 600;
    position: absolute;
    top: -3px;
    right: -6px;
    border-radius: 5rem;
    padding: 0.15rem 0.4rem;
    box-shadow: 0 0.125rem 0.25rem rgba(black, 0.5);
  }
}
</style>