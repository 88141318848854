import { Translation } from "./Translation";
export class ExternalLink {

    constructor(
        title,
        description,
        url,
    ) {
        this.id = null;
        this.title = title || new Translation();
        this.description = description || new Translation();
        this.url = url || "";
    }
}