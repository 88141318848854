<template>
  <transition name="full-screen">
    <div class="full-screen-modal" v-if="showModal">
      <slot></slot>
    </div>
  </transition>
</template>

<style lang="scss">
.full-screen-modal {
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 1000;
  background-color: white;
}

.full-screen-enter-active,
.full-screen-leave-active {
  transition: all 0.2s;
}
.full-screen-enter,
.full-screen-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>

<script>
import { EventBus } from "../eventBus";

export default {
  name: "FullScreenModal",
  props: {
    modalName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    showModal: false,
  }),
  created() {
    EventBus.$on("hide-fs-modal", (name) => {
      if (name == this.modalName) {
        this.showModal = false;
        this.$emit("modal-hidden");
      }
    });

    EventBus.$on("show-fs-modal", (name) => {
      if (name == this.modalName) {
        this.showModal = true;
        this.$emit("modal-shown");
      }
    });
  },
  beforeDestroy() {
    // fixing *body not scrollable* bug when changing route from full screen modal
    this.$fsModal.hide();
  }
};
</script>