<template>
  <b-row class="external-links mb-3">
    <b-col
      lg="3"
      class="mb-2"
      :id="'externalLink-tooltip-' + index"
      v-for="(link, index) of externalLinks"
      :key="index"
    >
      <div class="link-item">
        <div class="link-icon">
          <font-awesome-icon icon="link"></font-awesome-icon>
        </div>

        <b-col lg="9" class="ml-2">
          <h6 class="mb-0 text-truncate" >{{ getFieldTranslation(link, "title") }}</h6>
          <a class="d-lg-block text-truncate" :href="link.url" target="_blank ">{{ link.url }}</a>
        </b-col>
      </div>
      <b-tooltip :target="'externalLink-tooltip-' + index" triggers="hover">
        {{ getFieldTranslation(link, "description") }}
      </b-tooltip>
    </b-col>
  </b-row>
</template>

<style lang="scss">
@import "@/variables.scss";
.external-links {
  .link-item {
    flex-wrap: nowrap;
    align-items: center;
    padding: 0.5rem;
    display: flex;
    border: 1px solid $default-gray;
    border-radius: 50px;
    overflow: hidden;

    .link-icon {
      padding: 0 0.5rem;
      font-size: 1.5rem;
    }
  }
}
</style>

<script>
export default {
  name: "ExternalLinksList",
  components: {},
  props: {
    externalLinks: Array,
  },
  data() {
    return {};
  },
  created: function () {},
  computed: {},
  methods: {},
};
</script>