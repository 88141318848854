<template>
  <div class="user-detail">
    <Loader :size="6" v-if="!user" />

    <template v-else>
      <CustomAlert
        type="warning"
        class="d-flex m-1 align-items-center"
        v-if="user.status == 'pending'"
      >
        <b-col class="p-0 text-left">
          <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
          <span class="ml-2">{{ $t("admin.users.detail.pending") }}</span>
        </b-col>

        <RaisedButton class="mr-3" variant="danger">
          <font-awesome-icon icon="times"></font-awesome-icon>
          <span class="ml-2">{{ $t("admin.users.detail.refuse") }}</span>
        </RaisedButton>

        <RaisedButton variant="success">
          <font-awesome-icon icon="check"></font-awesome-icon>
          <span class="ml-2">{{ $t("admin.users.detail.accept") }}</span>
        </RaisedButton>
      </CustomAlert>

      <b-alert type="danger" v-if="error">
        <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
        <span class="ml-2">{{ $t("admin.users.detail.error") }}</span>
      </b-alert>

      <template v-else>
        <b-row class="mx-0 p-3 border-bottom">
          <div :class="['user-status', user.status]">
            <font-awesome-icon
              :icon="user.status == 'enabled' ? 'user-check' : 'user-times'"
              class="mx-3"
            ></font-awesome-icon>
            <div class="atlas-tag mt-1">{{ user.status }}</div>
          </div>

          <b-col>
            <h5 class="mb-1">{{ user.username }}</h5>
            <div class="atlas-tag d-inline bg-light border">
              {{ user.role }}
            </div>
          </b-col>
        </b-row>

        <br />

        <div class="px-4">
          <table class="table">
            <tbody>
              <tr>
                <th scope="col">{{ $t("admin.users.detail.firstname") }}</th>
                <td>{{ user.firstName }}</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("admin.users.detail.lastname") }}</th>
                <td>{{ user.lastName }}</td>
              </tr>
              <template v-if="user.contacts">
                <tr v-if="user.contacts.name">
                  <th scope="col">
                    {{ $t("admin.users.detail.contactName") }}
                  </th>
                  <td>{{ user.contacts.name }}</td>
                </tr>
                <tr v-if="user.contacts.email">
                  <th scope="col">
                    {{ $t("admin.users.detail.contactEmail") }}
                  </th>
                  <td>{{ user.contacts.email }}</td>
                </tr>
                <tr v-if="user.contacts.telephone">
                  <th scope="col">
                    {{ $t("admin.users.detail.contactTelephone") }}
                  </th>
                  <td>{{ user.contacts.telephone }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <div class="p-3" v-if="user.role == 'provider'">
          <b-row
            no-gutters
            align-v="center"
            class="text-muted justify-content-between mb-3"
          >
            <h6 class="mb-0">{{ $t("admin.users.detail.contents") }}</h6>
            <span v-html="$t('common.elementsFound', { n: total })"></span>
          </b-row>

          <Loader class="m-3" :size="5" v-if="!contents" />

          <b-row v-else>
            <b-col :cols="4" v-for="content of contents" :key="content.id">
              <ContentItem :mapMode="true" :content="content" class="mb-2" />
            </b-col>
          </b-row>
        </div>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";

.user-detail {
  background-color: white;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;

  .user-status {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.enabled {
      color: $geo-success;
    }
    &.disabled {
      color: $geo-warning;
    }
  }

  th,
  td {
    padding: 0.25rem;
    border: none;
  }
  td {
    text-align: right;
  }
  tr {
    border-bottom: 1px solid $default-gray;
  }
}
</style>

<script>
import { Contents, Users } from "../../../api";
import { handleError } from "../../../utils";
import Loader from "../../../components/Loader.vue";
import CustomAlert from "../../../components/CustomAlert.vue";
import RaisedButton from "../../../components/RaisedButton.vue";
import ContentItem from "../../../components/ContentItem.vue";

export default {
  name: "UserDetail",
  components: {
    Loader,
    ContentItem,
    RaisedButton,
    CustomAlert,
  },
  data() {
    return {
      user: null,
      contents: null,
      total: undefined,
      otherPages: null,
      error: false,
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      const id = this.$route.params.id;

      Users.getUser(id).then(
        ({ data: user }) => {
          this.user = user;
          if (this.user.role == "provider") this.getProviderContents();
        },
        (err) => {
          this.error = true;
          this.user = {};
          handleError(
            this,
            err,
            "messages.userInfoErrorMsg",
            "messages.userError"
          );
        }
      );
    },
    getProviderContents() {
      const params = new URLSearchParams();
      params.set("owner", this.user.id);

      Contents.getContents(params).then(
        ({ data: contentsPages }) => {
          this.contents = contentsPages.firstPage;
          this.otherPages = contentsPages.nextPages;
          this.total = contentsPages.total;
        },
        (err) => {
          this.contents = [];
          handleError(
            this,
            err,
            "messages.userContentErr",
            "messages.contentError"
          );
        }
      );
    },
  },
};
</script>