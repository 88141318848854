<template>
  <div class="contents-list">
    <b-row align-v="center" class="mb-3" no-gutters>
      <b-col cols="6">
        <CustomSearch
          :placeholder="$t('common.search')"
          :showButton="true"
          v-model="searchText"
          @search="searchContents"
          :disabled="!contents"
        />
      </b-col>

      <b-col cols="2" class="pl-2">
        <b-form-select
          size="sm"
          :value="categoryFilter"
          :disabled="!contents"
          @input="filterContentsPerCategory"
        >
          <b-form-select-option value="All">{{
            $t("common.all")
          }}</b-form-select-option>
          <b-form-select-option
            v-for="(category, index) of categories"
            :key="index"
            :value="category.id"
            >{{ getFieldTranslation(category, "name") }}</b-form-select-option
          >
        </b-form-select>
      </b-col>

      <b-col cols="2" class="pl-2">
        <b-form-select
          size="sm"
          :value="statusFilter"
          :disabled="!contents"
          @input="filterContentsPerStatus"
        >
          <b-form-select-option value="All">{{
            $t("common.all")
          }}</b-form-select-option>
          <b-form-select-option
            v-for="(status, index) of stati"
            :key="index"
            :value="status.value"
            >{{ $t(status.label) }}</b-form-select-option
          >
        </b-form-select>
      </b-col>

      <RaisedButton
        variant="success"
        class="ml-auto"
        @click.native="$router.push({ name: 'ContentCreate' })"
        :disabled="!contents"
        v-if="userRole == 'provider'"
      >
        <font-awesome-icon icon="plus" fixed-width></font-awesome-icon>
        <span class="ml-2">{{ $t("common.newContent") }}</span>
      </RaisedButton>
    </b-row>

    <Loader v-if="!contents" :size="5" />

    <template v-else>
      <CustomAlert v-if="!contents.length">
        <b-icon icon="info-circle-fill"></b-icon>
        <span class="ml-2">{{ $t("common.noContents") }}</span>
      </CustomAlert>

      <template v-else>
        <b-row align-h="between" align-v="end" class="mb-1" no-gutters>
          <b-col cols="3">
            <span v-html="$t('common.elementsFound', { n: total })"></span>
          </b-col>

          <b-pagination
            class="mb-0"
            :value="page"
            @input="onPageChange"
            align="center"
            :total-rows="total"
            :per-page="limit"
          >
          </b-pagination>

          <b-col cols="3">
            <b-form-group
              class="mb-0"
              label-cols="7"
              :label="$t('common.elementsPerPage')"
              label-for="page-limit"
            >
              <b-form-select
                id="page-limit"
                :value="limit"
                @input="updateContentsPerPage"
              >
                <b-form-select-option value="3">3</b-form-select-option>
                <b-form-select-option value="15">15</b-form-select-option>
                <b-form-select-option value="30">30</b-form-select-option>
                <b-form-select-option value="50">50</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <table class="contents-table">
          <thead>
            <th width="70px"></th>
            <th width="50%">{{ $t("common.name") }}</th>
            <th>{{ $t("common.status") }}</th>
            <th>{{ $tc("common.category") }}</th>
            <th>{{ $tc("common.action", 2) }}</th>
          </thead>

          <tbody>
            <tr
              v-for="(content, index) of sortedContents"
              :key="index"
              :class="content.disabled ? 'bg-light ' : ''"
            >
              <td class="text-center p-1">
                <Avatar
                  class="mx-auto"
                  :src="contentImageUrl + content.id + '/main'"
                />
              </td>

              <td>
                <router-link
                  :class="content.disabled ? 'text-muted ' : ''"
                  :to="{ name: 'ContentPreview', params: { id: content.id } }"
                  >{{ getFieldTranslation(content, "title") }}</router-link
                >
              </td>

              <td class="text-center">
                <div
                  :class="[
                    'atlas-tag pill text-white p-1 px-2',
                    content.disabled
                      ? 'bg-secondary'
                      : getContentClass(content.status),
                  ]"
                >
                  <font-awesome-icon
                    :icon="getContentIcon(content.status)"
                  ></font-awesome-icon>
                  <span class="ml-2">{{
                    getContentStatusLabel(content.status)
                  }}</span>
                </div>
              </td>

              <td class="text-center">
                <span class="atlas-tag border bg-light">
                  <font-awesome-icon
                    :icon="getContentCategory(content.category).icon"
                  ></font-awesome-icon>
                  <span class="ml-2">{{
                    getFieldTranslation(
                      getContentCategory(content.category),
                      "name"
                    )
                  }}</span>
                </span>
              </td>
              <td class="text-center">
                <template
                  v-if="userRole == 'provider' && content.status != 'deleted'"
                >
                  <IconButton
                    class="mr-2"
                    @click.native="availabilityModal(content)"
                  >
                    <font-awesome-icon
                      icon="clipboard-list"
                      fixed-width
                    ></font-awesome-icon>
                  </IconButton>
                  <IconButton
                    class="mr-2"
                    @click.native="
                      $router.push({
                        name: 'ContentEdit',
                        params: { id: content.id },
                      })
                    "
                  >
                    <font-awesome-icon
                      icon="pen"
                      fixed-width
                    ></font-awesome-icon>
                  </IconButton>
                  <IconButton
                    class="text-danger"
                    @click.native="deleteModal(content)"
                  >
                    <font-awesome-icon
                      icon="trash"
                      fixed-width
                    ></font-awesome-icon>
                  </IconButton>
                </template>
                <template
                  v-if="
                    userRole == 'admin' &&
                    (content.status == 'pending' ||
                      content.status == 'modified')
                  "
                >
                  <IconButton
                    @click.native="
                      $router.push({
                        name: 'ContentDraft',
                        params: { id: content.id },
                      })
                    "
                  >
                    <font-awesome-icon
                      icon="search"
                      fixed-width
                    ></font-awesome-icon>
                  </IconButton>
                  <IconButton
                    class="text-success ml-2"
                    @click.native="acceptContent(content.id)"
                  >
                    <font-awesome-icon
                      icon="check"
                      fixed-width
                    ></font-awesome-icon>
                  </IconButton>
                  <IconButton
                    class="ml-2 text-danger"
                    @click.native="setContentToReject(content)"
                  >
                    <font-awesome-icon
                      icon="times"
                      fixed-width
                    ></font-awesome-icon>
                  </IconButton>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </template>

    <b-modal
      id="reject-content-modal"
      hide-footer
      :title="$t('admin.contents.detail.rejectContent')"
    >
      <p>{{ $t("admin.contents.detail.rejectMessage") }}</p>

      <b-container class="mb-3" v-if="selectedContent">
        <div class="border rounded bg-light p-2">
          <b-row no-gutters align-v="center">
            <Avatar :src="contentImageUrl + selectedContent.id + '/main'" />
            <div class="px-3">
              <h6 class="mb-0">
                {{ getFieldTranslation(selectedContent, "title") }}
              </h6>
            </div>
          </b-row>
        </div>
      </b-container>

      <b-form-group
        id="content-reject-reason-fieldset"
        label="Message"
        label-for="content-reject-reason-field"
      >
        <b-form-textarea
          id="content-reject-reason-field"
          rows="4"
          :placeholder="$t('admin.contents.detail.rejectPlaceholder')"
          v-model="rejectedMessage"
        ></b-form-textarea>
      </b-form-group>

      <b-row no-gutters align-v="center" align-h="between">
        <FlatButton @click.native="$bvModal.hide('reject-content-modal')">{{
          $t("actions.cancel")
        }}</FlatButton>

        <RaisedButton variant="danger" @click.native="rejectContent">
          <font-awesome-icon icon="paper-plane"></font-awesome-icon>
          <span class="ml-2">{{ $t("actions.confirm") }}</span>
        </RaisedButton>
      </b-row>
    </b-modal>

    <DeleteModal
      @cancel="$bvModal.hide('delete-modal')"
      @confirm="deleteContent"
    >
      <p v-if="selectedContent">
        {{ $t("messages.deleteContent") }}
        <b>{{ getFieldTranslation(selectedContent, "title") }}</b
        >?
      </p>
    </DeleteModal>

    <b-modal
      id="booking-recap-modal"
      size="lg"
      centered
      hide-header
      hide-footer
      title="Verifier"
      @hidden="resetSelectedContent"
    >
      <BookingOverview v-if="selectedContent" :contentId="selectedContent.id" />
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "../variables.scss";

.contents-list {
  .contents-table {
    width: 100%;
    background-color: white;
    box-shadow: 0 0 1rem 0.5rem rgba(black, 0.1);

    a {
      color: $geo-info;
    }

    thead {
      background-color: #f2f2f2;
      text-transform: uppercase;
      color: $geo-secondary;
      border-bottom: 1px solid #ddd;
    }
    th {
      text-align: center;
      padding: 0.5rem 0.75rem;
    }
    td {
      padding: 0.25rem 0.5rem;
    }

    tr:not(:last-of-type) {
      border-bottom: 1px solid #ddd;
    }
  }
}
</style>

<script>
import Loader from "../components/Loader.vue";
import DeleteModal from "../components/DeleteModal.vue";
import RaisedButton from "../components/RaisedButton.vue";
import IconButton from "../components/IconButton.vue";
import CustomSearch from "../components/CustomSearch.vue";
import CustomAlert from "../components/CustomAlert.vue";
import Avatar from "../components/Avatar.vue";
import FlatButton from "../components/FlatButton.vue";
import BookingOverview from "../components/BookingOverview.vue";
import { MULTIMEDIA_API } from "../globals";
import { Account, Contents, Categories } from "../api";
import { handleError, contentPositionByStatus } from "../utils";

export default {
  name: "ContentsList",
  components: {
    DeleteModal,
    Loader,
    FlatButton,
    Avatar,
    CustomAlert,
    CustomSearch,
    IconButton,
    RaisedButton,
    BookingOverview,
  },
  data: () => ({
    contents: null,
    otherPages: null,
    firstPage: null,
    searchText: "",
    categoryFilter: "All",
    selectedContent: null,
    contentImageUrl: null,
    limit: 30,
    total: 0,
    page: 1,
    categories: null,
    userRole: null,
    statusFilter: "All",
    stati: [
      { label: "common.rejected", value: "rejected" },
      { label: "common.pending", value: "pending" },
      { label: "common.approved", value: "approved" },
      { label: "common.deleted", value: "deleted" },
      { label: "common.modified", value: "modified" },
      { label: "common.notApproved", value: "not_approved" },
    ],
    rejectedMessage: "",
  }),
  created() {
    this.userRole = this.$cookies.get("atlas_role");
    this.getCategories();
    this.contentImageUrl = `${MULTIMEDIA_API}/data/`;
  },
  computed: {
    sortedContents() {
      return this.contents
        ? [...this.contents].sort((a, b) => {
            return contentPositionByStatus(a.status) >
              contentPositionByStatus(b.status)
              ? 1
              : -1;
          })
        : null;
    },
  },
  methods: {
    getCategories() {
      Categories.getCategories().then(
        ({ data: categories }) => {
          this.categories = categories;
          this.getContents();
        },
        (err) => {
          const error = err.response.data;
          handleError(this, err, error.message, error.error);
        }
      );
    },
    getContentCategory(id) {
      const category = this.categories.find((cat) => cat.id == id);
      return {
        name: category.name,
        icon: category.icon,
      };
    },
    getContentClass(status) {
      switch (status) {
        case "pending":
        case "modified":
          return "bg-warning";

        case "not-approved":
        case "rejected":
        case "deleted":
          return "bg-danger";

        case "approved":
          return "bg-success";

        default:
          return "bg-secondary";
      }
    },
    getContentIcon(status) {
      switch (status) {
        case "pending":
        case "modified":
          return "clock";

        case "not-approved":
        case "rejected":
        case "deleted":
          return "times";

        case "approved":
          return "check";

        default:
          return "question";
      }
    },
    getContentStatusLabel(status) {
      switch (status) {
        case "pending":
          return this.$t("common.pending");
        case "modified":
          return this.$t("common.modified");
        case "not-approved":
          return this.$t("common.notApproved");
        case "rejected":
          return this.$t("common.rejected");
        case "deleted":
          return this.$t("common.deleted");
        case "approved":
          return this.$t("common.approved");
        default:
          return this.$t("common.unknown");
      }
    },
    getContents() {
      const params = new URLSearchParams();

      params.set("pageSize", this.limit);

      if (this.searchText && this.searchText != "") {
        params.set("q", this.searchText);
      }
      if (this.categoryFilter != "All") {
        params.set("categories", this.categoryFilter);
      }
      if (this.statusFilter != "All") {
        params.set("status", this.statusFilter);
      }

      Account.getAccountContents(params).then(
        ({ data: contentsPages }) => {
          this.total = contentsPages.total;
          this.firstPage = contentsPages.firstPage;
          this.otherPages = contentsPages.nextPages;
          this.contents = this.firstPage;
        },
        (err) => {
          console.log(err);
          this.contents = [];
          handleError(
            this,
            err,
            "messages.contentErrorMsg",
            "messages.contentError"
          );
        }
      );
    },
    onPageChange(page) {
      this.contents = null;
      this.page = page;
      if (page == 1) this.contents = this.firstPage;
      else this.getPageContents();
    },
    getPageContents() {
      const pageBody = this.otherPages[this.page - 2]; // index starts from 0, but 0 is the 2nd page
      Account.getAccountContentsPage(pageBody).then(
        ({ data: contents }) => {
          this.contents = contents;
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentErrorMsg",
            "messages.contentError"
          );
        }
      );
    },
    searchContents() {
      this.page = 1;
      this.getContents();
    },
    updateContentsPerPage(limit) {
      this.limit = limit;
      this.contents = null;
      this.getContents();
    },
    filterContentsPerCategory(category) {
      this.contents = null;
      this.categoryFilter = category;
      this.getContents();
    },
    filterContentsPerStatus(status) {
      this.contents = null;
      this.statusFilter = status;
      this.getCategories();
    },
    availabilityModal(content) {
      this.selectedContent = content;
      this.$bvModal.show("booking-recap-modal");
    },
    resetSelectedContent() {
      this.selectedContent = null;
    },
    deleteModal(content) {
      this.selectedContent = content;
      this.$bvModal.show("delete-modal");
    },
    setContentToReject(content) {
      this.selectedContent = content;
      this.$bvModal.show("reject-content-modal");
    },
    acceptContent(contentId) {
      this.$loading.show();

      Contents.contentApproval(contentId, true).then(
        () => {
          this.$loading.hide();
          this.$bvToast.toast(this.$t("messages.contentAvailableMsg"), {
            title: this.$t("messages.contentAvailable"),
            variant: "success",
            solid: true,
          });
          this.contents = null;
          this.getCategories();
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentUnaccepted",
            "messages.contentError"
          );
          this.$loading.hide();
        }
      );
    },
    rejectContent() {
      this.$loading.show();

      Contents.contentApproval(
        this.selectedContent.id,
        false,
        this.rejectedMessage
      ).then(
        () => {
          this.$loading.hide();
          this.$bvToast.toast(this.$t("messages.contentDenyMsg"), {
            title: this.$t("messages.contentDeny"),
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("reject-content-modal");
          this.contents = null;
          this.getCategories();
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentRejectMsg",
            "messages.contentError"
          );
          this.$loading.hide();
        }
      );
    },
    deleteContent() {
      Contents.deleteContent(this.selectedContent.id).then(
        () => {
          this.$bvModal.hide("delete-modal");
          this.$bvToast.toast(this.$t("messages.contentDeleted"), {
            title: this.$t("messages.success"),
            variant: "success",
            solid: true,
          });
          this.getContents();
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentDeleteErrorMsg",
            "messages.contentDeleteError"
          );
        }
      );
    },
  },
};
</script>
