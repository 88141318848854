<template>
  <div class="requests-list">
    <Loader :size="6" v-if="!requests || !contents" />

    <template v-else>
      <b-row align-v="center" class="mb-3" no-gutters>
        <b-col class="pr-2" cols="4">
          <b-row align-v="center" no-gutters>
            <IconButton
              v-if="selectedContent"
              @click.native="clearSelectedContent"
            >
              <font-awesome-icon
                class="text-danger"
                icon="times"
              ></font-awesome-icon>
            </IconButton>
          </b-row>

          <b-dropdown id="content-dropdown" variant="light">
            <template #button-content>
              <div v-if="selectedContent" class="d-flex align-items-center">
                <Avatar
                  size="sm"
                  :src="imageUrl + selectedContent.id + '/main'"
                />
                <div class="pl-3">
                  <h6 class="mb-0">
                    {{ getFieldTranslation(selectedContent, "title") }}
                  </h6>
                </div>
              </div>

              <span v-else>{{ $t("admin.requests.list.all") }}</span>
            </template>

            <b-dropdown-item
              v-for="(content, index) of contents"
              :key="index"
              @click="updateRequestContent(content)"
            >
              <Avatar :src="imageUrl + content.id + '/main'" />
              <div class="pl-3">
                <h6 class="mb-0">
                  {{ getFieldTranslation(content, "title") }}
                </h6>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

        <b-col cols="3">
          <multiselect
            class="select-size"
            id="status-multi-select"
            :value="statusFilter"
            @input="updateRequestStatus"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('admin.requests.list.placeholders.status')"
            label="name"
            track-by="name"
          >
            <template slot="option" slot-scope="{ option }">
              <strong>{{ $t(option.name) }}</strong>
            </template>
            <template slot="tag" slot-scope="{ option }">
              <span class="badge badge-info p-1 mr-1">{{
                $t(option.name)
              }}</span>
            </template>
          </multiselect>
        </b-col>

        <b-col>
          <div class="d-flex align-items-center">
            <template v-if="!customRangeSelector">
              <b-col>
                <multiselect
                  class="select-size"
                  id="range-select"
                  :value="selectedCustomRange"
                  @input="updatedSelectedRange"
                  :allow-empty="true"
                  :options="rangeOptions"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :searchable="false"
                  :placeholder="$t('filters.rangeFilter')"
                  label="name"
                  track-by="name"
                >
                  <template slot="option" slot-scope="{ option }">
                    <strong>{{ $t(option.name) }}</strong>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span>{{ $t(option.name) }}</span>
                  </template>
                </multiselect>
              </b-col>
            </template>
            <template v-else>
              <b-col>
                <vue-date-picker
                  :value="dateRange.start"
                  @input="updateDateRange('start', $event)"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <b-form-input
                      id="start-filter"
                      :value="inputValue"
                      v-on="inputEvents"
                      :placeholder="
                        $t('admin.requests.list.placeholders.start')
                      "
                      autocomplete="off"
                    ></b-form-input>
                  </template>
                </vue-date-picker>
              </b-col>

              <font-awesome-icon
                icon="arrow-right"
                class="mx-3"
              ></font-awesome-icon>

              <b-col class="px-0">
                <vue-date-picker
                  :value="dateRange.end"
                  @input="updateDateRange('end', $event)"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <b-form-input
                      id="start-filter"
                      :value="inputValue"
                      v-on="inputEvents"
                      :placeholder="$t('admin.requests.list.placeholders.end')"
                      autocomplete="off"
                    ></b-form-input>
                  </template>
                </vue-date-picker>
              </b-col>
            </template>
            <IconButton
              class="ml-1"
              v-if="dateRange.start || dateRange.end || customRangeSelector"
              @click.native="clearDateRange"
            >
              <font-awesome-icon
                class="text-danger"
                icon="times"
              ></font-awesome-icon>
            </IconButton>
          </div>
        </b-col>
      </b-row>

      <b-row class="mb-2" no-gutters>
        <b-col class="d-flex justify-content-end">
          <RaisedButton
            variant="success"
            size="md"
            @click.native="openBookingPaymentSelection"
            v-if="waitingPaymentRequests.length > 0 && userRole === 'user'"
          >
            <span class="pr-3 pl-3">
              <font-awesome-icon icon="cash-register" />
              <span class="ml-2">{{ $t("actions.completeAllPay") }}</span>
            </span>
          </RaisedButton>
        </b-col>
      </b-row>

      <CustomAlert v-if="!requests.length">
        <font-awesome-icon icon="info-circle"></font-awesome-icon>
        <span class="ml-2">{{ $t("admin.requests.list.noResults") }}</span>
      </CustomAlert>

      <div class="requests-wrapper" v-else>
        <b-row
          v-for="(request, index) of sortedRequests"
          :key="index"
          class="no-gutters hover"
        >
          <b-col md="11">
            <RequestListItem
              class="action"
              :request="request"
              @click.native="
                $router.push({
                  name: 'RequestDetail',
                  params: { id: request.id },
                })
              "
            >
            </RequestListItem>
          </b-col>
          <b-col
            md="1"
            class="d-flex align-items-center justify-content-center"
          >
            <div v-if="request.status == 'pending' && userRole != 'user'">
              <IconButton
                class="p-2 text-success"
                @click.native.stop="
                  changeRequestStatus(request.id, 'confirmation')
                "
              >
                <font-awesome-icon size="lg" icon="check"></font-awesome-icon>
              </IconButton>
              <IconButton
                class="ml-3 p-2 text-danger"
                @click.native.stop="
                  changeRequestStatus(request.id, 'rejection')
                "
              >
                <font-awesome-icon size="lg" icon="times"></font-awesome-icon>
              </IconButton>
            </div>
            <div v-else-if="userRole === 'user'">
              <IconButton
                :class="
                  request.status === 'wait_payment'
                    ? 'p-2 text-success'
                    : 'p-2 invisible'
                "
                @click.native="openBookingPaymentSelection($event, request.id)"
              >
                <font-awesome-icon
                  size="lg"
                  icon="cash-register"
                ></font-awesome-icon>
              </IconButton>
              <IconButton
                v-if="
                  request.status === 'wait_payment' ||
                  request.status === 'pending'
                "
                class="ml-3 p-2 text-danger"
                @click.native="cancelBookingModal($event, request)"
              >
                <font-awesome-icon size="lg" icon="times"></font-awesome-icon>
              </IconButton>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>

    <DeleteModal
      @cancel="$bvModal.hide('delete-modal')"
      @confirm="deleteBookingRequest"
    >
      <p v-if="selectedBook">
        {{
          $t("admin.requests.cancelBook", {
            title: getFieldTranslation(selectedBook, "contentTitle"),
          })
        }}
      </p>
    </DeleteModal>

    <b-modal
      hide-footer
      hide-header
      size="lg"
      id="pending-payment-modal"
      centered
      @hidden="resetWaitingPayments"
    >
      <h5>{{ $t("components.checkoutWizard.selectBooks") }}</h5>
      <div class="pl-3 pr-3">
        <b-row
          class="align-items-center border rounded p-2"
          v-for="(item, index) of waitingPaymentRequests"
          :key="index"
        >
          <b-col md="4" class="d-flex flex-column">
            <span class="text-left"
              >{{ getFieldTranslation(item, "contentTitle") }}
            </span>
          </b-col>

          <b-col md="4" class="d-flex flex-column">
            <span
              class="text-center"
              v-if="item.start.substring(0, 10) === item.end.substring(0, 10)"
            >
              {{ item.start | formatDate(true) }}
            </span>
            <span v-else class="text-center">
              {{ item.start | formatDate(true) }} ->
              {{ item.end | formatDate(true) }}
            </span>
          </b-col>
          <b-col md="2" class="d-flex flex-column">
            <span class="text-right"
              >{{ $t("common.adults") }}: {{ item.adultAmount }}
            </span>
            <span class="text-right"
              >{{ $t("common.children") }}: {{ item.kidAmount }}
            </span>
          </b-col>
          <b-col md="1" class="d-flex flex-column">
            <strong class="text-right"> {{ item.sum }}€</strong>
          </b-col>
          <b-col md="1">
            <CustomCheckbox :id="item.id" v-model="item.selected" />
          </b-col>
        </b-row>
        <div>
          <vue-recaptcha
            ref="recaptcha"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
            :sitekey="siteKey"
            :loadRecaptchaScript="true"
          ></vue-recaptcha>
        </div>
        <RaisedButton
          variant="success"
          size="md"
          class="float-right mt-2"
          @click.native="completePayment"
          :disabled="disablePay"
        >
          <font-awesome-icon icon="cash-register" />
          <span class="ml-2">Paga</span>
        </RaisedButton>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";
.requests-wrapper {
  background-color: white;
  border-radius: 0.25rem;
  border: 1px solid #ddd;
}
.hover {
  &:hover {
    box-shadow: 0 0 0 3px rgba($geo-info, 0.5);
  }

  &:active {
    box-shadow: 0 0 2px 3px $geo-info;
  }
}
/deep/ .dropdown {
  display: flex;

  .dropdown-menu {
    max-height: 70vh;
    overflow-y: auto;
  }

  &.show > .btn-light.dropdown-toggle {
    background-color: #fff;
    box-shadow: inset 0 0 0.25rem 0.125rem rgba(black, 0.125);
    border-color: #bbb;
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;

    &:hover {
      background-color: #f0f0f0;
      border-color: #aaa;
    }

    &::after {
      margin-left: auto;
    }
  }

  a.dropdown-item {
    display: flex;
    align-items: center;
    padding: 0.3rem 0.75rem;
  }
}

//overriding multiselect style to match with other dropdowns
.multiselect__placeholder {
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}
</style>

<script>
import { isBefore, format, addMonths, endOfMonth } from "date-fns";
import { Booking, Contents, BookingCheckoutApi } from "../../../api";
import RaisedButton from "../../../components/RaisedButton.vue";
import CustomAlert from "../../../components/CustomAlert.vue";
import IconButton from "../../../components/IconButton.vue";
import Loader from "../../../components/Loader.vue";
import RequestListItem from "../../../components/RequestListItem.vue";
import Avatar from "../../../components/Avatar.vue";
import { MULTIMEDIA_API, RECAPTCHA_SITE_KEY } from "../../../globals";
import jwt_decode from "jwt-decode";
import CustomCheckbox from "../../../components/CustomCheckbox.vue";
import { BookingCheckout } from "../../../models/BookingCheckout";
import VueRecaptcha from "vue-recaptcha";
import { handleError, requestPositionByStatus } from "../../../utils";
import DeleteModal from "../../../components/DeleteModal.vue";

export default {
  name: "RequestsList",
  components: {
    IconButton,
    CustomAlert,
    Avatar,
    RequestListItem,
    Loader,
    RaisedButton,
    VueRecaptcha,
    CustomCheckbox,
    DeleteModal,
  },
  data: () => ({
    requests: null,
    statusFilter: [],
    dateRange: { start: null, end: null },
    selectedContent: null,
    contents: [],
    imageUrl: `${MULTIMEDIA_API}/data/`,
    userRole: "user",
    waitingPaymentRequests: [],
    pendingRequests: [],
    captchaVerified: false,
    captcha: "",
    siteKey: RECAPTCHA_SITE_KEY,
    selectedBook: null,
    userId: "",
    customRangeSelector: false,
    selectedCustomRange: null,
  }),
  created() {
    this.getRole();
    this.initStatusFilter();
    this.initDateRangeFilter();
    this.initContents();
  },
  computed: {
    contentOptions() {
      return this.contents.map((content) => ({
        text: this.getFieldTranslation(content, "title"),
        value: content.id,
      }));
    },
    options() {
      return [
        { name: "common.paid", value: "paid" },
        { name: "common.pending", value: "pending" },
        { name: "common.rejected", value: "rejected" },
        { name: "common.waitingPayment", value: "wait_payment" },
        { name: "common.expired", value: "expired" },
      ];
    },
    sortedRequests() {
      return this.requests
        ? [...this.requests].sort((a, b) => {
            return requestPositionByStatus(a.status) >
              requestPositionByStatus(b.status)
              ? 1
              : -1;
          })
        : null;
    },
    selectedAtLeastOneBookToPay() {
      return this.waitingPaymentRequests.some((x) => x.selected === true);
    },
    disablePay() {
      return this.captchaVerified && this.selectedAtLeastOneBookToPay
        ? false
        : true;
    },
    rangeOptions() {
      let today = new Date();
      let todayFormat = format(today, "yyyy-MM-dd");

      return [
        {
          name: "filters.nextMonth",
          value: {
            start: todayFormat,
            end: format(endOfMonth(addMonths(today, 1)), "yyyy-MM-dd"),
          },
        },
        {
          name: "filters.nextTwoMonth",
          value: {
            start: todayFormat,
            end: format(endOfMonth(addMonths(today, 2)), "yyyy-MM-dd"),
          },
        },
        {
          name: "filters.nextThreeMonth",
          value: {
            start: todayFormat,
            end: format(endOfMonth(addMonths(today, 3)), "yyyy-MM-dd"),
          },
        },
        {
          name: "filters.nextSixMonth",
          value: {
            start: todayFormat,
            end: format(endOfMonth(addMonths(today, 6)), "yyyy-MM-dd"),
          },
        },
        {
          name: "filters.nextYear",
          value: {
            start: todayFormat,
            end: format(endOfMonth(addMonths(today, 12)), "yyyy-MM-dd"),
          },
        },
        { name: "filters.custom", value: "custom" },
      ];
    },
  },
  methods: {
    getRole() {
      let tkn = this.$cookies.get("atlas_token");

      const decoded = jwt_decode(tkn);
      this.userId = decoded.id;
      this.userRole = decoded.role;
    },
    initContents() {
      let params = {};
      if (this.userRole == "provider") {
        params = { owner: this.userId };
      }
      Contents.getShortContents(params).then(
        ({ data: contents }) => {
          this.contents = contents;
          this.getRequests();
        },
        (err) => {
          console.log(err);
          this.contents = [];
          this.requests = [];
          handleError(
            this,
            err,
            "messages.requestContentErr",
            "messages.requestError"
          );
        }
      );
    },
    initStatusFilter() {
      this.statusFilter = this.options.filter((x) => x.value !== "expired");
    },
    initDateRangeFilter() {
      this.selectedCustomRange = this.rangeOptions[2];
      this.dateRange = {
        start: this.rangeOptions[2].value.start,
        end: this.rangeOptions[2].value.end,
      };
    },
    getRequests() {
      const params = new URLSearchParams();

      if (this.statusFilter.length) {
        const requestStati = this.statusFilter.map((f) => f.value);
        const expired = requestStati.includes("expired");
        if (expired) requestStati.push("payment_expired");
        params.set("status", requestStati);
      }

      if (this.dateRange.start) {
        const start = format(new Date(this.dateRange.start), "yyyy-MM-dd");
        params.set("start", start);
      }

      if (this.dateRange.end) {
        const end = format(new Date(this.dateRange.end), "yyyy-MM-dd");
        params.set("end", end);
      }

      if (this.selectedContent) params.set("content", this.selectedContent.id);

      Booking.getAccountRequests(params).then(
        (res) => {
          const requests = res.data.sort((a, b) => {
            const firstDate = new Date(a.dayRequest);
            const secondDate = new Date(b.dayRequest);

            return isBefore(firstDate, secondDate) ? 1 : -1;
          });

          requests.forEach((request) => {
            const content = this.contents.find(
              (c) => c.id == request.contentId
            );
            if (content) request.contentTitle = content.title;
            else {
              request.contentTitle = {
                it_IT: "Contenuto Scaduto",
                en_EN: "Content expired",
              };
              request.drop = true;
            }
          });

          this.requests = requests.filter((r) => !r.drop);
          this.prepareWaitingAndPendingRequest();
        },
        (err) => {
          console.log(err);
          this.requests = [];
          handleError(
            this,
            err,
            "messages.requestsErrorMsg",
            "messages.requestError"
          );
        }
      );
    },
    prepareWaitingAndPendingRequest() {
      let requestsWithSelect = this.requests.map((req) => ({
        selected: false,
        ...req,
      }));
      this.waitingPaymentRequests = requestsWithSelect.filter(
        (req) => req.status === "wait_payment"
      );
      this.pendingRequests = requestsWithSelect.filter(
        (req) => req.status === "pending"
      );
    },
    reset() {
      this.requests = null;
      this.getRequests();
    },
    updateDateRange(field, value) {
      this.dateRange[field] = value;
      this.reset();
    },
    clearDateRange() {
      this.selectedCustomRange = null;
      this.customRangeSelector = false;
      this.dateRange = { start: null, end: null };
      this.reset();
    },
    clearSelectedContent() {
      this.selectedContent = null;
      this.reset();
    },
    updateRequestStatus(value) {
      this.statusFilter = value;
      this.reset();
    },
    updatedSelectedRange(input) {
      if (!input) {
        this.dateRange.start = null;
        this.dateRange.end = null;
        this.selectedCustomRange = null;
        this.reset();
        return;
      }

      if (input.value === "custom") {
        this.customRangeSelector = true;
        this.selectedCustomRange = input;

        return;
      }
      this.selectedCustomRange = input;
      this.dateRange.start = input.value.start;
      this.dateRange.end = input.value.end;
      this.reset();
    },
    updateRequestContent(content) {
      this.selectedContent = content;
      this.reset();
    },
    changeRequestStatus(request, action) {
      this.$loading.show();

      Booking.updateRequestState(request, action).then(
        () => {
          this.$loading.hide();
          this.reset();
          this.$bvToast.toast(this.$t("messages.requestState"), {
            title: this.$t("messages.success"),
            variant: "success",
            solid: true,
          });
        },
        (err) => {
          console.log(err);
          this.$loading.hide();
          handleError(
            this,
            err,
            "messages.requestStateAction",
            "messages.requestError"
          );
        }
      );
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.captchaVerified = false;
    },
    onCaptchaVerified(recaptchaToken) {
      this.captcha = recaptchaToken;
      this.captchaVerified = true;
    },
    completePayment() {
      let bookingCheckoutIds = new BookingCheckout();

      let selectedBookings = this.waitingPaymentRequests.filter(
        (x) => x.selected === true
      );
      bookingCheckoutIds.userId = selectedBookings[0].userId;
      bookingCheckoutIds.name = selectedBookings[0].name;
      bookingCheckoutIds.email = selectedBookings[0].email;
      bookingCheckoutIds.phone = selectedBookings[0].phoneNumber;

      bookingCheckoutIds.bookings = selectedBookings.map((x) => x.id);
      bookingCheckoutIds.captcha = this.captcha;

      BookingCheckoutApi.sendBookingCheckoutIds(bookingCheckoutIds).then(
        (res) => {
          const checkoutUrl = res.data.checkoutUrl;
          window.location.href = checkoutUrl;
        },
        (err) => {
          handleError(
            this,
            err,
            "messages.requestCheckout",
            "messages.checkoutError"
          );
        }
      );
    },
    cancelBookingModal(evt, book) {
      this.selectedBook = book;
      this.$bvModal.show("delete-modal");
    },
    deleteBookingRequest() {
      this.$bvModal.hide("delete-modal");
      this.$loading.show();

      Booking.deleteBooking(this.selectedBook.id).then(
        () => {
          this.$loading.hide();
          this.reset();
          this.$bvToast.toast(this.$t("messages.bookingCancelled"), {
            title: this.$t("messages.success"),
            variant: "success",
            solid: true,
          });
        },
        (err) => {
          console.log(err);
          this.$loading.hide();
          handleError(
            this,
            err,
            "messages.bookingCancelledErr",
            "messages.requestError"
          );
        }
      );
    },
    openBookingPaymentSelection(evt, id) {
      if (id) {
        let i = this.waitingPaymentRequests.findIndex((x) => x.id == id);
        this.waitingPaymentRequests[i].selected = true;
      } else {
        this.waitingPaymentRequests.forEach((x) => (x.selected = true));
      }
      this.$bvModal.show("pending-payment-modal");
    },
    resetWaitingPayments() {
      this.waitingPaymentRequests.forEach((x) => (x.selected = false));
    },
  },
};
</script>