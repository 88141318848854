<template>
  <div class="discount-wizard">
    <form-wizard
      :title="$t('components.discounts.discountCreation')"
      subtitle=""
      ref="discountWizard"
      :start-index.sync="activeTabIndex"
      stepSize="xs"
      shape="circle"
      color="#20a0ff"
      error-color="#ff4949"
    >
      <tab-content
        :title="$t('components.discounts.tab1')"
        icon="ti-settings"
        :before-change="() => validateUserInfo()"
      >
        <template ref="discountInfo">
          <div class="discount-info m-3">
            <validation-observer ref="userInfoValidator">
              <b-form class="discount-info-form">
                <b-row>
                  <b-col cols="5"
                    ><label class="col-form-label">{{
                      $t("components.discounts.userLabel")
                    }}</label></b-col
                  >
                  <b-col class="pl-1">
                    <Multiselect
                      :value="user"
                      :options="usersSelectable"
                      @select="loadActiveDiscount"
                      :allow-empty="false"
                      :placeholder="$t('admin.discounts.user')"
                      label="name"
                      track-by="id"
                    ></Multiselect>
                    <span
                      class="text-danger"
                      v-show="isInvalid && user === null"
                      >{{ $t("components.discounts.errorUser") }}</span
                    >
                  </b-col>
                </b-row>
                <br />
                <validation-provider
                  :name="'Percentuale sconto'"
                  :rules="{
                    required: true,
                    regex: /\b([1-9]|[1-9][0-9]|100)\b/,
                  }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="discount-amount-fieldset"
                    :label="$t('components.discounts.discountApply')"
                    label-cols="5"
                    label-for="discount-amount-field"
                  >
                    <b-form-input
                      id="discount-amount-field"
                      :placeholder="$t('components.discounts.discountRate')"
                      type="number"
                      min="0"
                      v-model.number="newDiscount.amount"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="discount-amount-field-live-feedback"
                    >
                      {{ $t("components.discounts.percentageError") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form>
            </validation-observer>
          </div>
        </template>
      </tab-content>

      <tab-content
        :title="$t('components.discounts.contents')"
        icon="ti-fullscreen"
      >
        <b-row class="pr-3 pl-3">
          <Multiselect
            :value="provider"
            :options="providerOptions"
            @select="refreshContents"
            :placeholder="$t('admin.discounts.provider')"
            label="name"
            track-by="id"
          ></Multiselect>
        </b-row>
        <hr />
        <h5>{{ $t("components.discounts.selectContents") }}</h5>
        <div v-if="contents.length == 0">
          <CustomAlert type="warning" class="mb-2">
            <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
            <span class="ml-2">{{
              $t("components.discounts.noContents")
            }}</span>
          </CustomAlert>
        </div>
        <div v-else>
          <b-row class="justify-content-end pr-3 pb-2">
            <RaisedButton variant="info" @click.native="selectAll()">
              <span>{{ $t("components.discounts.selectAll") }}</span>
            </RaisedButton>
          </b-row>

          <div class="pb-3 pl-3 pr-3 overflow-auto content-selection-wrapper">
            <b-row
              class="align-items-center border rounded p-2"
              v-for="(item, index) of contents"
              :key="index"
            >
              <b-col class="p-0">
                <Avatar
                  class="mx-auto"
                  :src="contentImageUrl + item.id + '/main'"
                />
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <span class="text-left"
                  >{{ getFieldTranslation(item, "title") }}
                </span>
              </b-col>

              <b-col md="2" class="text-right">
                <small
                  ><s class="pr-2"> {{ item.showcasePrice }}€</s></small
                >
                <font-awesome-icon
                  icon="caret-right"
                  size="xs"
                ></font-awesome-icon>
                <strong>
                  {{
                    item.showcasePrice -
                    (item.showcasePrice / 100) * newDiscount.amount
                  }}€</strong
                >
              </b-col>

              <b-col md="2" class="text-right">
                <CustomCheckbox :id="item.id" v-model="item.selected" />
              </b-col>
            </b-row>
          </div>
        </div>
      </tab-content>

      <RaisedButton variant="secondary" slot="prev">
        <font-awesome-icon icon="angle-left" />
        <span class="ml-2">{{ $t("components.bookingWizard.back") }}</span>
      </RaisedButton>

      <RaisedButton variant="info" slot="next">
        <span class="mr-2">{{ $t("components.bookingWizard.next") }}</span>
        <font-awesome-icon icon="angle-right" />
      </RaisedButton>

      <RaisedButton
        @click.native="createDiscount"
        variant="success"
        slot="finish"
        :disabled="false"
      >
        <font-awesome-icon icon="chevron-right" />
        <span class="ml-2">{{
          $t("components.discounts.createDiscount")
        }}</span>
      </RaisedButton>
    </form-wizard>
  </div>
</template>

<style lang="scss" >
.wizard-icon-container {
  margin: -1px;
}
.content-selection-wrapper {
  max-height: 40vh;
}
</style>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import RaisedButton from "./RaisedButton.vue";
import Multiselect from "vue-multiselect";
import Avatar from "./Avatar.vue";
import CustomCheckbox from "./CustomCheckbox.vue";
import { NewDiscounts } from "../models/Discount";
import { Contents, Discounts } from "../api";
import { MULTIMEDIA_API } from "../globals";
import CustomAlert from "./CustomAlert.vue";
import { handleError } from "../utils";

export default {
  name: "CheckoutWizard",
  components: {
    FormWizard,
    Multiselect,
    TabContent,
    RaisedButton,
    CustomCheckbox,
    Avatar,
    CustomAlert,
  },
  props: {
    userOptions: Array,
    providerOptions: Array,
  },
  data() {
    return {
      newDiscount: null,
      contentImageUrl: null,
      isInvalid: false,
      activeTabIndex: 0,
      user: null,
      provider: null,
      canProceed: false,
      contents: [],
      userActiveDiscount: null,
    };
  },
  created: function () {
    this.contentImageUrl = `${MULTIMEDIA_API}/data/`;
    this.newDiscount = new NewDiscounts();
  },
  computed: {
    
    usersSelectable(){
      return [{id:"all",name:this.$t("common.allUser")},...this.userOptions]

    }
  },
  methods: {
    selectAll() {
      this.contents.forEach((x) => (x.selected = true));
    },
    getContents() {
      Contents.getShortContents({
        owner: this.provider ? this.provider.id : "",
      }).then(
        (res) => {
          if (res.data === null) {
            this.contents = [];
          } else {
            this.contents = res.data
              .filter((c) => c.showcasePrice != 0)
              .filter((c) => !this.userActiveDiscount.includes(c.id))
              .map((c) => ({ selected: false, ...c }));
          }
        },
        (err) => {
          console.log(err);
          this.contents = [];
          handleError(
            this,
            err,
            "messages.contentErrorMsg",
            "messages.contentError"
          );
        }
      );
    },
    refreshContents(evt) {
      this.provider = evt;
      this.getContents();
    },
    loadActiveDiscount(evt) {
      this.user = evt;
      Discounts.getDiscounts({
        userId: this.user ? this.user.id : "",
      }).then(
        ({ data: discounts }) => {
          this.userActiveDiscount = discounts.map((disc) => disc.contentId);
        },
        (err) => {
          console.log(err);
          this.userActiveDiscount = [];
          handleError(
            this,
            err,
            "messages.discountsErrorMsg",
            "messages.discountsError"
          );
        }
      );
    },

    createDiscount() {
      this.newDiscount.userId = this.user.id;
      this.newDiscount.contents = this.contents
        .filter((c) => c.selected === true)
        .map((c) => c.id);
      Discounts.createDiscounts(this.newDiscount).then(
        (res) => {
          console.log(res);
          this.$emit("closeWizard");
          this.$bvToast.toast(this.$t("messages.discountsSuccessMsg"), {
            title: this.$t("messages.discountsSuccess"),
            variant: "success",
            solid: true,
          });
        },
        (err) => {
          console.log(err);
          this.$emit("closeWizard");
          handleError(
            this,
            err,
            "messages.discountsErrorCreateMsg",
            "messages.discountsError"
          );
        }
      );
    },
    forceClearError() {
      this.$refs.wizard.tabs[this.activeTabIndex].validationError = null;
    },
    async validateUserInfo() {
      if (this.user === null) {
        this.isInvalid = true;
        return false;
      }
      if (await this.$refs.userInfoValidator.validate()) {
        this.getContents();
        return true;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
