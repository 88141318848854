<template>
  <div class="categories">
    <div class="mb-3">
      <h6 class="page-title mb-1">
        {{ $t("admin.platformManagement.categories.list.title") }}
      </h6>
      <span class="page-subtitle">{{
        $t("admin.platformManagement.categories.list.description")
      }}</span>
    </div>

    <b-row no-gutters align-v="center" class="mb-3">
      <b-col>
        <CustomSearch
          :placeholder="
            $t('admin.platformManagement.categories.list.placeholders.search')
          "
          v-model="searchText"
        />
      </b-col>

      <div class="pl-3">
        <RaisedButton
          @click.native="$bvModal.show('new-category')"
          variant="success"
        >
          <font-awesome-icon icon="plus"></font-awesome-icon>
          <span class="ml-2">{{
            $t("admin.platformManagement.categories.list.add")
          }}</span>
        </RaisedButton>
      </div>
    </b-row>

    <Loader v-if="!categories" :size="6" />

    <template v-else>
      <CustomAlert v-if="!categories.length">
        <font-awesome-icon icon="info-circle"></font-awesome-icon>
        <span class="ml-2">{{
          $t("admin.platformManagement.categories.list.noResults")
        }}</span>
      </CustomAlert>

      <div class="categories-list" v-else>
        <div
          class="category-item"
          v-for="(category, index) of filteredCategories"
          :key="index"
        >
          <div class="category-icon">
            <font-awesome-icon
              :icon="category.icon || 'folder'"
              :fixedWidth="true"
            ></font-awesome-icon>
          </div>

          <b-col class="overflow-hidden">
            <h6 class="mb-0">{{ getFieldTranslation(category, "name") }}</h6>
            <span class="text-muted text-truncate">{{
              getFieldTranslation(category, "description")
            }}</span>
          </b-col>

          <div class="px-3">
            <IconButton @click.native="editCategory(category)">
              <font-awesome-icon icon="pen"></font-awesome-icon>
              <!-- <span class="ml-2">Edit</span> -->
            </IconButton>
            <IconButton @click.native="deleteModal(category.id)" class="ml-3">
              <font-awesome-icon
                class="text-danger"
                icon="trash"
              ></font-awesome-icon>
              <!-- <span class="ml-2">Delete</span> -->
            </IconButton>
          </div>
        </div>
      </div>
    </template>

    <b-modal
      hide-footer
      :title="$t('admin.platformManagement.categories.edit.newTitle')"
      id="new-category"
      size="lg"
      @hidden="resetNewCategory"
    >
      <NewCategory
        :newCategory="newCategory"
        @cancel="$bvModal.hide('new-category')"
        @submit-category="saveCategory"
      />
    </b-modal>

    <DeleteModal
      @cancel="$bvModal.hide('delete-modal')"
      @confirm="deleteCategory"
      :block="!assignTo"
    >
      <div
        v-if="category"
        v-html="
          $t('admin.platformManagement.categories.delete.mainMessage', {
            title: getFieldTranslation(category, 'name'),
          })
        "
      ></div>

      <p class="my-2">
        <b-form-group
          label="Assegna a:"
          label-cols="3"
          label-for="other-category"
        >
          <b-form-select
            id="other-category"
            :options="remainingCategories"
            v-model="assignTo"
          >
            <b-form-select-option hidden disabled :value="null"
              >Seleziona una categoria</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
      </p>
    </DeleteModal>
  </div>
</template>

<style lang="scss">
.categories {
  .categories-list {
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    max-height: 500px;
    overflow-y: auto;

    .category-item {
      display: flex;
      align-items: center;
      padding: 0.5rem;

      &:not(:last-of-type) {
        border-bottom: 1px solid #ddd;
      }

      .category-icon {
        padding: 0 0.5rem;
        font-size: 1.5rem;
      }
    }
  }
}
</style>

<script>
import CustomAlert from "../../../components/CustomAlert.vue";
import Loader from "../../../components/Loader.vue";
import IconButton from "../../../components/IconButton.vue";
import DeleteModal from "../../../components/DeleteModal.vue";
import RaisedButton from "../../../components/RaisedButton.vue";
import CustomSearch from "../../../components/CustomSearch.vue";
import NewCategory from "./NewCategory.vue";
import { handleError } from "../../../utils";
import { Category } from "../../../models/Category.js";
import { Categories } from "../../../api";

import _ from "lodash";

export default {
  name: "Categories",
  components: {
    CustomAlert,
    NewCategory,
    CustomSearch,
    RaisedButton,
    DeleteModal,
    IconButton,
    Loader,
  },
  data: () => ({
    categories: null,
    newCategory: null,
    category: null,
    assignTo: null,
    searchText: "",
  }),
  created: function () {
    this.getCategories();
    this.resetNewCategory();
  },
  computed: {
    filteredCategories() {
      const search = this.searchText.trim().toLowerCase();
      return this.categories
        ? this.categories.filter((category) =>
            this.getFieldTranslation(category, "name")
              .toLowerCase()
              .includes(search)
          )
        : [];
    },
    remainingCategories() {
      return this.categories && this.category
        ? this.categories
            .filter((category) => category.id != this.category.id)
            .map((category) => ({
              text: this.getFieldTranslation(category, "name"),
              value: category.id,
            }))
        : [];
    },
  },
  methods: {
    resetNewCategory() {
      this.newCategory = new Category();
    },
    getCategories() {
      Categories.getCategories().then(
        (res) => {
          this.categories = res.data;
        },
        (err) => {
          const error = err.response.data;
          handleError(this, err, error.message, error.error);
          this.categories = [];
        }
      );
    },
    editCategory(category) {
      this.newCategory = _.cloneDeep(category);
      this.$bvModal.show("new-category");
    },
    saveCategory() {
      this.$loading.show();

      if (this.newCategory.id) {
        Categories.updateCategory(this.newCategory).then(
          () => {
            this.$bvToast.toast(this.$t("messages.categoryUpdated"), {
              title: this.$t("messages.categorySuccess"),
              variant: "success",
              solid: true,
            });
            this.$loading.hide();
            this.newCategory = new Category();
            this.categories = null;
            this.getCategories();
            this.$bvModal.hide("new-category");
          },
          (err) => {
            const error = err.response.data;
            handleError(this, err, error.message, error.error);
            this.$loading.hide();
          }
        );
      } else {
        Categories.createCategory(this.newCategory).then(
          (res) => {
            this.$bvToast.toast(this.$t("messages.categoryAdded"), {
              title: this.$t("messages.categorySuccess"),
              variant: "success",
              solid: true,
            });
            this.$loading.hide();
            this.categories.push(res.data);
            this.newCategory = new Category();
            this.$bvModal.hide("new-category");
          },
          (err) => {
            const error = err.response.data;
          handleError(
            this,
            err,
            error.message,
            error.error
          );
            this.$loading.hide();
          }
        );
      }
    },
    deleteModal(id) {
      if (this.categories.length == 1) {
        this.$bvToast.toast(this.$t("messages.categoryAlert"), {
          title: this.$t("messages.attention"),
          variant: "warning",
          solid: true,
        });
      } else {
        this.category = this.categories.find((category) => category.id == id);
        this.$bvModal.show("delete-modal");
      }
    },
    deleteCategory() {
      this.$loading.show();

      const params = new URLSearchParams();
      params.set("assignto", this.assignTo);

      Categories.deleteCategory(this.category.id, params).then(
        () => {
          this.$bvToast.toast(this.$t("messages.categoryDelete"), {
            title: this.$t("messages.categorySuccess"),
            variant: "success",
            solid: true,
          });
          this.categories = null;
          this.getCategories();
          this.$bvModal.hide("delete-modal");
          this.$loading.hide();
        },
        (err) => {
          const error = err.response.data;
          handleError(
            this,
            err,
            error.message,
            error.error
          );
          this.$loading.hide();
        }
      );
    },
  },
};
</script>