export const AUTH_API = `${window.__env.BASE_URL}/auth`;
export const ACCOUNT_API = `${window.__env.BASE_URL}/me`;
export const CONTENTS_API = `${window.__env.BASE_URL}/contents`;
export const CONTENTS_SERVICE_API = `${window.__env.CONTENTS_SERVICE_URL}/contents`;
export const CLUSTERS_API = `${CONTENTS_API}/clusters`;
export const MULTIMEDIA_API = `${window.__env.BASE_URL}/multimedia`;
export const EXTRA_FIELDS_API = `${window.__env.BASE_URL}/extra-fields`;
export const CATEGORIES_API = `${window.__env.BASE_URL}/content-categories`;
export const TAGS_API = `${window.__env.BASE_URL}/tags`;
export const USERS_API = `${window.__env.BASE_URL}/users`;
export const RECOVERY_API = `${window.__env.BASE_URL}/recovery`;
export const SETTINGS_API = `${window.__env.BASE_URL}/settings`;
export const BOOKNG_API = `${window.__env.BASE_URL}/bookings`;
export const BOOKNG_RULES_API = `${window.__env.BASE_URL}/rules`;
export const AVAILABILITY_API = window.__env.AVAILABILITY_SERVICE_URL;
export const PAYMENTS_API = `${window.__env.BASE_URL}/payments`;
export const DISCOUNTS_API = `${window.__env.BASE_URL}/discounts`;

export const SICILY_CENTER = [14.117355, 37.650413];

export const MAP_ACCESS_TOKEN = window.__env.MAP_ACCESS_TOKEN;
export const MAP_STYLE = window.__env.MAP_STYLE;

export const MAP_URL = window.__env.MAP_URL;
export const MAP_ATTRIBUTION = window.__env.MAP_ATTRIBUTION;

export const DEFAULT_CONTENTS_LIMIT = window.__env.DEFAULT_CONTENTS_LIMIT;

export const MULTIMEDIA_CONTENT_TYPE = window.__env.MULTIMEDIA_CONTENT_TYPE;

export const RECAPTCHA_SITE_KEY = window.__env.RECAPTCHA_SITE_KEY;

export const HOME_CATEGORIES = window.__env.HOME_CATEGORIES;
export const HOME_MULTIMEDIA = `${window.__env.MULTIMEDIA_BASE_URL}/`