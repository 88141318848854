<template>
  <div class="registration">
    <b-container class="pt-5">
      <b-row align-h="center" align-v="center">
        <b-col md="8">
          <b-card>
            <LocalesCombo class="locales-combo" />

            <div class="text-center py-4">
              <router-link :to="{ name: 'Home' }">
                <img src="../assets/scialari.svg" width="150" />
              </router-link>

              <h5 class="mt-3 text-muted">
                {{ $t("pages.registration.description") }}
              </h5>
            </div>

            <validation-observer
              ref="registrationObserver"
              v-slot="{ handleSubmit }"
            >
              <b-form @submit.stop.prevent="handleSubmit(submitUser)">
                <validation-provider
                  name="First Name"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="first-name-input-group"
                    :label="$t('pages.registration.firstName')"
                    label-for="first-name-input"
                    :label-cols-md="4"
                  >
                    <b-form-input
                      size="sm"
                      id="first-name-input"
                      name="geo-first-name"
                      :placeholder="
                        $t('pages.registration.placeholders.userFirstname')
                      "
                      v-model="newUser.firstName"
                      :state="getValidationState(validationContext)"
                      aria-describedby="first-name-input-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                      id="first-name-input-live-feedback"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Last Name"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="last-name-input-group"
                    :label="$t('pages.registration.lastName')"
                    label-for="last-name-input"
                    :label-cols-md="4"
                  >
                    <b-form-input
                      size="sm"
                      id="last-name-input"
                      name="geo-last-name"
                      :placeholder="
                        $t('pages.registration.placeholders.userLastname')
                      "
                      v-model="newUser.lastName"
                      :state="getValidationState(validationContext)"
                      aria-describedby="last-name-input-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="last-name-input-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <hr />

                <h6 class="text-muted">
                  {{ $t("pages.registration.contacts") }}
                </h6>
                <div class="pl-3">
                  <validation-provider
                    name="Email"
                    :rules="{ required: true, email: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="contact-email-input-group"
                      label="Email"
                      label-for="contact-email-input"
                      :label-cols-md="4"
                    >
                      <b-form-input
                        size="sm"
                        id="contact-email-input"
                        type="email"
                        name="geo-contact-email"
                        :placeholder="
                          $t('pages.registration.placeholders.contactEmail')
                        "
                        v-model="newUser.contacts.email"
                        :state="getValidationState(validationContext)"
                        aria-describedby="contact-email-input-live-feedback"
                      ></b-form-input>

                      <b-form-invalid-feedback
                        id="contact-email-input-live-feedback"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    name="Telephone"
                    :rules="{ required: true, numeric: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="contact-telephone-input-group"
                      :label="$t('pages.registration.telephone')"
                      label-for="contact-telephone-input"
                      :label-cols-md="4"
                    >
                      <b-form-input
                        size="sm"
                        id="contact-telephone-input"
                        type="tel"
                        name="geo-contact-telephone"
                        :placeholder="
                          $t('pages.registration.placeholders.contactTelephone')
                        "
                        v-model="newUser.contacts.telephone"
                        :state="getValidationState(validationContext)"
                        aria-describedby="contact-telephone-input-live-feedback"
                      ></b-form-input>

                      <b-form-invalid-feedback
                        id="contact-telephone-input-live-feedback"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>

                <hr />

                <h6 class="text-muted">
                  {{ $t("pages.registration.credentials") }}
                </h6>
                <div class="pl-3">
                  <validation-provider
                    name="Username"
                    :rules="{ required: true, email: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="email-input-group"
                      label="Email"
                      label-for="email-input"
                      :label-cols-md="4"
                    >
                      <b-form-input
                        size="sm"
                        id="email-input"
                        name="geo-email"
                        :placeholder="
                          $t('pages.registration.placeholders.userEmail')
                        "
                        v-model="newUser.username"
                        :state="getValidationState(validationContext)"
                        aria-describedby="email-input-live-feedback"
                      ></b-form-input>

                      <b-form-invalid-feedback id="email-input-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    name="newPassword"
                    :rules="{
                      required: true,
                      min: 8,
                      password: '@confirmPassword',
                    }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="new-password-fieldset"
                      :label="$t('pages.registration.newPassword')"
                      label-for="new-password"
                      :label-cols-md="4"
                    >
                      <b-form-input
                        size="sm"
                        id="new-password"
                        v-model.trim="newUser.password"
                        :placeholder="
                          $t('pages.registration.placeholders.newPassword')
                        "
                        :state="getValidationState(validationContext)"
                        type="password"
                      ></b-form-input>

                      <b-form-invalid-feedback id="new-password-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    name="confirmPassword"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="confirm-password-fieldset"
                      :label="$t('pages.registration.confirmPassword')"
                      label-for="confirm-password"
                      :label-cols-md="4"
                    >
                      <b-form-input
                        size="sm"
                        id="confirm-password"
                        v-model.trim="confirmPassword"
                        :placeholder="
                          $t('pages.registration.placeholders.confirmPassword')
                        "
                        :state="getValidationState(validationContext)"
                        type="password"
                      ></b-form-input>

                      <b-form-invalid-feedback
                        id="confirm-password-live-feedback"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>

                <hr />

                <CustomCheckbox
                  class="mb-3"
                  id="user-is-business"
                  :label="$t('pages.registration.business')"
                  v-model="isBusiness"
                />

                <template v-if="isBusiness">
                  <h6 class="text-muted">
                    {{ $t("pages.registration.companyDetails") }}
                  </h6>
                  <div class="pl-3">
                    <validation-provider
                      name="Company Name"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="company-name-input-group"
                        :label="$t('pages.registration.companyName')"
                        label-for="company-name-input"
                        :label-cols-md="4"
                      >
                        <b-form-input
                          size="sm"
                          id="company-name-input"
                          name="geo-company-name"
                          :placeholder="$t('pages.registration.companyName')"
                          v-model="newUser.companyName"
                          :state="getValidationState(validationContext)"
                          aria-describedby="company-name-input-live-feedback"
                        ></b-form-input>

                        <b-form-invalid-feedback
                          id="company-name-input-live-feedback"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      name="Company Address"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="company-address-input-group"
                        :label="$t('pages.registration.companyAddress')"
                        label-for="company-address-input"
                        :label-cols-md="4"
                      >
                        <b-form-input
                          size="sm"
                          id="company-address-input"
                          name="geo-company-address"
                          :placeholder="$t('pages.registration.companyAddress')"
                          v-model="newUser.companyAddress"
                          :state="getValidationState(validationContext)"
                          aria-describedby="company-address-input-live-feedback"
                        ></b-form-input>

                        <b-form-invalid-feedback
                          id="vat-number-input-live-feedback"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      name="VAT Number"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="vat-number-input-group"
                        :label="$t('pages.registration.vatNumber')"
                        label-for="vat-number-input"
                        :label-cols-md="4"
                      >
                        <b-form-input
                          size="sm"
                          id="vat-number-input"
                          name="geo-vat-number"
                          :placeholder="$t('pages.registration.vatNumber')"
                          v-model="newUser.vatNumber"
                          :state="getValidationState(validationContext)"
                          aria-describedby="vat-number-input-live-feedback"
                        ></b-form-input>

                        <b-form-invalid-feedback
                          id="vat-number-input-live-feedback"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </template>

                <b-row class="pt-3" no-gutters align-h="end">
                  <div class="invalid-feedback d-block" v-if="captchaError">
                    {{ $t("errors.captchaError") }}
                  </div>
                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    :sitekey="siteKey"
                    :loadRecaptchaScript="true"
                  ></vue-recaptcha>
                </b-row>

                <br />

                <div class="text-center">
                  <RaisedButton
                    type="submit"
                    variant="success"
                    class="w-50 mb-3"
                    :disabled="disableButton"
                  >
                    <font-awesome-icon icon="shield-alt"></font-awesome-icon>
                    <span class="ml-3">{{ $t("actions.confirm") }}</span>
                  </RaisedButton>

                  <br />

                  <FlatButton
                    class="w-50"
                    @click.native="$router.push({ name: 'Login' })"
                    >{{ $t("actions.cancel") }}</FlatButton
                  >
                </div>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { User } from "../models/User";
import { RECAPTCHA_SITE_KEY } from "../globals";

import FlatButton from "../components/FlatButton.vue";
import RaisedButton from "../components/RaisedButton.vue";
import LocalesCombo from "../components/LocalesCombo.vue";
import CustomCheckbox from "../components/CustomCheckbox.vue";

import VueRecaptcha from "vue-recaptcha";
import { Users } from "../api";
import { cloneDeep } from "lodash";

export default {
  name: "Registration",
  components: {
    FlatButton,
    RaisedButton,
    CustomCheckbox,
    LocalesCombo,
    VueRecaptcha,
  },
  data: () => ({
    newUser: new User(),
    isBusiness: false,
    confirmPassword: "",
    siteKey: RECAPTCHA_SITE_KEY,
    captchaError: null,
  }),
  computed: {
    disableButton: function () {
      return (
        this.newUser.password != this.confirmPassword ||
        this.captchaError ||
        !this.newUser.captcha
      );
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onCaptchaVerified(recaptchaToken) {
      this.newUser.captcha = recaptchaToken;
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    submitUser() {
      this.$loading.show();

      if (this.isBusiness) this.newUser.category = "business";
      else this.newUser.category = "user";

      const body = cloneDeep(this.newUser);
      body.lang = this.$i18n.locale;

      Users.createUser(body).then(
        ({ data }) => {
          this.$loading.hide();
          console.log(data);
          this.$bvToast.toast(this.$t("messages.registrationSuccessMsg"), {
            title: this.$t("messages.registrationSuccess"),
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "Login" });
        },
        (err) => {
          this.$loading.hide();
          console.log(err);
          this.$bvToast.toast(this.$t("messages.registrationErrorMsg"), {
            title: this.$t("messages.registrationError"),
            variant: "danger",
            solid: true,
          });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.registration {
  .locales-combo {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
</style>