<template>
  <div class="content-detail">
    <CustomBreadcrumb class="my-3">
      <template v-if="!isPreview">
        <b-breadcrumb-item to="/">
          <b-icon icon="house-fill"></b-icon>
          <span class="ml-2">{{ $t("navigation.home") }}</span>
        </b-breadcrumb-item>
        <b-breadcrumb-item to="/contents-catalog">
          <span class="ml-2">{{ $t("navigation.contents") }}</span>
        </b-breadcrumb-item>
      </template>
      <b-breadcrumb-item v-else :to="{ name: 'ContentsList' }">{{
        $t("navigation.contentsList")
      }}</b-breadcrumb-item>
      <b-breadcrumb-item class="text-truncate" active v-if="content && !error">
        {{ getFieldTranslation(content, "title") }}
      </b-breadcrumb-item>
    </CustomBreadcrumb>

    <Loader v-if="!content" :size="5" />

    <template v-else>
      <CustomAlert
        type="warning"
        v-if="
          (content.status == 'modified' || content.status == 'pending') &&
          user.id == content.ownerId
        "
      >
        <font-awesome-icon icon="info-circle"></font-awesome-icon>
        <span class="ml-2">{{
          content.status == "modified"
            ? $t("admin.contents.detail.modifiedAlert")
            : $t("admin.contents.detail.pendingAlert")
        }}</span>
      </CustomAlert>

      <CustomAlert type="danger" v-if="error">
        <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
        <span class="ml-2">{{
          error == 404
            ? $t("errors.contentNotFound")
            : $t("errors.unableToLoadContent")
        }}</span>
      </CustomAlert>

      <div class="content-card" :class="{ 'm-0': isPreview }" v-else>
        <template v-if="isPreview">
          <b-row
            no-gutters
            class="bg-dark p-2"
            align-h="end"
            v-if="user.role == 'provider' && content.ownerId == user.id"
          >
            <RaisedButton
              variant="warning"
              @click.native="
                $router.push({
                  name: 'ContentEdit',
                  params: { id: content.id },
                })
              "
            >
              <font-awesome-icon icon="pen"></font-awesome-icon>
              <span class="ml-2">{{
                content.status == "pending"
                  ? $t("actions.edit")
                  : $t("admin.contents.detail.keepEditing")
              }}</span>
            </RaisedButton>
            <RaisedButton
              class="ml-3"
              variant="danger"
              @click.native="$bvModal.show('delete-modal')"
            >
              <font-awesome-icon icon="trash"></font-awesome-icon>
              <span class="ml-2">{{ $t("actions.delete") }}</span>
            </RaisedButton>
          </b-row>

          <CustomAlert
            v-if="
              user.role == 'admin' &&
              (content.status == 'pending' || content.status == 'modified')
            "
            type="warning"
            class="d-flex align-items-center"
          >
            <div class="pr-2">
              <font-awesome-icon
                size="lg"
                icon="exclamation-circle"
              ></font-awesome-icon>
            </div>

            <b-col class="text-left">
              <h5 class="mb-0">
                {{ $t("admin.contents.detail.pendingTitle") }}
              </h5>
              <span>{{ $t("admin.contents.detail.pendingDescription") }}</span>
            </b-col>

            <RaisedButton
              variant="light"
              class="text-dark"
              @click.native="
                $router.push({
                  name: 'ContentDraft',
                  params: { id: content.id },
                })
              "
            >
              <font-awesome-icon icon="search"></font-awesome-icon>
              <span class="ml-2">{{ $t("actions.inspect") }}</span>
            </RaisedButton>
            <RaisedButton
              class="ml-3"
              variant="success"
              @click.native="acceptContent"
            >
              <font-awesome-icon icon="check"></font-awesome-icon>
              <span class="ml-2">{{ $t("actions.accept") }}</span>
            </RaisedButton>
            <RaisedButton
              class="ml-3"
              variant="danger"
              @click.native="$bvModal.show('reject-content-modal')"
            >
              <font-awesome-icon icon="times"></font-awesome-icon>
              <span class="ml-2">{{ $t("actions.decline") }}</span>
            </RaisedButton>
          </CustomAlert>
        </template>

        <div class="content-media bg-dark" v-if="images && images.length">
          <VueGallery
            :images="images"
            :index="index"
            :options="{ continuous: false }"
            @close="index = null"
          ></VueGallery>

          <ContentGallery
            :images="images"
            @selected-image="index = $event"
          ></ContentGallery>
        </div>

        <b-container fluid="xl" class="content-container mt-2">
          <b-row>
            <b-col cols="12" lg="7" xl="8">
              <div class="content-card-element header">
                <h2 class="text-truncate mb-0">
                  {{ getFieldTranslation(content, "title") }}
                </h2>
                <div class="page-title">
                  <div
                    v-if="
                      content.address.street ||
                      content.address.city ||
                      content.address.country
                    "
                  >
                    <b-icon icon="geo-alt-fill"></b-icon>
                    <span class="ml-2">{{
                      content.address | contentAddress
                    }}</span>
                  </div>
                  <div v-if="content.contacts && content.contacts.telephone">
                    <b-icon icon="telephone-fill"></b-icon>
                    <span class="ml-2">{{ content.contacts.telephone }}</span>
                  </div>
                </div>
              </div>

              <div class="content-card-element">
                <b-form-row>
                  <b-col
                    cols="6"
                    v-for="(field, index) of content.extra"
                    :key="index"
                  >
                    <div class="content-extra-field">
                      <div class="extra-field-icon">
                        <font-awesome-icon
                          :icon="field.icon || 'info'"
                        ></font-awesome-icon>
                      </div>

                      <b-col>
                        <span class="extra-field-title">{{
                          getFieldTranslation(field, "title")
                        }}</span>
                        <span>{{
                          getFieldTranslation(field, "stringValue")
                        }}</span>
                      </b-col>
                    </div>
                  </b-col>
                </b-form-row>
              </div>

              <p
                class="p-3 border"
                v-html="getFieldTranslation(content, 'description')"
              ></p>
            </b-col>

            <b-col cols="12" lg="5" xl="4" class="my-3">
              <LeafletMap
                class="my-3"
                :placeholderMode="true"
                :center="[
                  content.location.coordinates[1],
                  content.location.coordinates[0],
                ]"
                :detailMarker="true"
                :zoom="9"
              ></LeafletMap>

              <div
                v-if="
                  content.packages &&
                  content.packages.length == 1 &&
                  !content.disabled
                "
              >
                <b-card footer-tag="footer" class="package-card-single">
                  <AvailabilityCalendar
                    :packageId="content.packages[0].id"
                    :isExpanded="true"
                  >
                  </AvailabilityCalendar>
                  <template #footer>
                    <b-row
                      no-gutters
                      align-v="center"
                      class="justify-content-between"
                    >
                      <RaisedButton
                        v-if="content.bookingType != 'none'"
                        variant="primary"
                        :disabled="$route.name == 'ContentPreview'"
                        @click.native="
                          showPackageDetailModal(content.packages[0])
                        "
                        ><span>
                          {{ $t("components.contentPresentation.details") }}
                        </span>
                      </RaisedButton>
                      <h6
                        class="pt-1 mb-0"
                        v-if="content.packages[0].price.isFree"
                      >
                        {{ $t("common.free") }}
                      </h6>
                      <PriceLabel
                        v-else-if="!content.discount || content.discount === 0"
                        :price="content.packages[0].price.adultPrice"
                      />
                      <div v-else class="d-flex align-items-center">
                        <span class="price-cut mr-3"
                          >{{ content.packages[0].price.adultPrice }}€</span
                        >
                        <PriceLabel
                          class="discounted"
                          :price="
                            evaluateDiscount(
                              content.packages[0].price.adultPrice,
                              content.discount
                            )
                          "
                        />
                      </div>
                    </b-row>
                  </template>
                </b-card>
              </div>
            </b-col>
          </b-row>
          <template
            v-if="
              content.packages &&
              content.packages.length > 1 &&
              !content.disabled
            "
          >
            <b-row class="mb-2">
              <h4>
                {{ $t("components.contentPresentation.packagesList") }}
              </h4>
            </b-row>
            <b-row class="mb-3">
              <b-col
                lg="4"
                class="mt-3"
                v-for="(pack, index) of content.packages"
                :key="index"
              >
                <b-card footer-tag="footer" class="package-card">
                  <b-card-title>{{
                    getFieldTranslation(pack, "title")
                  }}</b-card-title>
                  <b-card-text>{{
                    getFieldTranslation(pack, "description")
                  }}</b-card-text>

                  <template #footer>
                    <b-row
                      no-gutters
                      align-v="center"
                      class="justify-content-between"
                    >
                      <RaisedButton
                        v-if="content.bookingType != 'none'"
                        variant="primary"
                        :disabled="$route.name == 'ContentPreview'"
                        @click.native="showPackageDetailModal(pack)"
                        ><span>
                          {{ $t("components.contentPresentation.details") }}
                        </span>
                      </RaisedButton>
                      <h6 class="pt-1 mb-0" v-if="pack.price.isFree">
                        {{ $t("common.free") }}
                      </h6>
                      <PriceLabel
                        v-else-if="!content.discount || content.discount === 0"
                        :price="pack.price.adultPrice"
                      />
                      <div v-else class="d-flex align-items-center">
                        <span class="price-cut mr-3"
                          >{{ pack.price.adultPrice }}€</span
                        >
                        <PriceLabel
                          class="discounted"
                          :price="
                            evaluateDiscount(
                              pack.price.adultPrice,
                              content.discount
                            )
                          "
                        />
                      </div>
                    </b-row>
                  </template>
                </b-card>
              </b-col>
            </b-row>
          </template>

          <template v-if="content.disabled">
            <b-row class="mb-3 justify-content-center">
              <b-col cols="8">
                <CustomAlert type="warning" v-if="content.disabled">
                  <font-awesome-icon
                    icon="exclamation-triangle"
                  ></font-awesome-icon>
                  <span class="ml-2">{{ $t("errors.notAvailable") }}</span>
                </CustomAlert>
              </b-col>
            </b-row>
          </template>

          <template
            v-if="content.externalLinks && content.externalLinks.length > 0"
          >
            <h4>
              {{ $t("components.contentPresentation.externalLinkList") }}
            </h4>
            <ExternalLinksList
              :externalLinks="content.externalLinks"
            ></ExternalLinksList>
          </template>
        </b-container>
      </div>
    </template>

    <b-modal
      id="booking-wizard-modal"
      size="xl"
      centered
      hide-footer
      hide-header
      title="Wizard"
    >
      <BookingWizard
        v-if="content"
        :pack="selectedPackage"
        :contentId="id"
        :contentOwner="content.ownerId"
        :contentType="content.bookingType"
        :discount="content.discount"
        :fromCart="false"
        :bookUnavailable="bookUnavailable"
        @submitReservation="sendRequest"
        @add-to-cart="addToCart"
        @previous-tab="resetRequestResult"
      >
      </BookingWizard>
    </b-modal>

    <b-modal
      id="reject-content-modal"
      hide-footer
      :title="$t('admin.contents.detail.rejectContent')"
    >
      <p>{{ $t("admin.contents.detail.rejectMessage") }}</p>

      <b-form-group
        id="content-reject-reason-fieldset"
        label="Message"
        label-for="content-reject-reason-field"
      >
        <b-form-textarea
          id="content-reject-reason-field"
          rows="4"
          :placeholder="$t('admin.contents.detail.rejectPlaceholder')"
          v-model="rejectedMessage"
        ></b-form-textarea>
      </b-form-group>

      <b-row no-gutters align-v="center" align-h="between">
        <FlatButton @click.native="$bvModal.hide('reject-content-modal')">{{
          $t("actions.cancel")
        }}</FlatButton>

        <RaisedButton variant="danger" @click.native="rejectContent">
          <font-awesome-icon icon="paper-plane"></font-awesome-icon>
          <span class="ml-2">{{ $t("actions.confirm") }}</span>
        </RaisedButton>
      </b-row>
    </b-modal>

    <DeleteModal
      @cancel="$bvModal.hide('delete-modal')"
      @confirm="deleteContent"
    >
      <p v-if="content && !error">
        {{
          $t("admin.platformManagement.categories.delete.mainMessage", {
            title: getFieldTranslation(content, "title"),
          })
        }}
      </p>
    </DeleteModal>

    <b-modal
      id="registration-modal"
      header-border-variant="white"
      hide-footer
      size="lg"
      centered
    >
      <div class="text-center mb-3">
        <img src="../assets/scialari.svg" width="150" class="pb-3" />
        <h5>
          {{ $t("messages.loginNeededMain") }}
        </h5>
        <p>
          {{ $t("messages.loginNeededSub") }}
        </p>
      </div>

      <b-row no-gutters align-v="center" align-h="center" class="pt-3 pb-5">
        <RaisedButton
          class="mr-5"
          variant="primary"
          @click.native="$router.push({ name: 'Login' })"
        >
          <font-awesome-icon icon="sign-in-alt"></font-awesome-icon>
          <span class="ml-2">{{ $t("actions.signIn") }}</span>
        </RaisedButton>
        <RaisedButton
          variant="success"
          @click.native="$router.push({ name: 'Registration' })"
        >
          <font-awesome-icon icon="user-circle"></font-awesome-icon>
          <span class="ml-2">{{ $t("actions.register") }}</span>
        </RaisedButton>
      </b-row>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
@import "../variables.scss";

@media (max-width: 425px) {
  .content-card {
    margin: 0;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .content-card {
    margin: 0 1.5rem;
  }
}

@media screen and (min-width: 769px) {
  .content-card {
    margin: 0 3rem;
  }
}

@media (min-width: 1200px) {
  .container-xl.content-container {
    max-width: 95%;
  }
}

.content-container .map-container {
  height: 250px;
}
.mini-map {
  pointer-events: none;
  z-index: 1;
}
.discounted {
  transform: skewY(-8deg);
  background-color: #ff3a56;
  border-radius: 3px 4px 4px 3px;
  position: relative;
  color: white;
  padding: 0 10px 0 10px;
}

.content-card {
  background-color: white;
  overflow: hidden;
  // padding: 2rem 0;
  box-shadow: 0 0.12rem 0.5rem 0.12rem rgba(black, 0.1);
  border-radius: 0.25rem;

  .content-category {
    border-radius: 0.25rem;
    border-width: 3px;
    border-style: solid;
    padding: 0.5rem;
    min-width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.675rem;

    .icon {
      font-size: 2rem;
    }
  }

  .package-card {
    min-height: 280px;
  }

  .package-card-single {
    min-height: 280px;
  }
  .price-plans-list {
    max-height: 500px;
    padding: 0 0.5rem;
    overflow-y: auto;

    .price-plan-card:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border: 1px solid #ccc;
      border-radius: 10px;
    }
  }

  .content-extra-field {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // border: 1px solid #ddd;

    .extra-field-icon {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 1px solid #ccc;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .extra-field-title {
      display: block;
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .content-media {
    padding: 0.5rem;
    box-shadow: inset 0 -0.75rem 1.5rem #00000088;

    .image {
      float: left;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border: 1px solid #ebebeb;
      margin: 5px;
    }
  }

  .price {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Quicksand";
  }

  .free-content {
    font-weight: 600;
    font-size: 1.2rem;
    font-family: "Quicksand";
    padding: 1rem;
    border: 1px solid #ddd;
    text-align: center;
    text-transform: uppercase;
  }

  .content-card-element {
    padding: 0.75rem;

    &.header {
      padding: 1rem 0 0.5rem;
      align-items: center;

      .content-info {
        display: flex;
        flex-wrap: wrap;

        div:not(:first-of-type) {
          padding-left: 0.75rem;
        }
        div:not(:last-of-type) {
          padding-right: 0.75rem;
        }
      }
    }

    .title {
      // font-weight: 400;
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    .subtitle {
      font-size: 1.5rem;
      margin-bottom: 0.75rem;

      &.sm {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
@media screen and (max-width: 580px) {
  .content-card .content-media {
    padding: 0;
  }
}
.external-links-list {
  max-height: 500px;
  overflow-y: auto;
  display: flex;

  .external-link-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ddd;

    &:not(:last-of-type) {
      border-bottom: 1px solid #ddd;
    }

    .external-link-icon {
      padding: 0 0.5rem;
      font-size: 1.5rem;
    }
  }
}
</style>

<script>
import VueGallery from "vue-gallery";
import LeafletMap from "../components/LeafletMap.vue";
import { MULTIMEDIA_API } from "../globals";
import { BookingCheckoutApi, Contents, Multimedia, Settings } from "../api";
import Loader from "../components/Loader.vue";
import FlatButton from "../components/FlatButton.vue";
import CustomBreadcrumb from "../components/CustomBreadcrumb.vue";
import ContentGallery from "../components/ContentGallery.vue";
import RaisedButton from "../components/RaisedButton.vue";
import DeleteModal from "../components/DeleteModal.vue";
import CustomAlert from "../components/CustomAlert.vue";
import ExternalLinksList from "../components/ExternalLinksList.vue";
import AvailabilityCalendar from "../components/AvailabilityCalendar.vue";
import { serializeDay, calculateDiscount } from "../utils";
import BookingWizard from "../components/BookingWizard.vue";
import PriceLabel from "../components/PriceLabel.vue";
import { cloneDeep } from "lodash";
import { Marker } from "../models/Marker";
import { EventBus } from "../eventBus";
import { BookingCheckout } from "../models/BookingCheckout";
import { handleError } from "../utils";

export default {
  name: "ContentDetail",
  data: () => ({
    id: undefined,
    error: undefined,
    selectedPlan: null,
    isPreview: false,
    user: null,
    content: null,
    index: null,
    extraFields: [],
    mainImageUrl: null,
    coverImageUrl: null,
    images: null,
    files: null,
    marker: null,
    slide: 0,
    sliding: null,
    availableDates: [],
    selectedPackage: null,
    rejectedMessage: "",

    //checkout wizard data
    items: [],
    auto: [],
    manual: [],
    autoTotal: 0,
    bookUnavailable: null,
  }),
  components: {
    LeafletMap,
    FlatButton,
    Loader,
    CustomBreadcrumb,
    VueGallery,
    AvailabilityCalendar,
    PriceLabel,
    BookingWizard,
    ContentGallery,
    ExternalLinksList,
    DeleteModal,
    RaisedButton,
    CustomAlert,
  },
  created() {
    this.id = this.$route.params.id;
    if (this.$route.name == "ContentPreview") this.isPreview = true;
    this.user = this.$cookies.get("atlas_user") || null;
    this.initSettings();
  },
  methods: {
    goToRegistration() {
      this.$router.push({ name: "Registration" });
    },
    showPackageDetailModal(pack) {
      if (this.user === null) {
        this.$bvModal.show("registration-modal");
        return;
      }
      this.selectedPackage = pack;
      this.$bvModal.show("booking-wizard-modal");
    },
    calculateAvailableDates() {
      if (this.content.packages[0].availability.validityRanges) {
        let days = this.content.packages[0].availability.daysOfWeek.map((day) =>
          serializeDay(day, true)
        );
        this.content.packages[0].availability.validityRanges.map((range) => {
          this.availableDates.push({
            start: new Date(range[0]),
            end: new Date(range[1]),
            weekdays: days,
          });
        });
      }
    },
    initSettings() {
      Settings.getSettings().then(
        (res) => {
          const result = res.data;
          this.extraFields = result.fields || [];
          this.categories = result.categories || [];
          this.tags = result.tags || [];
          this.getContent();
          this.getMultimedia();
        },
        (err) => {
          console.log(err);
          this.error = true;
          handleError(
            this,
            err,
            "messages.retrieveInfoErrorMsg",
            "messages.contentError"
          );
        }
      );
    },
    getContent: function () {
      Contents.getContent(this.id).then(
        (res) => {
          this.content = res.data;
          if (this.content.packages && this.content.packages.length == 1) {
            this.calculateAvailableDates();
          }
          this.mainImageUrl = `${MULTIMEDIA_API}/data/${this.id}/main`;
          this.marker = new Marker(
            this.id,
            this.content.location.coordinates,
            this.content.title,
            this.mainImageUrl,
            this.content.address,
            this.content.category
          );
          if (this.content.extra) this.fixExtraFields();
        },
        (err) => {
          const response = err.response;

          this.error = response.status;
          this.content = {};
          handleError(
            this,
            err,
            "messages.contentDetailMsg",
            "messages.contentError"
          );
        }
      );
    },
    fixExtraFields() {
      this.content.extra
        .sort((a, b) => a.position < b.position)
        .forEach((field, index) => {
          const completeField = this.extraFields.find((f) => f.id == field.id);
          if (!completeField) this.content.extra.splice(index, 1);
          else {
            field.icon = completeField.icon;
            field.title = completeField.title;
          }
        });
    },
    planReservation(plan) {
      this.selectedPlan = plan;
      this.$bvModal.show("reservation-modal");
    },
    getMultimedia() {
      Multimedia.getMultimediaList(this.id).then(
        ({ data: images }) => {
          if (!images.length) return;

          const token = this.$cookies.get("atlas_token");

          this.images = images
            .sort((a) => (a.mainImage ? -1 : 0))
            .filter((img) => img.approved)
            .map((media) => {
              return `${MULTIMEDIA_API}/data/${this.id}/${media.id}${
                token ? "?token=" + token : ""
              }`;
            });
        },
        (err) => {
          if (err.response && err.response.status == 404) this.files = [];
          else
            handleError(
              this,
              err,
              "messages.multimediaErrorMsg",
              "messages.multimediaError"
            );
        }
      );
    },
    evaluateDiscount(price, discount) {
      return calculateDiscount(price, discount);
    },
    deleteContent() {
      this.$loading.show();

      Contents.deleteContent(this.content.id).then(
        () => {
          this.$bvModal.hide("delete-modal");
          this.$root.$bvToast.toast(this.$t("messages.contentDeleted"), {
            title: this.$t("messages.contentSuccess"),
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "ContentsList" });
          this.$loading.hide();
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentDeleteErrorMsg",
            "messages.contentDeleteErrorMsg"
          );
          this.$loading.hide();
        }
      );
    },
    resetRequestResult() {
      this.bookUnavailable = null;
    },
    sendRequest(evt) {
      this.$loading.show();

      const reservation = cloneDeep(evt);
      reservation.lang = this.appLocale;

      let booking = new BookingCheckout();
      booking.userId = reservation.userId;
      booking.name = reservation.name;
      booking.email = reservation.email;
      booking.phone = reservation.phoneNumber;
      booking.captcha = reservation.captcha;

      let correctReservation = {
        packageId: reservation.packageId,
        contentId: reservation.contentId,
        contentOwner: reservation.contentOwner,
        kidAmount: reservation.kidAmount,
        adultAmount: reservation.adultAmount,
        start: reservation.start,
        end: reservation.end,
        slotId: reservation.slotId,
        name: reservation.name,
        email: reservation.email,
        phoneNumber: reservation.phoneNumber,
        options: reservation.options,
        contentName: this.getFieldTranslation(this.content, "title"),
      };

      booking.bookings.push(correctReservation);

      BookingCheckoutApi.sendBookingCheckout(booking).then(
        (res) => {
          this.$loading.hide();
          this.$bvModal.hide("booking-wizard-modal");
          this.$bvToast.toast(this.$t("messages.bookingDoneMsg"), {
            title: this.$t("messages.bookingDone"),
            variant: "success",
            solid: true,
          });
          const checkoutUrl = res.data.checkoutUrl;
          if (checkoutUrl != "") {
            window.location.href = checkoutUrl;
          }
        },
        (err) => {
          console.error(err);
          if (
            err.response.status === 403 &&
            err.response.data.error.startsWith("content")
          ) {
            let date = err.response.data.error;
            this.bookUnavailable = date.substring(28, date.length);
            handleError(
              this,
              err,
              err.response.data.error,
              "messages.bookingError"
            );
          } else {
            handleError(
              this,
              err,
              "messages.bookingErrorMsg",
              "messages.bookingError"
            );
          }
          this.$loading.hide();
        }
      );
    },
    acceptContent() {
      this.$loading.show();

      Contents.contentApproval(this.id, true).then(
        () => {
          this.$loading.hide();
          this.$bvToast.toast(this.$t("messages.contentAvailableMsg"), {
            title: this.$t("messages.contentAvailable"),
            variant: "success",
            solid: true,
          });
          this.content = null;
          this.initSettings();
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentUnaccepted",
            "messages.contentError"
          );

          this.$loading.hide();
        }
      );
    },
    rejectContent() {
      this.$loading.show();

      Contents.contentApproval(this.id, false, this.rejectedMessage).then(
        () => {
          this.$loading.hide();
          this.$bvToast.toast(this.$t("messages.contentDenyMsg"), {
            title: this.$t("messages.contentDeny"),
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("reject-content-modal");
          this.content = null;
          this.initSettings();
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentRejectMsg",
            "messages.contentError"
          );
          this.$loading.hide();
        }
      );
    },
    addToCart(reservation) {
      const booking = cloneDeep(reservation);

      booking.lang = this.appLocale;
      booking.content = {
        id: this.content.id,
        title: this.content.title,
      };
      booking.package = {
        title: this.selectedPackage.title,
        description: this.selectedPackage.description,
      };
      booking.type = this.content.bookingType;

      const currentCart = this.$cookies.get("atlas_cart") || "[]";
      const newCart = JSON.parse(currentCart);
      newCart.push(booking);
      this.$cookies.set("atlas_cart", JSON.stringify(newCart), Infinity); // never expire
      this.$bvToast.toast(this.$t("components.cart.cartUpdateMsg"), {
        title: this.$t("components.cart.cartUpdate"),
        variant: "success",
        solid: true,
      });
      EventBus.$emit("cart-item-added");
      this.$bvModal.hide("booking-wizard-modal");
    },
  },
};
</script>