import { client } from './setup';
import { ACCOUNT_API, BOOKNG_API } from '../globals';

export default {
    getAccountRequests: (params) => client.get(`${ACCOUNT_API}/bookings`, { params }),
    getAllRequests: (params) => client.get(BOOKNG_API, { params }),
    getRequest: (requestId) => client.get(`${BOOKNG_API}/${requestId}`),
    getContentRequests: (contentId, params) => client.get(`${BOOKNG_API}/${contentId}`, { params }),
    getContentRequest: (contentId, requestId) => client.get(`${BOOKNG_API}/${contentId}/${requestId}`),
    sendRequest: (request) => client.post(BOOKNG_API, request),
    updateRequestState: (requestId, state) => client.patch(`${BOOKNG_API}/${requestId}/${state}`),
    deleteBooking: (requestId) => client.delete(`${BOOKNG_API}/${requestId}`)
}