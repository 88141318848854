<template>
  <div class="who-are-we">
    <b-container>
      <h3 class="display-4 page-title text-center mb-4">
        {{ $t("pages.whoAre.head") }}
      </h3>

      <div class="presentation-message">
        <div class="presentation-text page-subtitle">
          {{ $t("pages.whoAre.subtitle") }}
        </div>
      </div>

      <p class="mt-3">
        {{ $t("pages.whoAre.body1") }}

        <br /><br />

        {{ $t("pages.whoAre.body2") }}

        <br /><br />

        {{ $t("pages.whoAre.body3") }}
      </p>
    </b-container>
  </div>
</template>

<style lang="scss">
.who-are-we {
  padding: 2rem 0;

  .presentation-message {
    position: relative;
    background-image: url("../assets/chi-siamo-image.jpg");
    background-position: center;
    background-size: cover;
    height: 200px;
    border-radius: 0.5rem;

    .presentation-text {
      position: absolute;
      font-weight: 500;
      bottom: 2rem;
      right: 1rem;
      font-size: 1.5rem;
      text-shadow: 3px 3px 15px black;
    }
  }
}
</style>

<script>
export default {
  name: "WhoAreWe",
};
</script>