<template>
  <div class="booking-wizard">
    <form-wizard
      :title="$t('components.bookingWizard.bookExperience')"
      :subtitle="$t('components.bookingWizard.followSteps')"
      ref="bookingWizard"
      :start-index.sync="activeTabIndex"
      stepSize="xs"
      shape="circle"
      color="#20a0ff"
      error-color="#ff4949"
    >
      <tab-content
        :title="$t('components.bookingWizard.firstStep')"
        icon="ti-fullscreen"
        :before-change="() => validate('verifyAvailability')"
      >
        <VerifyAvailability
          ref="verifyAvailability"
          :pack="pack"
          @on-validate="onStepValidate"
        />
      </tab-content>

      <tab-content
        :title="$t('components.bookingWizard.thirdStep')"
        icon="ti-check"
        :before-change="() => validate('recapStep')"
      >
        <Recap
          ref="recapStep"
          @on-validate="onStepValidate"
          :pack="pack"
          :reservation="reservation"
          :checkbox="true"
          :discount="discount"
        />
      </tab-content>

      <tab-content
        :title="$t('components.bookingWizard.secondStep')"
        icon="ti-settings"
        :before-change="() => validate('userInfo')"
      >
        <b-row align-h="center">
          <CustomAlert type="danger" class="mb-2" v-if="bookUnavailable != null">
            <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
            <span class="ml-2">
              {{$t("components.bookingWizard.unavailableDate", {date: bookUnavailable})}}
            </span>
          </CustomAlert>
          <b-col md="8">
            <UserInfo
              ref="userInfo"
              :note="true"
              @on-validate="onStepValidate"
            />
          </b-col>
          <b-col md="4">
            <RecapView
              ref="recapView"
              @on-validate="onStepValidate"
              :pack="pack"
              :reservation="reservation"
              :discount="discount"
            />
            <vue-recaptcha
              class="mt-2 float-right"
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              :sitekey="siteKey"
              :loadRecaptchaScript="true"
            ></vue-recaptcha>
          </b-col>

        </b-row>
      </tab-content>

      <RaisedButton variant="secondary" slot="prev" @click.native="previousTab">
        <font-awesome-icon icon="angle-left" />
        <span class="ml-2">{{ $t("components.bookingWizard.back") }}</span>
      </RaisedButton>

      <RaisedButton variant="info" slot="next">
        <span class="mr-2">{{
          activeTabIndex == 1 ? "Prenota" : $t("components.bookingWizard.next")
        }}</span>
        <font-awesome-icon icon="angle-right" />
      </RaisedButton>

      <RaisedButton
        v-if="activeTabIndex == 1"
        @click.native="addToCart"
        class="mr-4"
        variant="success"
        slot="custom-buttons-right"
      >
        <font-awesome-icon icon="shopping-cart" />
        <span class="ml-2">{{ $t("components.bookingWizard.addToCart") }}</span>
      </RaisedButton>

      <RaisedButton
        @click.native="buyNow"
        variant="success"
        slot="finish"
        :disabled="reservation.captcha === undefined"
      >
        <font-awesome-icon icon="cash-register" />
        <span class="ml-2">{{
          contentType === "manual"
            ? $t("components.bookingWizard.bookNow")
            : $t("components.bookingWizard.buyNow")
        }}</span>
      </RaisedButton>
    </form-wizard>

    <!--     <b-modal hide-footer hide-header size="md" id="recaptcha-modal" centered>
      <b-row align-h="center">
        <vue-recaptcha
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
          :sitekey="siteKey"
          :loadRecaptchaScript="true"
        ></vue-recaptcha>
      </b-row>
    </b-modal> -->
  </div>
</template>

<style lang="scss" >
.wizard-icon-container {
  margin: -1px;
}
</style>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import VerifyAvailability from "./booking-wizard-steps/VerifyAvailability.vue";
import UserInfo from "./booking-wizard-steps/UserInfo.vue";
import Recap from "./booking-wizard-steps/Recap.vue";
import RecapView from "./booking-wizard-steps/RecapView.vue";
import RaisedButton from "./RaisedButton.vue";
import VueRecaptcha from "vue-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../globals";
import CustomAlert from "./CustomAlert.vue";

export default {
  name: "BookingWizard",
  components: {
    FormWizard,
    TabContent,
    VerifyAvailability,
    UserInfo,
    Recap,
    RaisedButton,
    VueRecaptcha,
    RecapView,
    CustomAlert,
  },
  props: {
    pack: Object,
    contentId: String,
    contentOwner: String,
    contentType: String,
    bookUnavailable: String,
    discount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      finalModel: {},
      activeTabIndex: 0,
      reservation: {
        packageId: this.pack.id,
        contentId: this.contentId,
        contentOwner: this.contentOwner,
        kidAmount: 0,
        adultAmount: 0,
        discount: this.discount,
      },
      captchaError: false,
      siteKey: RECAPTCHA_SITE_KEY,
    };
  },
  methods: {
    addToCart() {
      this.validate("recapStep");
      this.$emit("add-to-cart", this.reservation);
    },
    buyNow() {
      this.validate("userInfo");
      this.$emit("submitReservation", this.reservation);
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.reservation.captcha = undefined;
    },
    onCaptchaVerified(recaptchaToken) {
      this.validate("userInfo");
      this.reservation.captcha = recaptchaToken;
    },
    forceClearError() {
      this.$refs.wizard.tabs[this.activeTabIndex].validationError = null;
    },
    validate(ref) {
      return this.$refs[ref].validate();
    },
    previousTab() {
      if (this.bookUnavailable != null) {
        this.$emit("previous-tab");
      }
    },
    isLastStep() {
      if (this.$refs.bookingWizard) {
        return this.$refs.bookingWizard.isLastStep;
      }
      return false;
    },
    onStepValidate(validated, stepInfo) {
      if (validated) {
        this.reservation = { ...this.reservation, ...stepInfo };
      }
    },
  },
};
</script>
