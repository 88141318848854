<template>
  <div class="booking-recap">
    <b-row align-v="center" align-h="center">
      <div
        class="content-image mx-3"
        :style="{
          'background-image':
            'url(' + contentImageUrl + booking.contentId + '/main)',
        }"
      ></div>

      <b-col md="8">
        <h4 class="content-title">
          {{ getFieldTranslation(booking.content, "title") }}
        </h4>
        <div
          class="atlas-tag sm text-white pill d-inline-block bg-info"
          v-if="
            booking.content.address &&
            (booking.content.address.street ||
              booking.content.address.city ||
              booking.content.address.country)
          "
        >
          <font-awesome-icon icon="map-marker-alt"></font-awesome-icon>
          <span class="ml-2">{{
            booking.content.address | contentAddress
          }}</span>
        </div>
        <p class="text-muted m-2">
          {{ getFieldTranslation(booking.content, "abstract") }}
        </p>
      </b-col>
      <b-col class="d-flex justify-content-end align-self-end">
        <slot></slot>
      </b-col>
    </b-row>

    <hr />

    <b-row align-h="center">
      <b-col md="6">
        <div class="p-2">
          <h6>
            <font-awesome-icon icon="ticket-alt"></font-awesome-icon>
            <span class="ml-2">{{ $t("admin.requests.detail.recap") }}</span>
          </h6>

          <table class="table table-borderless">
            <tbody>
              <tr>
                <th scope="col">{{ $t("common.status") }}</th>
                <td class="text-right">
                  <div
                    v-if="showStatus"
                    class="atlas-tag text-white pill d-inline-block"
                    :class="getRequestClass()"
                  >
                    <font-awesome-icon
                      :icon="getRequestIcon()"
                    ></font-awesome-icon>
                    <span class="ml-2">{{ $t(`common.${fixStatusText()}`) }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="col">{{ $t("admin.requests.detail.dates") }}</th>
                <td
                  class="text-right"
                  v-if="
                    booking.start.substring(0, 10) ===
                    booking.end.substring(0, 10)
                  "
                >
                  {{ booking.start | formatDate(true) }}
                </td>
                <td v-else class="text-right">
                  {{ booking.start | formatDate(true) }} ->
                  {{ booking.end | formatDate(true) }}
                </td>
              </tr>
              <tr v-if="booking.content.typeBook == 'free'">
                <th scope="col">{{ $t("admin.requests.detail.time") }}</th>
                <td class="text-right">{{ getTime(booking.time) }}</td>
              </tr>
              <tr v-else-if="booking.slotId != ''">
                <th scope="col">Slot</th>
                <td class="text-right">
                  {{ getTime(booking.start) }} - {{ getTime(booking.end) }}
                </td>
              </tr>

              <tr>
                <th scope="col">{{ $t("common.name") }}</th>
                <td class="text-right">{{ booking.name }}</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("common.email") }}</th>
                <td class="text-right">{{ booking.email }}</td>
              </tr>
              <tr v-if="booking.phoneNumber">
                <th scope="col">{{ $t("common.telephone") }}</th>
                <td class="text-right">{{ booking.phoneNumber }}</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("common.info") }}</th>
                <td class="text-right">
                  {{
                    booking.status == "pending"
                      ? $t("admin.requests.detail.pending", {
                          date: formatDate(booking.requestDate),
                        })
                      : $t("admin.requests.detail.confirmed", {
                          date: formatDate(booking.responseDate),
                        })
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <template v-if="booking.notes">
            <h6>{{ $t("admin.requests.detail.notes") }}</h6>
            <p class="border p-2">{{ booking.notes }}</p>
          </template>
        </div>
      </b-col>
      <b-col md="6" class="mb-3 mb-md-0">
        <div class="p-2">
          <h6>
            <font-awesome-icon icon="users"></font-awesome-icon>
            <span class="ml-2">{{
              $t("admin.requests.detail.planGroup")
            }}</span>
          </h6>

          <PricePlanCard v-if="requestPackage" :plan="requestPackage">
            <table class="table-borderless mb-0 w-100">
              <tbody>
                <tr>
                  <th scope="col">{{ $t("admin.requests.detail.adults") }}</th>
                  <td class="text-right">{{ booking.adultAmount }}</td>
                </tr>
                <tr>
                  <th scope="col">
                    {{ $t("admin.requests.detail.children") }}
                  </th>
                  <td class="text-right">{{ booking.kidAmount }}</td>
                </tr>
              </tbody>
            </table>
          </PricePlanCard>

          <PriceLabel class="booking-total" :price="booking.sum" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.booking-recap {
  .content-recap {
    margin: 0 -1rem 2rem -1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 1rem;
    border-bottom: 1px solid #ddd;

    .content-title {
      font-weight: 300;
      font-size: 1.5rem;

      @include media-breakpoint-between(sm, md) {
        font-size: 2rem;
      }
      @include media-breakpoint-between(md, lg) {
        font-size: 2.25rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 2.75rem;
      }
    }
  }
  .content-image {
    border: 1px solid #c5c5c5;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    background-position: center;
    background-size: cover;
  }

  .booking-total {
    text-align: right;

    .price-integers {
      font-size: 2rem;
    }
    .price-currency,
    .price-decimals {
      font-size: 1rem;
    }
  }
}
</style>

<script>
import { MULTIMEDIA_API } from "../globals";
import PricePlanCard from "./PricePlanCard.vue";
import PriceLabel from "./PriceLabel.vue";
import moment from "moment";
import { fixBookingStatus, getBookingClass, getBookingIcon } from "../utils";

export default {
  name: "BookingRecap",
  components: {
    PriceLabel,
    PricePlanCard,
  },
  data: () => ({
    contentImageUrl: `${MULTIMEDIA_API}/data/`,
  }),
  props: {
    booking: {
      type: Object,
      required: true,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    requestPackage() {
      return this.booking.content.packages.find(
        (p) => p.id == this.booking.packageId
      );
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/yyyy HH:mm");
    },
    getTime(date) {
      return moment(date).utc().format("HH:mm");
    },
    getRequestClass() {
      return getBookingClass(this.booking.status);
    },
    getRequestIcon() {
      return getBookingIcon(this.booking.status);
    },
    fixStatusText() {
      return fixBookingStatus(this.booking.status);
    },
  },
};
</script>