<template>
  <div class="contacts">
    <b-container>
      <h3 class="display-4 page-title text-center mb-4">
        {{ $t("pages.contacts.head") }}
      </h3>
      <h2 class="page-subtitle text-center">
        {{ $t("pages.contacts.subtitle") }}
      </h2>

      <p>
        {{ $t("pages.contacts.body") }}
      </p>
    </b-container>
  </div>
</template>

<style lang="scss">
.contacts {
  padding: 2rem 0;
}
</style>

<script>
export default {
  name: "Contacts",
};
</script>