<template>
  <div
    v-if="!isMobile"
    class="content-masonry"
    ref="masonryContainer"
    v-images-loaded:on.progress="imageProgress"
  >
    <div class="d-flex flex-wrap main-row">
      <b-col
        class="image-item main-item"
        cols="12"
        md="8"
        lg="6"
        xl="4"
        @click="$emit('selected-image', 0)"
      >
        <img :src="images[0]" class="content-image" alt="Content main image" />
      </b-col>

      <b-col class="px-0" cols="12" md="4" lg="6" xl="8">
        <div class="masonry-canvas">
          <div
            v-for="(image, index) of contentImages"
            class="image-item"
            :key="index"
            @click="$emit('selected-image', index + 1)"
          >
            <img :src="image" alt="Content image" />
          </div>

          <div
            class="image-item more-images"
            @click="$emit('selected-image', maxImages)"
            v-if="othersLength > 0"
          >
            <img :src="images[maxImages]" alt="Content image" />
            <span class="more-length">+{{ othersLength }}</span>
          </div>
        </div>
      </b-col>
    </div>
  </div>
  <div
    v-else
    class="content-masonry mobile-gallery"
    ref="masonryContainer"
    v-images-loaded:on.progress="imageProgress"
    :style="{ backgroundImage: 'url(' + images[0] + ')' }"
  >
    <div class="d-flex flex-wrap main-row">
      <b-col class="image-item main-item" cols="12" md="8" lg="6" xl="4">
        <img
          :src="images[0]"
          class="content-image"
          alt="Content main image"
          @click="$emit('selected-image', 0)"
        />
      </b-col>
    </div>
  </div>
</template>

<style lang="scss">
.content-masonry {
  height: 35vh;
  min-height: 300px;

  .main-row {
    height: 100%;

    .main-item {
      height: 100%;
    }
  }

  .image-item {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
      box-shadow: 0 0 0 0.2rem #ffffff;
    }

    img {
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      opacity: 0;
      transform: scale(0);
      transition: transform 0.3s ease;
    }
  }

  .masonry-canvas {
    display: flex;
    flex-wrap: wrap;

    .image-item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;

      &.more-images {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;

        img {
          filter: brightness(0.6);

          &:hover {
            filter: brightness(0.8);
          }
        }

        .more-length {
          position: absolute;
          font-size: 4rem;
          color: white;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
.content-masonry.mobile-gallery {
  background-size: cover;
  background-repeat: no-repeat;
  .image-item {
    opacity: 0;
  }
}
</style>

<script>
import imagesLoaded from "vue-images-loaded";

export default {
  name: "ContentGallery",
  props: {
    images: Array,
    maxImages: {
      type: Number,
      default: 6,
    },
  },
  data: () => ({
    loadedImages: [],
    othersLength: undefined,
  }),
  created: function () {
    this.othersLength = this.images.length - this.maxImages;
  },
  directives: {
    imagesLoaded,
  },
  computed: {
    contentImages: function () {
      return this.images.slice(1, this.maxImages);
    },
    isMobile() {
      return window.matchMedia("(max-width:580px)").matches;
    },
  },
  methods: {
    imageProgress: function (_, evt) {
  
      const image = evt.img;
      const parent = image.parentNode;
      const container = this.$refs.masonryContainer;

      image.style.transform = "scale(1)";
      image.style.opacity = 1;

      if (!parent.classList.contains("main-item"))
        parent.style.height = `${container.clientHeight / 2}px`;

      this.loadedImages.push(image);
    },
  },
};
</script>