<template>
  <div class="user-info m-3">
    <validation-observer ref="userInfoValidator">
      <b-form class="user-info-form">
        <validation-provider
          :name="$t('common.name')"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group
            id="user-name-fieldset"
            :label="$t('common.name')"
            label-cols="4"
            label-for="user-name-field"
          >
            <b-form-input
              id="user-name-field"
              :placeholder="$t('common.name')"
              v-model.trim="reservation.name"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback id="user-name-field-live-feedback">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          :name="$t('common.email')"
          :rules="{ required: true, email: true }"
          v-slot="validationContext"
        >
          <b-form-group
            id="user-email-fieldset"
            :label="$t('common.email')"
            label-cols="4"
            label-for="user-email-field"
          >
            <b-form-input
              id="user-email-field"
              :placeholder="$t('common.email')"
              type="email"
              v-model.trim="reservation.email"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback id="user-email-field-live-feedback">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          :name="$t('common.telephone')"
          :rules="{
            required: true,
            regex: /^[+]?[(]?[0-9]{1,4}[)]?[- \./0-9]*$/,
          }"
          v-slot="validationContext"
        >
          <b-form-group
            id="user-telephone-fieldset"
            :label="$t('common.telephone')"
            label-cols="4"
            label-for="user-telephone-field"
          >
            <b-form-input
              id="user-telephone-field"
              type="tel"
              :placeholder="$t('common.telephone')"
              :state="getValidationState(validationContext)"
              v-model.trim="reservation.phoneNumber"
            ></b-form-input>
            <b-form-invalid-feedback id="user-telephone-field-live-feedback">
              {{ $t("errors.invalidTelephone") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-form-group v-if="note"
          id="reservation-notes-fieldset"
          class="mb-0"
          label="Note"
          label-for="reservation-notes-field"
        >
          <b-form-textarea
            id="reservation-notes-field"
            rows="6"
            :placeholder="$t('admin.requests.detail.notes')"
            v-model="reservation.note"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "UserInfo",
  data() {
    return {
      reservation: {
        name: null,
        email: null,
        phoneNumber: null,
        userId: null,
        note: null,
      },
      user: null,
    };
  },
  props: {
    note: Boolean
  },
  created: function () {
    this.user = this.$cookies.get("atlas_user");

    if (this.user) {
      this.reservation.name = this.user.firstName + " " + this.user.lastName;
      this.reservation.email = this.user.contacts.email;
      this.reservation.phoneNumber = this.user.contacts.telephone;
      this.reservation.userId = this.user.id;
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    validate() {
      return new Promise((resolve) => {
        this.$refs.userInfoValidator.validate().then((valid) => {
          if (valid) {
            this.$emit("on-validate", valid, this.reservation);
          }
          resolve(valid);
        });
      });
    },
  },
};
</script>
