<template>
  <div
    :class="['custom-checkbox', { checked: value }, { readonly: readonly }, label || help ? '' : 'pr-2']"
    :id="id"
    @click="checkboxValue = !checkboxValue"
  >
    <font-awesome-icon
      :icon="value ? 'check' : ['far', 'square']"
    ></font-awesome-icon>
    <!-- <b-icon
      :class="['checkbox', { checked: value }]"
      :icon="value ? 'check-square-fill' : 'square'"
    ></b-icon> -->

    <b-col class="pr-1" v-if="label || help">
      <span class="checkbox-label" v-if="label">{{ label }}</span>
      <span class="checkbox-help-text" v-if="help">{{ help }}</span>
    </b-col>
  </div>
</template>

<style lang="scss">
@import "@/variables.scss";

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.25rem;
  padding-left: 0.5rem;
  border-radius: 0.25rem;
  user-select: none;
  border: 1px solid transparent;
  user-select: none;

  &:not(.readonly) {
    cursor: pointer;
  }

  &.readonly {
    pointer-events: none;
  }

  &.checked {
    border-color: $geo-info;
    color: darken($geo-info, 15%);
    background-color: rgba($geo-info, 0.125);
    font-weight: 600;

    svg {
      color: $geo-success;
    }
  }

  &:not(.readonly):hover {
    background-color: $geo-basic;
  }

  .checkbox-label {
    text-transform: uppercase;
    // font-weight: 600;
    font-size: 0.85rem;
    display: block;
  }
  .checkbox-help-text {
    font-size: 0.75rem;
    color: #6f6f6f;
  }
}
</style>

<script>
export default {
  name: "CustomCheckbox",
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    label: String,
    help: String,
    readonly: Boolean,
  },
  computed: {
    checkboxValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>