export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        appLocale() {
          return this.$i18n.locale === 'it' ? 'it_IT' : 'en_EN';
        },
      },
      methods: {
        getFieldTranslation(object, field) {
          if (!Array.isArray(object[field])) {
            if (!(field in object)) return '';
            return object[field][this.appLocale] || object[field]['it_IT'];
          }

          const localizedField = object[field].find(localization => localization.language === this.$i18n.locale);
          return localizedField && localizedField.text ? localizedField.text : object[field][0].text;
        }
      }
    })
  }
}
