<template>
  <div class="overlay-loader" v-if="show">
    <Loader :size="8" variant="light" />
  </div>
</template>

<style lang="scss">
.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(black, 0.5);
}
</style>

<script>
import Loader from "./Loader.vue";

import { EventBus } from "../eventBus";

export default {
  name: "OverlayLoader",
  components: {
    Loader,
  },
  data: () => ({
    show: false,
  }),
  created: function () {
    EventBus.$on("show-loader", () => (this.show = true));
    EventBus.$on("hide-loader", () => (this.show = false));
  },
};
</script>