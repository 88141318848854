<template>
  <header class="tabset-header">
    <div
      class="tab-head"
      v-for="(tab, index) of tabs"
      :class="{
        'tab-head--active': tab.id == value,
      }"
      :key="'custom-tab-head-' + index"
      @click="$emit('input', tab.id)"
    >
      <slot :name="'tab-head-' + tab">{{ tab.title }}</slot>

      <template v-if="editable && index > minTabs - 1">
        <div class="tab-remove" @click="$emit('tab-removed', index)">
          <font-awesome-icon icon="times"></font-awesome-icon>
        </div>
      </template>
      <template v-if="tab.edited">
        <div class="ml-2 d-inline-block">
          <font-awesome-icon icon="circle" size="xs"></font-awesome-icon>
        </div>
      </template>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";

.tabset-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #ccc;

  .tab-head {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.5rem 1rem;
    position: relative;
    cursor: pointer;
    margin-left: 0.75rem;
    text-transform: uppercase;
    font-size: 0.875rem;
    transition: opacity 0.25s ease;
    min-width: 65px;
    text-align: center;

    &:not(:hover):not(.tab-head--active) {
      opacity: 0.65;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: -3px;
      left: 0;
      border-radius: 5px;
      height: 5px;
      transition: background-color 0.25s ease;
    }
    &:hover:after {
      background-color: $accent;
    }
    &.tab-head--active {
      color: $primary;
      font-weight: 600;

      &:after {
        background-color: $primary;
      }
    }

    .tab-remove {
      margin-left: 1rem;
      cursor: pointer;
      display: inline-block;
    }
  }
}
</style>

<script>
export default {
  name: "CustomTabNav",
  props: {
    value: String,
    tabs: Array,
    editable: Boolean,
    minTabs: Number,
  },
};
</script>