<template>
  <Loader :size="8" v-if="!content" />

  <div v-else>
    <CustomAlert v-if="error" type="danger">
      <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
      <span class="ml-2">An error occurred, try again later.</span>
    </CustomAlert>

    <template v-else>
      <CustomBreadcrumb class="mb-3">
        <b-breadcrumb-item :to="{ name: 'ContentsList' }"
          >Lista Contenuti</b-breadcrumb-item
        >
        <b-breadcrumb-item
          v-if="content.id"
          :to="{ name: 'ContentPreview', params: { id: content.id } }"
          >{{ getFieldTranslation(content, "title") }}</b-breadcrumb-item
        >
        <b-breadcrumb-item class="text-truncate" active>
          Draft Contenuto
        </b-breadcrumb-item>
      </CustomBreadcrumb>

      <CustomAlert type="warning" class="mb-3">
        <b-row class="mb-2" align-v="center" align-h="center">
          <font-awesome-icon icon="info-circle"></font-awesome-icon>
          <span class="ml-2">{{
            content.status == "pending"
              ? $t("admin.contents.draft.pendingWarning")
              : $t("admin.contents.draft.modifiedWarning")
          }}</span>
        </b-row>

        <b-row align-v="center" align-h="center">
          <RaisedButton
            variant="danger"
            class="px-3 mr-5"
            @click.native="$bvModal.show('reject-content-modal')"
          >
            <font-awesome-icon icon="times"></font-awesome-icon>
            <span class="ml-2">{{ $t("actions.decline") }}</span>
          </RaisedButton>

          <RaisedButton
            variant="success"
            class="px-3"
            @click.native="acceptContent"
          >
            <font-awesome-icon icon="check"></font-awesome-icon>
            <span class="ml-2">{{ $t("actions.accept") }}</span>
          </RaisedButton>
        </b-row>
      </CustomAlert>

      <div class="content-draft">
        <CustomTabset :tabs="contentTabs">
          <template slot="tab-pane-overview">
            <div class="content-overview" key="overview">
              <table class="invisible-table m-2">
                <tbody>
                  <tr>
                    <th scope="col">Category</th>
                    <td>
                      {{ getFieldTranslation(getContentCategory(), "name") }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Type</th>
                    <td>{{ content.bookingType }}</td>
                  </tr>
                </tbody>
              </table>

              <CustomTabset :tabs="languageTabs">
                <template slot="tab-pane-ita">
                  <div class="content-i18n" key="i18n-ita">
                    <table class="invisible-table mb-2">
                      <thead>
                        <td width="40%"></td>
                        <td></td>
                      </thead>
                      <tbody>
                        <tr v-field-updated.title="updatedFields">
                          <th scope="col">Nome</th>
                          <td>{{ content.title[0].text }}</td>
                        </tr>
                        <tr v-field-updated.abstract="updatedFields">
                          <th class="pb-2" scope="col">Descrizione Breve</th>
                          <td class="pb-2">
                            {{ content.abstract[0].text }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="invisible-table">
                      <tbody>
                        <tr v-field-updated.description="updatedFields">
                          <th class="align-top" scope="col">Descrizione</th>
                          <td
                            class="border"
                            v-html="content.description[0].text"
                          ></td>
                        </tr>
                      </tbody>
                    </table>

                    <br />

                    <h6 class="text-muted">Campi extra</h6>
                    <CustomAlert v-if="!content.extra.length">
                      <font-awesome-icon icon="info-circle"></font-awesome-icon>
                      <span class="ml-2">{{
                        $t(
                          "admin.platformManagement.extraFields.list.noResults"
                        )
                      }}</span>
                    </CustomAlert>

                    <b-form-row v-else>
                      <b-col
                        cols="4"
                        v-for="(field, index) of content.extra"
                        :key="index"
                      >
                        <div
                          class="border rounded p-3"
                          v-field-updated.extra="updatedFields"
                        >
                          <b-row align-v="center" no-gutters>
                            <font-awesome-icon
                              :icon="field.icon || 'info'"
                            ></font-awesome-icon>

                            <b-col class="pl-3">
                              <p class="mb-0">{{ field.title[0].text }}</p>
                              <span>{{ field.stringValue[0].text }}</span>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-form-row>
                  </div>
                </template>

                <template slot="tab-pane-eng">
                  <div class="content-i18n" key="i18n-eng">
                    <table class="invisible-table">
                      <thead>
                        <td width="40%"></td>
                        <td></td>
                      </thead>
                      <tbody>
                        <tr v-field-updated.title="updatedFields">
                          <th scope="col">Name</th>
                          <td>{{ content.title[1].text }}</td>
                        </tr>
                        <tr v-field-updated.abstract="updatedFields">
                          <th class="pb-2" scope="col">Short Description</th>
                          <td class="pb-2">
                            {{ content.abstract[1].text }}
                          </td>
                        </tr>
                        <tr v-field-updated.description="updatedFields">
                          <th class="align-top" scope="col">Description</th>
                          <td
                            class="border"
                            v-html="content.description[1].text"
                          ></td>
                        </tr>
                      </tbody>
                    </table>

                    <br />

                    <h6 class="text-muted">Extra Fields</h6>
                    <CustomAlert v-if="!content.extra.length">
                      <font-awesome-icon icon="info-circle"></font-awesome-icon>
                      <span class="ml-2">{{
                        $t(
                          "admin.platformManagement.extraFields.list.noResults"
                        )
                      }}</span>
                    </CustomAlert>

                    <b-form-row v-else>
                      <b-col
                        cols="4"
                        v-for="(field, index) of content.extra"
                        :key="index"
                      >
                        <div
                          class="border rounded p-3"
                          v-field-updated.extra="updatedFields"
                        >
                          <b-row align-v="center" no-gutters>
                            <font-awesome-icon
                              :icon="field.icon || 'info'"
                            ></font-awesome-icon>

                            <b-col class="pl-3">
                              <p class="mb-0">{{ field.title[1].text }}</p>
                              <span>{{ field.stringValue[1].text }}</span>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-form-row>
                  </div>
                </template>
              </CustomTabset>
            </div>
          </template>

          <template slot="tab-pane-tags">
            <div
              class="content-tags"
              key="tags"
              v-field-updated.tags="updatedFields"
            >
              <div
                class="mb-1"
                v-for="(tag, index) of tagsCheckboxes"
                :key="index"
              >
                <CustomCheckbox
                  :id="'tag-' + tag.id"
                  :label="getFieldTranslation(tag, 'label')"
                  :value="tag.value"
                  :readonly="true"
                />
              </div>
            </div>
          </template>

          <template slot="tab-pane-location-contacts">
            <div class="content-location-contacts" key="contacts">
              <b-row no-gutters>
                <b-col md="4">
                  <div
                    class="content-info-card"
                    v-field-updated.location="updatedFields"
                  >
                    <LeafletMap
                      class="my-3"
                      :placeholderMode="true"
                      :center="[
                        content.location.coordinates[1],
                        content.location.coordinates[0],
                      ]"
                      :detailMarker="true"
                      :zoom="9"
                    ></LeafletMap>

                    <table class="invisible-table">
                      <tbody>
                        <tr>
                          <th scope="col">Longitude</th>
                          <td class="text-right">
                            {{ content.location.coordinates[0] }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Latitude</th>
                          <td class="text-right">
                            {{ content.location.coordinates[1] }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <b-col class="px-3">
                  <div v-field-updated.address="updatedFields">
                    <h6 class="text-muted">Content Address</h6>
                    <table class="invisible-table ml-3">
                      <tbody>
                        <tr>
                          <th scope="col">Street</th>
                          <td>{{ content.address.street }}</td>
                        </tr>
                        <tr>
                          <th scope="col">City</th>
                          <td>{{ content.address.city }}</td>
                        </tr>
                        <tr>
                          <th scope="col">Country</th>
                          <td>{{ content.address.country }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <hr />

                  <div v-field-updated.contacts="updatedFields">
                    <h6 class="text-muted">Contacts</h6>
                    <table class="invisible-table ml-3">
                      <tbody>
                        <tr>
                          <th scope="col">Name</th>
                          <td>{{ content.contacts.title }}</td>
                        </tr>
                        <tr>
                          <th scope="col">Email</th>
                          <td>{{ content.contacts.email }}</td>
                        </tr>
                        <tr>
                          <th scope="col">Telephone</th>
                          <td>{{ content.contacts.telephone }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>

          <template slot="tab-pane-package">
            <div
              class="content-package"
              key="package"
              v-field-updated.packages="updatedFields"
            >
              <CustomAlert v-if="!content.packages || !content.packages.length">
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
                <span class="ml-2">{{
                  $t("components.packageEditor.noPackage")
                }}</span>
              </CustomAlert>

              <CustomTabset
                v-if="content.packages && content.packages.length > 0"
                :tabs="packagesTabs"
              >
                <template
                  v-for="(packageTab, index) of packagesTabs"
                  :slot="'tab-pane-' + packageTab.id"
                >
                  <div :key="index">
                    <h6 class="text-primary">
                      {{ $t("common.description") }}
                    </h6>
                    <table class="invisible-table">
                      <tbody>
                        <tr>
                          <th scope="col">Titolo (IT)</th>
                          <td>
                            {{ content.packages[index].title[0].text }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Titolo (EN)</th>
                          <td>
                            {{ content.packages[index].title[1].text }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Descrizione (IT)</th>
                          <td>
                            {{ content.packages[index].description[0].text }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Descrizione (EN)</th>
                          <td>
                            {{ content.packages[index].description[1].text }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Numero minimo di persone</th>
                          <td>{{ content.packages[index].minPeople }}</td>
                        </tr>
                        <tr>
                          <th scope="col">Numero massimo di persone</th>
                          <td>{{ content.packages[index].maxPeople }}</td>
                        </tr>
                      </tbody>
                    </table>

                    <hr />

                    <h6 class="text-primary">
                      {{ $t("components.packageEditor.price") }}
                    </h6>
                    <table class="invisible-table">
                      <tbody>
                        <template v-if="!content.packages[index].price.isFree">
                          <tr>
                            <th scope="col">
                              {{
                                content.packages[index].price.perPerson
                                  ? $t("common.adults")
                                  : $t("common.price")
                              }}
                            </th>
                            <td>
                              <PriceLabel
                                :price="
                                  content.packages[index].price.adultPrice
                                "
                                size="md"
                              />
                            </td>
                          </tr>
                          <tr v-if="content.packages[index].price.perPerson">
                            <th scope="col">Bambini</th>
                            <td>
                              <PriceLabel
                                :price="
                                  content.packages[index].price.adultPrice
                                "
                                size="md"
                              />
                            </td>
                          </tr>
                        </template>
                        <tr v-else>
                          <th></th>
                          <td class="text-right">
                            {{ $t("components.pricePlanEditor.isFree") }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <h6 class="text-muted">
                      {{ $t("components.pricePlanEditor.option") }}
                    </h6>
                    <div class="border rounded ml-3">
                      <div
                        class="readonly-list"
                        v-for="(option, k) of content.packages[index].price
                          .options"
                        :key="k"
                      >
                        <table class="invisible-table">
                          <tbody>
                            <tr>
                              <th scope="col">Titolo (IT)</th>
                              <td class="text-right">
                                {{ option.title[0].text }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="col">Titolo (EN)</th>
                              <td class="text-right">
                                {{ option.title[1].text }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="col">
                                {{
                                  option.perPerson
                                    ? $t("common.adults")
                                    : $t("common.price")
                                }}
                              </th>
                              <td class="text-right">
                                <PriceLabel
                                  :price="option.adultPrice"
                                  size="sm"
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="col">Bambini</th>
                              <td class="text-right">
                                <PriceLabel
                                  :price="option.kidPrice"
                                  size="sm"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <hr />

                    <h6 class="text-primary">
                      {{ $t("components.packageEditor.availability") }}
                    </h6>
                    <table class="invisible-table">
                      <tbody>
                        <tr>
                          <th scope="col">
                            {{ $t("components.availabilityForm.months") }}
                          </th>
                          <td class="text-right">
                            {{
                              content.packages[
                                index
                              ].availability.monthsOfYear.join(", ")
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">
                            {{
                              $t("components.availabilityForm.daysOfTheWeek")
                            }}
                          </th>
                          <td class="text-right">
                            {{
                              content.packages[
                                index
                              ].availability.daysOfWeek.join(", ")
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <h6 class="text-muted">
                      {{ $t("components.availabilityForm.negRange") }}
                    </h6>
                    <div
                      class="atlas-tag pill border mr-2"
                      v-for="(range, j) of content.packages[index].availability
                        .negativeRanges"
                      :key="j"
                    >
                      <font-awesome-icon
                        class="text-danger"
                        icon="calendar-times"
                      ></font-awesome-icon>
                      <span class="ml-2">
                        {{ range[0] }} - {{ range[1] }}
                      </span>
                    </div>

                    <hr />

                    <h6 class="text-primary">
                      {{ $t("components.packageEditor.timeslots") }}
                    </h6>
                    <table class="invisible-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="text-right">Posti disponibili</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(slot, index) of content.packages[index]
                            .timeSlots"
                          :key="index"
                          class="border-bottom"
                        >
                          <th scope="col">{{ slot.from }} - {{ slot.to }}</th>
                          <td class="text-right">
                            {{ slot.amount }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </CustomTabset>
            </div>
          </template>

          <template slot="tab-pane-external-links">
            <div
              class="content-external-links"
              key="external-links"
              v-field-updated.externalLinks="updatedFields"
            >
              <CustomAlert v-if="!content.externalLinks.length">
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
                <span class="ml-2">{{
                  $t("components.externalLinkEditor.noExternalLinks")
                }}</span>
              </CustomAlert>

              <template v-else>
                <div class="border rounded">
                  <div
                    class="readonly-list"
                    v-for="(link, index) of content.externalLinks"
                    :key="index"
                  >
                    <table class="invisible-table">
                      <tbody>
                        <tr>
                          <th scope="col">Titolo (IT)</th>
                          <td class="text-right">
                            {{ link.title[0].text }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Titolo (EN)</th>
                          <td class="text-right">
                            {{ link.title[1].text }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Descrizione (IT)</th>
                          <td class="text-right">
                            {{ link.description[0].text }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Descrizione (EN)</th>
                          <td class="text-right">
                            {{ link.description[1].text }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">URL</th>
                          <td class="text-right">
                            <a :href="link.url">{{ link.url }}</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </div>
          </template>

          <template slot="tab-pane-images">
            <div class="content-images" key="images">
              <b-form-row>
                <b-col
                  cols="3"
                  v-for="(image, index) of contentImages"
                  :key="index"
                >
                  <div
                    :class="[
                      'image-item',
                      {
                        edited: !image.approved && content.status == 'modified',
                      },
                    ]"
                  >
                    <img :src="image.url" />
                  </div>
                </b-col>
              </b-form-row>
            </div>
          </template>
        </CustomTabset>
      </div>
    </template>
    <b-modal
      id="reject-content-modal"
      hide-footer
      :title="$t('admin.contents.detail.rejectContent')"
    >
      <p>{{ $t("admin.contents.detail.rejectMessage") }}</p>

      <b-form-group
        id="content-reject-reason-fieldset"
        label="Message"
        label-for="content-reject-reason-field"
      >
        <b-form-textarea
          id="content-reject-reason-field"
          rows="4"
          :placeholder="$t('admin.contents.detail.rejectPlaceholder')"
          v-model="rejectedMessage"
        ></b-form-textarea>
      </b-form-group>

      <b-row no-gutters align-v="center" align-h="between">
        <FlatButton @click.native="$bvModal.hide('reject-content-modal')">{{
          $t("actions.cancel")
        }}</FlatButton>

        <RaisedButton variant="danger" @click.native="rejectContent">
          <font-awesome-icon icon="paper-plane"></font-awesome-icon>
          <span class="ml-2">{{ $t("actions.confirm") }}</span>
        </RaisedButton>
      </b-row>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";

.content-draft {
  overflow: hidden;
  background: white;
  border-radius: 0.25rem;

  .readonly-list:not(:last-of-type) {
    border-bottom: 1px solid $default-gray;
  }

  .content-info-card {
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    // overflow: hidden;

    .info-title {
      border-bottom: 1px solid #ddd;
      padding: 0.5rem;
      font-weight: 500;
    }
    .info-body {
      padding: 0.5rem;

      &.tags-wrapper {
        max-height: 300px;
        overflow-y: auto;
      }
    }
  }

  .content-location-contacts .map-container {
    height: 200px;
  }

  .content-images .image-item {
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    height: 200px;

    img {
      height: auto;
      width: auto;
      max-height: 100%;
      max-width: 100%;
    }

    &.edited {
      box-shadow: 0 0 0 3px rgba($geo-warning, 0.75);
      background-color: rgba($geo-warning, 0.25);
    }
  }
}
</style>

<script>
import LeafletMap from "../components/LeafletMap.vue";
import RaisedButton from "../components/RaisedButton.vue";
import FlatButton from "../components/FlatButton.vue";
import Loader from "../components/Loader.vue";
import CustomBreadcrumb from "../components/CustomBreadcrumb.vue";
import CustomTabset from "../components/CustomTabset.vue";
import CustomAlert from "../components/CustomAlert.vue";
/* 
import CustomCheckbox from "../components/CustomCheckbox.vue";
import PricePlanEditor from "../components/PricePlanEditor.vue";
import BookingRulesList from "./BookingRulesList.vue"; 
*/
import PriceLabel from "../components/PriceLabel.vue";
import CustomCheckbox from "../components/CustomCheckbox.vue";

import { MULTIMEDIA_API } from "../globals";
import { Contents, Multimedia, Settings, BookingRules } from "../api";

import { NewContent } from "../models/NewContent";
import { Marker } from "../models/Marker";
import { handleError } from "../utils";

export default {
  name: "ContentDraft",
  components: {
    LeafletMap,
    CustomAlert,
    CustomTabset,
    CustomBreadcrumb,
    Loader,
    RaisedButton,
    FlatButton,
    PriceLabel,
    CustomCheckbox,
  },
  created() {
    // this.initContent();
    this.initSettings();
  },
  data: function () {
    return {
      content: null,
      extraFields: [],
      categories: [],
      marker: null,
      tags: [],
      updatedFields: [],
      error: false,
      attachments: null,
      contentImages: null,
      newImages: null,
      descriptionError: false,
      bookingRules: null,
      languageTabs: [
        { id: "ita", title: "Italiano" },
        { id: "eng", title: "English" },
      ],
      contentTabs: [
        {
          id: "overview",
          title: this.$t("components.contentEditPage.overview"),
        },
        { id: "tags", title: "Tags" },
        {
          id: "location-contacts",
          title: this.$t("components.contentEditPage.location"),
        },

        { id: "package", title: this.$t("components.contentEditPage.package") },
        {
          id: "external-links",
          title: this.$t("components.contentEditPage.externalLinks"),
        },
        {
          id: "images",
          title: this.$t("common.images"),
        },
      ],
      rejectedMessage: "",
    };
  },
  computed: {
    availableFields() {
      return this.extraFields.filter(
        (f) => !this.content.extra.map((field) => field.id).includes(f.id)
      );
    },
    tagsCheckboxes() {
      return this.tags
        .map((tag) => ({
          id: tag.id,
          label: tag.label,
          value: this.content.tags ? this.content.tags.includes(tag.id) : false,
        }))
        .sort((a, b) => a.text < b.text);
    },
    pricePlansTabs() {
      return this.content.price.plans.map((plan, index) => ({
        id: "price-plan-" + index,
        title: this.getFieldTranslation(plan, "title"),
      }));
    },
    packagesTabs() {
      return this.content.packages.map((pack, index) => ({
        id: "package-" + index,
        title: this.getFieldTranslation(pack, "title"),
      }));
    },
  },
  methods: {
    initSettings() {
      Settings.getSettings().then(
        ({ data: settings }) => {
          this.extraFields = settings.fields
            ? settings.fields.filter((field) => !field.hidden)
            : [];
          this.categories = settings.categories || [];
          this.tags = settings.tags || [];
          this.initContent();
        },
        (err) => {
          console.log(err);
          this.error = true;
          handleError(
            this,
            err,
            "messages.retrieveInfoErrorMsg",
            "messages.contentError"
          );
        }
      );
    },
    getContentCategory() {
      return this.categories.find((cat) => cat.id == this.content.category);
    },
    initContent() {
      const id = this.$route.params.id;

      const params = new URLSearchParams();
      params.set("draft", true);

      Contents.getContent(id, params).then(
        (res) => {
          this.content = new NewContent(res.data);
          this.content.location.coordinates = res.data.location.coordinates;
          if (!this.content.contacts) this.content.contacts = {};
          if (!this.content.price) this.content.price = {};
          if (!this.content.extra) this.content.extra = [];
          if (!this.content.packages) this.content.packages = [];
          this.marker = new Marker(
            this.content.id,
            this.content.location.coordinates,
            this.content.title,
            `${MULTIMEDIA_API}/data/${this.content.id}/main`,
            this.content.address,
            this.content.category
          );
          this.updatedFields = this.content.updatedFields;
          this.getContentMultimedia();
          this.fixExtraFields();
          if (this.updatedFields) this.fixTabs();
          if (this.content.typeBook) this.getContentBookingRules();
          else this.bookingRules = [];
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentDetailMsg",
            "messages.contentError"
          );
        }
      );
    },
    getContentBookingRules() {
      BookingRules.getRules(this.content.id).then(
        (res) => {
          this.bookingRules = res.data;
        },
        (err) => {
          console.log(err);
          this.bookingRules = [];
          handleError(
            this,
            err,
            "messages.bookingRulesMsg",
            "messages.contentError"
          );
        }
      );
    },
    getContentMultimedia() {
      const token = this.$cookies.get("atlas_token");

      Multimedia.getMultimediaList(this.content.id).then(
        ({ data: images }) => {
          this.contentImages = images
            .map((f) => ({
              id: f.id,
              title: f.title,
              url: `${MULTIMEDIA_API}/data/${this.content.id}/${f.id}?token=${token}`,
              mainImage: f.mainImage,
              approved: f.approved,
            }))
            .sort((f) => (f.mainImage ? -1 : 0));
          if (this.content.status == "modified") this.fixTabs(true);
        },
        (err) => {
          if (err.response && err.response.status == 404) this.files = [];
          else
            handleError(
              this,
              err,
              "messages.multimediaErrorMsg",
              "messages.multimediaError"
            );
        }
      );
    },
    fixTabs(justImages) {
      if (justImages) {
        const imagesEdited = this.contentImages.findIndex(
          (image) => !image.approved
        );
        if (imagesEdited > -1) this.$set(this.contentTabs[5], "edited", true);
        return;
      }

      const overviewEdited = this.updatedFields.findIndex(
        (field) =>
          field == "title" ||
          field == "abstract" ||
          field == "description" ||
          field == "extra" ||
          field == "category" ||
          field == "bookingType"
      );
      const tagsEdited = this.updatedFields.findIndex(
        (field) => field == "tags"
      );
      const locationEdited = this.updatedFields.findIndex(
        (field) =>
          field == "location" || field == "address" || field == "contacts"
      );
      const packagesEdited = this.updatedFields.findIndex(
        (field) => field == "packages"
      );
      const linksEdited = this.updatedFields.findIndex(
        (field) => field == "externalLinks"
      );

      if (overviewEdited > -1) this.$set(this.contentTabs[0], "edited", true);
      if (tagsEdited > -1) this.$set(this.contentTabs[1], "edited", true);
      if (locationEdited > -1) this.$set(this.contentTabs[2], "edited", true);
      if (packagesEdited > -1) this.$set(this.contentTabs[3], "edited", true);
      if (linksEdited > -1) this.$set(this.contentTabs[4], "edited", true);
    },
    fixExtraFields() {
      this.content.extra
        .sort((a, b) => a.position < b.position)
        .forEach((field, index) => {
          const completeField = this.extraFields.find((f) => f.id == field.id);

          if (!completeField) this.content.extra.splice(index, 1);
          else {
            field.icon = completeField.icon;
            field.title = completeField.title;
            field.type = completeField.type;
            if (field.type == "string") {
              field.stringValue ==
                [
                  { language: "it", text: "" },
                  { language: "en", text: "" },
                ];
            }
          }
        });
    },
    acceptContent() {
      this.$loading.show();

      Contents.contentApproval(this.content.id, true).then(
        () => {
          this.$loading.hide();
          this.$root.$bvToast.toast(this.$t("messages.contentAvailableMsg"), {
            title: this.$t("messages.contentAvailable"),
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "ContentsList" });
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentUnaccepted",
            "messages.contentError"
          );
          this.$loading.hide();
        }
      );
    },
    rejectContent() {
      this.$loading.show();

      Contents.contentApproval(
        this.content.id,
        false,
        this.rejectedMessage
      ).then(
        () => {
          this.$loading.hide();
          this.$root.$bvToast.toast(this.$t("messages.contentDenyMsg"), {
            title: this.$t("messages.contentDeny"),
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("reject-content-modal");
          this.$router.push({ name: "ContentsList" });
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.contentRejectMsg",
            "messages.contentError"
          );
          this.$loading.hide();
        }
      );
    },
  },
};
</script>
