<template>
  <div :class="['custom-radios', direction]">
    <b-col
      :cols="columns || 12"
      class="p-0"
      v-for="(option, index) of options"
      :key="index"
    >
      <div
        :class="['radio-option', size, { selected: value == option.value }]"
        @click="toggleOption(index)"
      >
        <font-awesome-icon
          :icon="value == option.value ? 'check' : ['far', 'circle']"
          fixedWidth
          class="radio-icon"
        ></font-awesome-icon>
        <div class="ml-2">
          <span class="option-label">{{ option.label }}</span>
          <span class="text-muted" v-if="option.trailing && option.amount != 'Infinity'">{{
            option.trailing
          }}</span>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "CustomRadios",
  props: {
    options: Array,
    value: [String, Array, Number],
    size: {
      type: String,
      default: "md",
    },
    direction: String,
    columns: Number,
  },
  methods: {
    toggleOption(index) {
      if (this.value === this.options[index].value)
        this.$emit("input", undefined);
      else this.$emit("input", this.options[index].value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/variables.scss";

.custom-radios {
  // padding: 0.25rem 0.75rem;
  &.row {
    margin: 0;

    .radio-option {
      margin: 0 0.25rem;
    }
  }

  .radio-option {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 0.25rem;
    border-radius: 0.25rem;
    border: 1px solid transparent;
    user-select: none;

    .option-label {
      display: block;
    }

    &.selected {
      border-color: $geo-info;
      color: darken($geo-info, 10%);
      background-color: rgba($geo-info, 0.125);

      .option-label {
        font-weight: 500;
      }

      .radio-icon {
        color: $geo-success;
      }
    }

    &:hover {
      background-color: $geo-basic;
    }

    &.sm {
      font-size: 0.85rem;
    }
  }
}
</style>