<template>
  <b-modal
    :id="modalId"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
  >
    <div class="text-center mb-3">
      <b-icon
        icon="exclamation-circle"
        class="text-danger danger-icon"
      ></b-icon>
    </div>

    <div class="text-center">
      <slot></slot>
      <h6 class="my-3 text-danger">
        {{ $t("admin.contents.delete.secondaryMessage") }}
      </h6>
    </div>

    <b-row align-h="around">
      <FlatButton size="lg" @click.native="$emit('cancel')" class="py-2">
        {{ $t("actions.cancel") }}
      </FlatButton>
      <FlatButton
        size="lg"
        @click.native="$emit('confirm')"
        class="py-2 text-danger"
        :disabled="block"
      >
        {{ $t("actions.confirm") }}
      </FlatButton>
    </b-row>
  </b-modal>
</template>

<style lang="scss">
.danger-icon {
  font-size: 8rem;
}
</style>

<script>
import FlatButton from "./FlatButton.vue";

export default {
  name: "DeleteModal",
  props: {
    modalId: {
      type: String,
      default: "delete-modal",
    },
    block: Boolean,
  },
  components: {
    FlatButton,
  },
};
</script>