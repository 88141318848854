<template>
  <div class="vue-calendar">
    <vue-calendar
      ref="calendar"
      :is-expanded="isExpanded"
      :locale="locale"
      :available-dates="validDates"
      :disabled-dates="{}"
      :attributes="attributes"
      :step="1"
      @update:from-page="monthChange"
      @dayclick="dayClick"
    >
    </vue-calendar>
  </div>
</template>

<script>
export default {
  name: "AvailabilityCalendar",
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
    locale: String,
    validDates: Array,
    attributes: Array,
    startDate: {
      type: Date,
      required: false,
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.startDate) {
      const calendar = this.$refs.calendar;
      calendar.focusDate(this.startDate, { force: true });
    }
  },
  methods: {
    monthChange(evt) {
      this.$emit("month-change", evt);
    },
    dayClick(evt) {
      this.$emit("day-click", evt);
    },
  },
};
</script>
