export class Availability {

    constructor(
        id,
        start,
        end,
        monthsOfYear,
        validityRanges,
        negativeRanges,
        daysOfWeek,
        amount,
    ) {
        this.id = id || undefined;
        this.start = start || undefined;
        this.end = end || undefined;
        this.monthsOfYear = monthsOfYear || []
        this.validityRanges = validityRanges || [];
        this.negativeRanges = negativeRanges || [];
        this.daysOfWeek = daysOfWeek || [];    
        this.amount = amount;
    }
}