<template>
  <div class="time-slots-list">
    <b-row
      v-for="(slot, index) of timeSlots"
      :key="index"
      class="timeslot-item"
      no-gutters
    >
      <div class="p-3">
        <div class="leading">
          <font-awesome-icon size="lg" icon="clock"></font-awesome-icon>
        </div>
      </div>
      <b-col>
        <h6>{{ slot.from }} - {{ slot.to }}</h6>
        <p class="text-muted mb-0">
          <font-awesome-icon icon="users"></font-awesome-icon>
          <span class="ml-2">{{ slot.amount }}</span>
        </p>
      </b-col>

      <IconButton class="mr-3" @click.native="editRange(index)">
        <font-awesome-icon icon="pen"></font-awesome-icon>
      </IconButton>
      <IconButton class="text-danger mr-3" @click.native="deleteRange(index)">
        <font-awesome-icon icon="trash"></font-awesome-icon>
      </IconButton>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/variables.scss";

.time-slots-list {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid $default-gray;
  border-radius: 0.5rem;
  .leading {
    padding: 0.5rem;
  }

  .timeslot-item {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      border-bottom: 1px solid $default-gray;
    }
  }
}
</style>

<script>
import IconButton from "./IconButton.vue";

export default {
  name: "TimeSlotsList",
  components: {
    IconButton,
  },
  props: {
    timeSlots: Array,
  },
  data() {
    return {};
  },
  created: function () {},
  computed: {},
  methods: {
    editRange(index) {
      this.$emit("edit-time-slot", index);
    },
    deleteRange(index) {
      this.$emit("delete-time-slot", index);
    },
  },
};
</script>