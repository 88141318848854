<template>
  <b-row no-gutters>
    <b-avatar variant="info" :src="image"></b-avatar>
    <b-col class="px-2">
      <h6 class="mb-1">{{ title }}</h6>
      <p
        class="m-0 text-warning"
        v-if="address.street || address.city || address.country"
      >
        <b-icon icon="geo-alt-fill"></b-icon>
        <span class="ml-2">{{ address | contentAddress }}</span>
      </p>
    </b-col>
    <b-col cols="12" v-if="showDetailButton" class="text-center mt-3">
      <RaisedButton variant="primary" @click.native="$emit('content-detail')">
        Visualizza
      </RaisedButton>
    </b-col>
  </b-row>
</template>

<script>
import RaisedButton from "./RaisedButton.vue";

export default {
  name: "MarkerPopupBody",
  components: {
    RaisedButton,
  },
  props: {
    image: String,
    title: String,
    address: Object,
    category: String,
    showDetailButton: Boolean,
  },
};
</script>