<template>
  <b-navbar variant="light" toggleable="sm" class="border-bottom">
    <b-navbar-brand class="action p-0" to="/">
      <img class="app-logo" src="../assets/scialari.svg" />
    </b-navbar-brand>

    <b-collapse id="nav-collapse" class="text-center text-sm-left" is-nav>
      <b-navbar-nav class="py-3 py-sm-0 ml-auto">
        <b-nav-item to="/home" active-class="active-link">{{
          $t("navigation.home")
        }}</b-nav-item>
        <b-nav-item to="/contents-catalog" active-class="active-link">{{
          $t("navigation.contents")
        }}</b-nav-item>

        <b-nav-item to="/who-are-we" active-class="active-link">{{
          $t("navigation.aboutUs")
        }}</b-nav-item>
        <b-nav-item to="/contacts" active-class="active-link">{{
          $t("navigation.contacts")
        }}</b-nav-item>
        <b-nav-item to="/about" active-class="active-link">{{
          $t("navigation.project")
        }}</b-nav-item>
        <CartLink v-if="!user || user.role == 'user'" :user="user" />
        <LocalesCombo class="ml-3" />
      </b-navbar-nav>
    </b-collapse>

    <div class="v-divider"></div>

    <template v-if="user">
      <AccountCombo :user="user" />
    </template>

    <RaisedButton v-else variant="info" @click.native="login">
      <font-awesome-icon icon="sign-in-alt"></font-awesome-icon>
      <span class="ml-2">Login</span>
    </RaisedButton>

    <b-navbar-toggle target="nav-collapse" v-if="!admin"></b-navbar-toggle>
  </b-navbar>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";

nav.navbar {
  padding: 0.3rem 0.75rem;

  .app-logo {
    height: 30px;
  }
}

.v-divider {
  border-left: 2px solid #ddd;
  margin: 0 1rem;
  height: 100%;
}

@media screen and (max-width: 576px) {
  .v-divider {
    display: none;
  }

  nav.navbar {
    padding: 0.125rem 0.5rem;

    .app-logo {
      height: 45px;
    }
  }
}

.navbar-light .navbar-nav .nav-link.active-link {
  font-weight: 600;
  color: $accent;

  &:hover,
  &:focus {
    color: darken($accent, 5%);
  }
}
</style>

<script>
import LocalesCombo from "./LocalesCombo.vue";
import AccountCombo from "./AccountCombo.vue";
import RaisedButton from "./RaisedButton.vue";
import CartLink from "./CartLink.vue";
import { logout } from "../utils";

export default {
  name: "Navbar",
  props: {
    admin: Boolean,
    user: Object,
  },
  components: {
    CartLink,
    RaisedButton,
    AccountCombo,
    LocalesCombo,
  },
  methods: {
    logout() {
      logout();
    },
    login() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>